<template>
  <div :class="['form-input', { last: last }]">
    <label class="form-input__label" :for="name">{{ label }}<span> *</span> </label>
    <textarea
      v-if="isTextarea"
      class="form-input__textarea"
      v-model="model"
      :id="name"
      :name="name"
      rows="4"
      required
      @change="change"
    />
    <input
      v-else
      class="form-input__input"
      v-model="model"
      :id="name"
      :name="name"
      :pattern="pattern"
      :placeholder="placeholder"
      :type="type"
      required
      @change="change"
    />
  </div>
</template>

<script>
export default {
  props: {
    isTextarea: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    pattern: {
      type: String,
      default: null,
    },
    last: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      model: '',
    };
  },
  methods: {
    change(event) {
      this.$emit('change', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-input {
  margin: 0 auto 20px;
  width: 100%;

  &.last {
    margin: 0 auto 10px;
  }
}

.form-input__label {
  font-weight: 500;

  span {
    color: $red;
  }

  @media screen and (max-width: 420px) {
    font-size: 0.9rem;
  }
}

.form-input__textarea {
  border: 2px solid $black;
  margin-top: 2px;
  line-height: 30px;
  padding: 0 5px;
  font-size: 1rem;
  width: 100%;
  color: rgba($black, 0.9);
  font-weight: 400;
  resize: none;
}

.form-input__input {
  border: 2px solid $black;
  margin-top: 2px;
  line-height: 30px;
  padding: 0 5px;
  font-size: 1rem;
  width: 100%;
  color: rgba($black, 0.9);
  font-weight: 400;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
