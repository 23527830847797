export default {
  name: {
    name: 'name',
    label: 'Imię i nazwisko / Firma',
  },
  email: {
    name: 'email',
    label: 'Email',
    type: 'email',
  },
  phone: {
    name: 'phone',
    label: 'Numer telefonu',
    pattern: '[0-9]{9}',
    placeholder: 'xxxxxxxxx',
  },
  text: {
    name: 'text',
    label: 'Treść wiadomości',
    type: 'textarea',
  },
  checkbox: {
    name: 'checkbox',
    label:
      'Wyrażam zgodę na przetwarzanie moich danych osobowych w celu obsługi mojego zapytania o produkt. Udzielenie zgody jest dobrowolne, jednakże niezbędne do korzystania z tego formularza.',
  },
};
