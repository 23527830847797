<template>
  <section
    v-if="subcategory"
    class="category"
    :style="{ backgroundImage: `url(${categoryImage})` }"
  >
    <category-button
      v-for="data in subcategory"
      :key="data.text"
      :text="data.text"
      :route="data.route"
    />
  </section>
</template>

<script>
import { lazyloadImage } from '../data/helper_functions';
import categoryData from '../data/category';
import CategoryButton from '../atoms/CategoryButton.vue';

export default {
  components: { CategoryButton },
  data() {
    return {
      subcategory: {},
      backgroundImage: null,
      backgroundLowImage: null,
    };
  },
  computed: {
    categoryImage: function () {
      return lazyloadImage(this.backgroundImage || this.backgroundLowImage);
    },
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      const category = this.$route.params.category;
      const data = categoryData.subcategory[category];

      this.subcategory = data.data;
      this.backgroundImage = data.background_image;
      this.backgroundLowImage = data.background_low_image;
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 1200px;
  width: 100%;
  gap: 20px;
  padding: 20px;
  margin: auto;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, 0.6);
  }
}
</style>
