<template>
  <section class="subcategory">
    <card v-for="data in currentProducts" :key="data.id" v-bind="data" />
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import productsData from '../data/products';
import filtersData from '../data/filters';
import Card from '../atoms/Card.vue';

export default {
  components: { Card },
  data() {
    return {
      category: '',
      subcategory: '',
    };
  },
  computed: {
    ...mapState(['products', 'filters']),

    currentProducts() {
      return this.filters.data.length ? this.filters.data : this.products;
    },
  },
  created() {
    this.category = this.$route.params.category;
    this.subcategory = this.$route.params.subcategory;

    this.setGlobalProducts();
    this.setFiltersData();
  },
  unmounted() {
    this.setProducts([]);
    this.setShowFilterBar(false);
    this.setFilters({
      value: [],
      data: [],
      type: '',
    });
  },
  methods: {
    ...mapMutations(['setProducts', 'setFilters', 'setShowFilterBar']),

    setGlobalProducts() {
      this.setProducts(
        this.subcategory
          ? productsData[this.category][this.subcategory]
          : productsData[this.category],
      );

      if (!this.currentProducts) this.$router.push({ path: '/404' });
    },

    setFiltersData() {
      const value = this.subcategory
        ? filtersData[this.category][this.subcategory] || []
        : filtersData[this.category] || [];

      this.setFilters({ ...this.filters, value });

      if (value.length) this.setShowFilterBar(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.subcategory {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 1200px;
  width: 100%;
  gap: 20px;
  padding: 10px 20px;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 0 10px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 420px) {
    grid-template-columns: 1fr;
  }
}
</style>
