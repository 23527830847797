<template>
  <div class="anitex-app">
    <TopBar />
    <NavigationBar />
    <FilterBar />
    <Footer />
    <Cookies />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import NavigationBar from './components/NavigationBar.vue';
import Footer from './components/Footer.vue';
import Cookies from './components/Cookies.vue';
import FilterBar from './components/FilterBar.vue';

export default {
  components: { TopBar, NavigationBar, Footer, Cookies, FilterBar },
};
</script>

<style lang="scss">
@import 'assets/styles/styles';

.anitex-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 91px;

  @media screen and (max-width: 1200px) {
    padding-bottom: 87px;
  }

  @media screen and (max-width: 992px) {
    padding-bottom: 81px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 80px;
  }

  @media screen and (max-width: 576px) {
    padding-bottom: 114px;
  }

  @media screen and (max-width: 420px) {
    padding-bottom: 101.5px;
  }
}
</style>
