<template>
  <div
    :class="['top-image', { '.about-us': isAboutUs }]"
    :style="{ backgroundImage: `url(${topImageImage})` }"
  >
    <h2 class="top-image__text">{{ text }}</h2>
  </div>
</template>

<script>
import { lazyloadImage } from '../data/helper_functions';

export default {
  props: {
    isAboutUs: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    lowImage: {
      type: String,
      required: true,
    },
  },
  computed: {
    topImageImage: function () {
      return lazyloadImage(this.image) || this.lowImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-image {
  display: flex;
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: grayscale(30%);
  padding: 50px 0;

  @media screen and (max-width: 1200px) {
    padding: 45px 0;
  }

  @media screen and (max-width: 992px) {
    padding: 40px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 35px 0;
  }

  @media screen and (max-width: 576px) {
    padding: 30px 0;
  }

  @media screen and (max-width: 420px) {
    padding: 25px 0;
  }

  &.about-us {
    background-position-y: 69%;
  }
}

.top-image__text {
  color: $white;
  margin: auto;
  font-size: 3.5rem;
  font-weight: 300;
  border: 2px solid $white;
  padding: 0 25px;
  letter-spacing: 1px;

  @media screen and (max-width: 992px) {
    font-size: 3.3rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 3.1rem;
  }

  @media screen and (max-width: 576px) {
    font-size: 2.9rem;
    padding: 0 20px;
  }

  @media screen and (max-width: 420px) {
    font-size: 2.7rem;
  }
}
</style>
