<template>
  <router-link rel="noopener" :to="route">
    <div
      :class="['home-button', { left: left }]"
      :style="{ backgroundImage: `url(${homeButtonImage})` }"
    >
      <div class="home-button__text">
        {{ text }}
      </div>
    </div>
  </router-link>
</template>

<script>
import { lazyloadImage } from '../data/helper_functions';

export default {
  props: {
    area: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    lowImage: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    homeButtonImage: function () {
      return lazyloadImage(this.image) || this.lowImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-button {
  display: flex;
  grid-area: v-bind(area);
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: grayscale(80%);
  cursor: pointer;

  &.left {
    background-position-x: left;
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      filter: grayscale(40%);

      > .home-button__text {
        transform: scale(1.1);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &:active {
      filter: grayscale(40%);

      > .home-button__text {
        transform: scale(1.1);
      }
    }
  }
}

.home-button__text {
  display: inline-flex;
  padding: 10px 30px;
  border: 6px solid $white;
  text-align: center;
  color: $white;
  font-weight: 600;
  font-size: 3rem;
  margin: auto;

  @media screen and (min-width: 1024px) {
    transition: transform 0.2s;
  }

  @media screen and (max-width: 1024px) {
    transition: none;
  }

  @media screen and (max-width: 1200px) {
    font-size: 2.8rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.6rem;
    padding: 8px 24px;
  }

  @media screen and (max-width: 576px) {
    font-size: 2.4rem;
    border-width: 5px;
  }

  @media screen and (max-width: 420px) {
    font-size: 2rem;
    padding: 7px 21px;
  }
}
</style>
