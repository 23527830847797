<template>
  <section v-if="showFilterBar" class="filter-bar">
    <div class="filter-bar__box">
      <span>wybierz filtr:</span>
      <button
        v-for="data in filters.value"
        :key="data.type"
        :class="['filter-bar__button', { active: isActive(data.type) }]"
        @click="clickButton(data.type)"
      >
        {{ data.text }}
      </button>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['showFilterBar', 'products', 'filters']),
  },
  methods: {
    ...mapMutations(['setFilters']),

    clickButton(value) {
      const type = this.filters.type === value ? '' : value;
      let data = [];

      this.products.map(product => {
        if (product.type && product.type.includes(type)) data.push(product);
      });

      this.setFilters({ ...this.filters, data, type });
    },

    isActive(value) {
      return this.filters.type === value;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-bar {
  display: flex;
  background-color: $grey-light;
  height: 60px;
  margin-bottom: 10px;
  overflow-x: auto;
  align-items: center;
  z-index: 99;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 768px) {
    height: 50px;
  }

  @media screen and (max-width: 576px) {
    height: 40px;
  }
}

.filter-bar__box {
  display: flex;
  align-items: center;
  max-width: 1200px;
  padding: 0 50px;
  margin: auto;
  width: 100%;

  span {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 25px;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      font-size: 1.15rem;
      margin-right: 20px;
    }

    @media screen and (max-width: 420px) {
      font-size: 1rem;
      margin-right: 15px;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 576px) {
    padding: 0 15px;
  }
}

.filter-bar__button {
  display: flex;
  cursor: pointer;
  background-color: transparent;
  font-size: 1.25rem;
  color: $black;
  padding: 4px 8px;
  border: 2px solid $black;
  margin-right: 15px;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      border: 2px solid $orange;
      color: $orange;
    }
  }

  @media screen and (max-width: 1024px) {
    &:active {
      border: 2px solid $orange;
      background-color: $orange;
      color: $white;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 1.15rem;
    margin-right: 12.5px;
  }

  @media screen and (max-width: 576px) {
    font-size: 1rem;
    padding: 3px 6px;
    margin-right: 10px;
  }

  &.active {
    border: 2px solid $orange;
    background-color: $orange;
    color: $white;

    @media screen and (min-width: 1024px) {
      &:hover {
        color: $white;
      }
    }
  }
}
</style>
