export default {
  obuwie: {
    trzewiki: [
      {
        type: 'bez_podnosek',
        text: 'bez podnoska',
      },
      {
        type: 'podnosek',
        text: 'z podnoskiem',
      },
      {
        type: 'zima',
        text: 'ocieplane',
      },
      {
        type: 'spaw',
        text: 'spawalnicze',
      },
      {
        type: 'lady',
        text: 'damskie',
      },
    ],
    polbuty: [
      {
        type: 'bez_podnosek',
        text: 'bez podnoska',
      },
      {
        type: 'podnosek',
        text: 'z podnoskiem',
      },
    ],
    sandaly: [
      {
        type: 'bez_podnosek',
        text: 'bez podnoska',
      },
      {
        type: 'podnosek',
        text: 'z podnoskiem',
      },
    ],
  },
  rekawice: {
    powlekane: [
      {
        type: 'zima',
        text: 'ocieplane',
      },
    ],
    skorzane: [
      {
        type: 'spaw',
        text: 'spawalnicze',
      },
      {
        type: 'zima',
        text: 'ocieplane',
      },
    ],
    gumowe: [
      {
        type: 'zima',
        text: 'ocieplane',
      },
    ],
  },
  'odziez-robocza': {
    spodnie: [
      {
        type: 'pas',
        text: 'do pasa',
      },
      {
        type: 'ogr',
        text: 'ogrodniczki',
      },
      {
        type: 'short',
        text: 'krótkie',
      },
    ],
    koszule: [
      {
        type: 'lady',
        text: 'damskie',
      },
    ],
    kurtki: [
      {
        type: 'zima',
        text: 'ocieplane',
      },
    ],
    ostrzegawcza: [
      {
        type: 'spodnie',
        text: 'spodnie',
      },
      {
        type: 'kurtka',
        text: 'kurtki',
      },
      {
        type: 't-shirt',
        text: 'koszulki',
      },
      {
        type: 'bluza',
        text: 'bluzy',
      },
    ],
    przeciwdeszczowa: [
      {
        type: 'kpl',
        text: 'komplety',
      },
      {
        type: 'spodnie',
        text: 'spodnie',
      },
      {
        type: 'kurtka',
        text: 'kurtki',
      },
      {
        type: 'poncho',
        text: 'poncho',
      },
      {
        type: 'coat',
        text: 'płaszcze',
      },
    ],
  },
  'artykuly-ochronne': {
    'ochrona-glowy': [
      {
        type: 'kask',
        text: 'kaski',
      },
      {
        type: 'czakas',
        text: 'czapkokaski',
      },
      {
        type: 'akc',
        text: 'akcesoria',
      },
    ],
    'ochrona-sluchu': [
      {
        type: 'nauszniki',
        text: 'nauszniki',
      },
      {
        type: 'zatyczki',
        text: 'zatyczki',
      },
    ],
    'ochrona-oczu-i-twarzy': [
      {
        type: 'gogle',
        text: 'gogle',
      },
      {
        type: 'okulary',
        text: 'okulary',
      },
    ],
  },
};
