<template>
  <section class="offer">
    <offer-button v-for="data in offer" :key="data.text" v-bind="data" />
  </section>
</template>

<script>
import OfferButton from '../atoms/OfferButton.vue';
import offer from '../data/offer';

export default {
  components: { OfferButton },
  data() {
    return {
      offer,
    };
  },
};
</script>

<style lang="scss" scoped>
.offer {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  gap: 10px;
  margin: auto;
  padding: 0 10px;
  width: 100%;
}
</style>
