<template>
  <router-link rel="noopener" :to="route">
    <div class="category-button">
      <span class="category-button__text">{{ text }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.category-button {
  position: relative;
  display: grid;
  border: 3px solid $white;
  color: $white;
  align-items: center;
  text-align: center;
  height: 125px;
  cursor: pointer;
  transition: background 0.2s;
  z-index: 99;

  @media screen and (min-width: 1024px) {
    &:hover {
      background-color: rgba($black, 0.85);
    }

    &:active {
      background-color: $black;
    }
  }

  @media screen and (max-width: 1024px) {
    &:active {
      background-color: $black;
    }
  }

  @media screen and (max-width: 768px) {
    height: 115px;
  }

  @media screen and (max-width: 576px) {
    height: 85px;
  }

  @media screen and (max-width: 420px) {
    height: 65px;
  }
}

.category-button__text {
  display: inline-block;
  font-size: 3rem;
  font-weight: 600;
  z-index: 1;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 576px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 420px) {
    font-size: 1.5rem;
  }
}
</style>
