<template>
  <footer class="footer">
    <div class="footer__box">
      <p class="footer__text">{{ `copyright © ${year} | fhu anitex` }}</p>
      <p class="footer__text">
        created by
        <a
          href="https://www.linkedin.com/in/marcin-koszykowski-0b14b5198/"
          target="_blank"
          rel="noopener"
        >
          marcin koszykowski
        </a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($grey-light, 0.5);
}

.footer__box {
  display: grid;
  margin: auto;
  padding: 30px 0;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;

  @media screen and (max-width: 1200px) {
    padding: 28px 0;
  }

  @media screen and (max-width: 992px) {
    padding: 25px 0;
  }

  @media screen and (max-width: 576px) {
    padding: 22px 0;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  @media screen and (max-width: 420px) {
    padding: 20px 0;
    gap: 16px;
  }
}

.footer__text {
  text-align: center;
  font-weight: 500;
  font-size: 1.05rem;
  text-transform: lowercase;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 420px) {
    font-size: 0.95rem;
  }

  a {
    color: $orange;
    font-weight: 500;

    @media screen and (min-width: 1024px) {
      &:hover {
        color: $blue;
      }
    }

    @media screen and (max-width: 1024px) {
      &:active {
        color: $blue;
      }
    }
  }
}
</style>
