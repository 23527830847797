<template>
  <div class="image">
    <div class="image__main-box">
      <img class="image__main" :src="mainImage" alt="główne zdjęcie produktu" />
    </div>
    <div v-if="showImageBox" class="image__sub-box">
      <img
        v-for="image in sub"
        :class="['image__sub', { active: isActive(image) }]"
        :key="image"
        :src="image"
        alt="zdjęcie produktu"
        @click="clickSubImage(image)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    main: {
      type: String,
      required: true,
    },
    sub: {
      type: Array,
      default: () => [],
    },
    isUrgent: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainImage: this.main,
    };
  },
  computed: {
    showImageBox() {
      return this.sub?.length > 1 && (!this.isUrgent || this.isNew);
    },
  },
  methods: {
    isActive(image) {
      return this.mainImage === image;
    },

    clickSubImage(image) {
      this.mainImage = image;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  display: flex;
  flex-direction: column;
  border-right: 2px solid $black;
  padding: 20px;

  @media screen and (max-width: 768px) {
    border-right: none;
    border-bottom: 2px solid $black;
  }

  @media screen and (max-width: 576px) {
    padding: 15px;
  }
}

.image__main-box {
  padding: 10px;
}

.image__main {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: auto;
  object-fit: contain;

  @media screen and (max-width: 992px) {
    max-width: 350px;
  }

  @media screen and (max-width: 768px) {
    max-width: 400px;
  }

  @media screen and (max-width: 576px) {
    max-width: 350px;
  }

  @media screen and (max-width: 420px) {
    max-width: 300px;
  }
}

.image__sub-box {
  margin-top: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  max-width: max-content;

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 420px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.image__sub {
  width: 100%;
  border: 2px solid $grey-light;
  padding: 5px;
  cursor: pointer;
  object-fit: contain;
  max-height: 170px;

  &.active {
    border-color: $orange;
  }
}
</style>
