import contactImage from '../assets/images/main/contact.jpg';
import offerImage from '../assets/images/main/offer.jpg';
import aboutuUsImage from '../assets/images/main/aboutus.jpg';
import rodoImage from '../assets/images/main/rodo.jpg';
import contactLowImage from '../assets/images/main/contact_low.jpg';
import offerLowImage from '../assets/images/main/offer_low.jpg';
import aboutuUsLowImage from '../assets/images/main/aboutus_low.jpg';
import rodoLowImage from '../assets/images/main/rodo_low.jpg';

export default [
  {
    text: 'oferta',
    image: offerImage,
    lowImage: offerLowImage,
    area: 'a',
    left: false,
    route: '/oferta',
  },
  {
    text: 'kontakt',
    image: contactImage,
    lowImage: contactLowImage,
    area: 'b',
    left: true,
    route: '/kontakt',
  },
  {
    text: 'o nas',
    image: aboutuUsImage,
    lowImage: aboutuUsLowImage,
    area: 'c',
    left: false,
    route: '/o-nas',
  },
  {
    text: 'rodo',
    image: rodoImage,
    lowImage: rodoLowImage,
    area: 'd',
    left: false,
    route: '/rodo',
  },
];
