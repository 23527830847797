<template>
  <div class="details">
    <div v-for="(value, name) in title" :key="name">
      <div v-if="product[name]" class="details__box">
        <p class="details__title">{{ value }}:</p>
        <p class="details__value">{{ product[name] }}</p>
      </div>
    </div>
    <div v-if="product.sizes" class="details__box">
      <p class="details__title">rozmiary:</p>
      <p class="details__value">{{ sizesText }}</p>
    </div>
    <div v-if="product.colors" class="details__box">
      <p class="details__title">kolory:</p>
      <div
        v-for="color in colors"
        :key="color"
        :class="[getColorClass(color), 'details__color']"
        :style="{
          '--first-color': `${productsColors[color[0]]}`,
          '--second-color': `${productsColors[color[1]]}`,
          '--third-color': `${productsColors[color[2]]}`,
        }"
      />
    </div>
  </div>
</template>

<script>
import productsColors from '../data/products_colors';

export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      productsColors,
      colors: [],
      title: {
        name: 'nazwa',
        category: 'kategoria',
        subcategory: 'podkategoria',
        producer: 'producent',
        description: 'opis',
      },
    };
  },
  created() {
    this.setColors();
  },
  computed: {
    sizesText() {
      return this.product.sizes.join(', ');
    },
  },
  methods: {
    setColors() {
      if (this.product.colors) this.product.colors.map(item => this.colors.push(item.split('/')));
    },

    getColorClass(color) {
      if (color.length === 2) return 'two';
      if (color.length === 3) return 'third';
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  width: 100%;
  padding: 20px;

  @media screen and (max-width: 576px) {
    padding: 15px;
  }
}

.details__box {
  display: flex;
  margin-bottom: 10px;
  text-transform: uppercase;
  line-height: 1.5;

  @media screen and (max-width: 992px) {
    line-height: 1.4;
    font-size: 0.9rem;
  }

  @media screen and (max-width: 768px) {
    line-height: 1.5;
    font-size: 1rem;
  }

  @media screen and (max-width: 576px) {
    line-height: 1.4;
    font-size: 0.9rem;
  }

  @media screen and (max-width: 420px) {
    line-height: 1.3;
    font-size: 0.85rem;
  }
}

.details__title {
  margin-right: 10px;
  font-weight: 600;
}

.details__color {
  position: relative;
  width: 23px;
  height: 23px;
  margin-right: 10px;
  background-color: var(--first-color);
  border: 1px solid $black;

  &.two {
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-left: 21px solid transparent;
      border-bottom: 21px solid var(--second-color);
      z-index: 1;
    }
  }

  &.third {
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      border-left: 14px solid transparent;
      border-bottom: 14px solid var(--second-color);
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-right: 14px solid transparent;
      border-top: 14px solid var(--third-color);
      z-index: 1;
    }
  }

  @media screen and (max-width: 992px) {
    width: 18px;
    height: 18px;

    &.two {
      &::before {
        border-width: 16px;
      }
    }

    &.third {
      &::before,
      &::after {
        border-width: 11px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 23px;
    height: 23px;

    &.two {
      &::before {
        border-width: 21px;
      }
    }

    &.third {
      &::before,
      &::after {
        border-width: 14px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    width: 18px;
    height: 18px;

    &.two {
      &::before {
        border-width: 16px;
      }
    }

    &.third {
      &::before,
      &::after {
        border-width: 11px;
      }
    }
  }
}
</style>
