<template>
  <section class="form">
    <p class="form__text">Formularz kontaktowy</p>
    <form class="form__form" autocomplete="off" @submit="submit">
      <form-input
        :name="form.email.name"
        :label="form.email.label"
        :type="form.email.type"
        @change="changeInput"
      />
      <form-input
        :name="form.name.name"
        :label="form.name.label"
        :type="form.name.type"
        @change="changeInput"
      />
      <form-input
        :name="form.phone.name"
        :label="form.phone.label"
        :pattern="form.phone.pattern"
        :placeholder="form.phone.placeholder"
        @change="changeInput"
      />
      <form-input
        last
        isTextarea
        :name="form.text.name"
        :label="form.text.label"
        :type="form.text.type"
        @change="changeInput"
      />
      <div class="form__checkbox">
        <input
          v-model="formData.checkbox"
          class="form__checkbox-input"
          :id="form.checkbox.name"
          :name="form.checkbox.name"
          required
          type="checkbox"
        />
        <label class="form__checkbox-label" :for="form.checkbox.name">
          {{ form.checkbox.label }}
        </label>
      </div>
      <p v-if="showConfirm" :class="['form__confirm', { success: success }]">
        {{ success ? 'Wiadomość została wysłana' : 'Błąd podczas wysyłania wiadomości' }}
      </p>
      <button class="form__button">Wyślij</button>
    </form>
  </section>
</template>

<script>
import emailjs from 'emailjs-com';
import FormInput from '../atoms/FormInput.vue';
import email from '../data/email';
import form from '../data/form';

export default {
  components: { FormInput },
  data() {
    return {
      form,
      showConfirm: false,
      success: true,
      formData: {
        email: '',
        name: '',
        phone: '',
        text: '',
        checkbox: false,
      },
    };
  },
  methods: {
    changeInput(event) {
      const newFormData = { ...this.formData, [event.target.name]: event.target.value };

      this.formData = newFormData;
    },

    setConfirmValue(success, showConfirm) {
      this.success = success;
      this.showConfirm = showConfirm;
    },

    clearFormData() {
      this.formData = {
        email: '',
        name: '',
        phone: '',
        text: '',
        checkbox: false,
      };
    },

    submit(event) {
      event.preventDefault();

      const params = {
        email: this.formData.email,
        name: this.formData.name,
        phone: this.formData.phone,
        text: this.formData.text,
      };

      emailjs.send(email.serviceID, email.templateID, params, email.userID).then(
        () => {
          this.setConfirmValue(true, true);
          this.clearFormData();
          setTimeout(() => this.setConfirmValue(true, false), 4000);
        },
        () => {
          this.setConfirmValue(false, true);
          setTimeout(() => this.setConfirmValue(false, false), 4000);
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  padding: 20px;
  border: 2px solid $black;
  margin-top: 10px;
}

.form__text {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 420px) {
    font-size: 1.35rem;
  }
}

.form__form {
  margin-top: 25px;

  @media screen and (max-width: 420px) {
    margin-top: 20px;
  }
}

.form__checkbox {
  display: flex;
  margin: 0 auto 30px;
  align-items: center;
  width: 95%;

  @media screen and (max-width: 420px) {
    margin-bottom: 20px;
  }

  .form__checkbox-input {
    margin-right: 10px;
  }

  .form__checkbox-label {
    font-size: 0.95rem;

    @media screen and (max-width: 420px) {
      font-size: 0.9rem;
    }
  }
}

.form__confirm {
  background-color: $red;
  color: $white;
  padding: 7.5px 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;

  @media screen and (max-width: 576px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 420px) {
    font-size: 1rem;
  }

  &.success {
    background-color: $green;
  }
}

.form__button {
  display: block;
  margin: auto;
  font-size: 1.15rem;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid rgba($color: $black, $alpha: 0.1);
  background-color: rgba($color: $black, $alpha: 0.8);
  color: $white;
  cursor: pointer;
  padding: 11.5px 14.5px;
  letter-spacing: 1px;
  transition: background 0.2s ease;

  @media screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.02);
    }

    &:active {
      color: rgba($color: $black, $alpha: 0.8);
      border-color: rgba($color: $black, $alpha: 0.8);
      background-color: $white;
    }
  }

  @media screen and (max-width: 1024px) {
    &:active {
      color: rgba($color: $black, $alpha: 0.8);
      border-color: rgba($color: $black, $alpha: 0.8);
      background-color: $white;
    }
  }

  &:active {
    color: rgba($color: $black, $alpha: 0.8);
    background-color: $white;
  }

  @media screen and (max-width: 420px) {
    font-size: 1rem;
    padding: 11.5px 13.5px;
  }
}
</style>
