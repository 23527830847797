export default {
  obuwie: {
    trzewiki: [
      {
        id: '841D8732AA11450D93ED956E98FC5424',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['bez_podnosek'],
        producer: 'urgent',
        name: '101 ob',
        description:
          "Trzewik zawodowy bez metalowego podnoska. Cholewka wykonana ze skóry bydlęcej typu 'buffalo' o grubości nie mniejszej niż 1,6 mm ze wstawkami z materiałów sztucznych na wysokości kostki. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest bardziej twardsza, wewnętrzna bardziej miękka.",
        img: 'https://www.anitexbhp.pl/images/boots/101_OB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/101_OB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/101_OB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/101_OB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/101_OB_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '799868098A1A41B8BF92C95202F8A02B',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek'],
        producer: 'urgent',
        name: '101 sb',
        description:
          "Trzewik bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana ze skóry bydlęcej typu 'buffalo' o grubości nie mniejszej niż 1,6 mm ze wstawkami z materiałów sztucznych na wysokości kostki. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka. Podeszwa antyelektrostatyczna.",
        img: 'https://www.anitexbhp.pl/images/boots/101_SB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/101_SB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/101_SB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/101_SB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/101_SB_4.jpg',
        ],
        sizes: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: 'CEDD92D450764B86838C816F38A5867D',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek'],
        producer: 'urgent',
        name: '102 s3 guma',
        description:
          'Trzewik bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Odporność na przebicie. Przepuszczalność wody i absorpcja wody. Podeszwa urzeźbiona. Cholewka cała wykonana ze skóry bydlęcej o grubości minimalnej 2 mm . Nie ma zatem żadnych wstawek z materiałów sztucznych (lepsza odporność na zniszczenia mechaniczne, jak i na pękanie spowodowane na przykład mrozem). Wodoodporna. Podeszwa wykonana z gumy: 1/ guma ma najlepsze parametry antypoślizgowe ze wszystkich materiałów używanych do produkcji podeszw 2/ jest odporniejsza od innych materiałów używanych do produkcji podeszw na wysoką temperaturę. Podeszwa antyelektrostatyczna.',
        img: 'https://www.anitexbhp.pl/images/boots/102_S3_GUMA_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/102_S3_GUMA_1.jpg',
          'https://www.anitexbhp.pl/images/boots/102_S3_GUMA_2.jpg',
          'https://www.anitexbhp.pl/images/boots/102_S3_GUMA_3.jpg',
          'https://www.anitexbhp.pl/images/boots/102_S3_GUMA_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: 'C30EF689A5B64215AEA38C498D4766AE',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['bez_podnosek', 'zima'],
        producer: 'urgent',
        name: '106 ob zima',
        description:
          "Trzewik zawodowy bez metalowego podnoska. Cholewka wykonana ze skóry bydlęcej typu 'buffalo' o grubości nie mniejszej niż 1,6 mm ze wstawkami z materiałów sztucznych na wysokości kostki, wewnątrz wyściółka ocieplająca. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest bardziej twardsza, wewnętrzna bardziej miękka.",
        img: 'https://www.anitexbhp.pl/images/boots/106_OB_ZIMA_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/106_OB_ZIMA_1.jpg',
          'https://www.anitexbhp.pl/images/boots/106_OB_ZIMA_2.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: 'DCDF589BB44D4D52937BA1D4C12CAAA3',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek', 'zima'],
        producer: 'urgent',
        name: '106 sb zima',
        description:
          "Trzewik bezpieczny z metalowym podnoskiem. Cholewka wykonana ze skóry bydlęcej typu 'buffalo' o grubości nie mniejszej niż 1,6 mm ze wstawkami z materiałów sztucznych na wysokości kostki, wewnątrz wyściółka ocieplająca. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest bardziej twardsza, wewnętrzna bardziej miękka.",
        img: 'https://www.anitexbhp.pl/images/boots/106_SB_ZIMA_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/106_SB_ZIMA_1.jpg',
          'https://www.anitexbhp.pl/images/boots/106_SB_ZIMA_2.jpg',
        ],
        sizes: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
        new: true,
      },
      {
        id: '30496B8AC8DE41279DA7AA663E7A5826',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek'],
        producer: 'urgent',
        name: '111 s1',
        description:
          'Trzewik bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana z materiałów typu płótno (dobrze przepuszcza wilgoć). Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka. Podeszwa antyelektrostatyczna.',
        img: 'https://www.anitexbhp.pl/images/boots/111_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/111_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/111_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/111_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/111_S1_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '5E79A3110014413D9AB025B89B30D13F',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['bez_podnosek', 'zima'],
        producer: 'urgent',
        name: '112 ob zima',
        description:
          "Trzewik zawodowy bez metalowego podnoska. Cholewka wykonana ze skóry typu 'buffalo' o grubości nie mniejszej niż 1,6 mm, wewnątrz wyściółka ocieplająca. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.",
        img: 'https://www.anitexbhp.pl/images/boots/112_OB_ZIMA_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/112_OB_ZIMA_1.jpg',
          'https://www.anitexbhp.pl/images/boots/112_OB_ZIMA_2.jpg',
          'https://www.anitexbhp.pl/images/boots/112_OB_ZIMA_3.jpg',
          'https://www.anitexbhp.pl/images/boots/112_OB_ZIMA_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '919956B7AD5F40B7B8B5F66671EB4A53',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek', 'zima'],
        producer: 'urgent',
        name: '112 sb zima',
        description:
          "Trzewik bezpieczny z metalowym podnoskiem (podstawowa ochrona). Cholewka wykonana ze skóry typu 'buffalo' o grubości nie mniejszej niż 1,6 mm, wewnątrz wyściółka ocieplająca. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.",
        img: 'https://www.anitexbhp.pl/images/boots/112_SB_ZIMA_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/112_SB_ZIMA_1.jpg',
          'https://www.anitexbhp.pl/images/boots/112_SB_ZIMA_2.jpg',
          'https://www.anitexbhp.pl/images/boots/112_SB_ZIMA_3.jpg',
          'https://www.anitexbhp.pl/images/boots/112_SB_ZIMA_4.jpg',
        ],
        sizes: [38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
        new: true,
      },
      {
        id: '762F523449424F54A2123A3765BBA0C1',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek', 'spaw'],
        producer: 'urgent',
        name: '115 s1p',
        description:
          'Trzewik bezpieczny z metalowym podnoskiem, podeszwa z wkładką antyprzebiciową. Cholewka wykonana ze skóry typu buffalo o grubości nie mniejszej niż 1,6 mm, ze wstawkami z materiałów sztucznych na wysokości kostek. Osłona ze skóry typu buffalo o grubości nie mniejszej niż 1,6 mm. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza , wewnętrzna bardziej miękka. Wkładka antyprzebiciowa.',
        img: 'https://www.anitexbhp.pl/images/boots/115_S1P_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/115_S1P_1.jpg',
          'https://www.anitexbhp.pl/images/boots/115_S1P_2.jpg',
          'https://www.anitexbhp.pl/images/boots/115_S1P_3.jpg',
          'https://www.anitexbhp.pl/images/boots/115_S1P_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '808AB37360194DE3B4AD603893AECC69',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek', 'lady'],
        producer: 'urgent',
        name: '116 s1 lady',
        description:
          'Trzewik bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Górna powierzchnia cholewy wykonana została z nubucku. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza , wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/116_S1_LADY_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/116_S1_LADY_1.jpg',
          'https://www.anitexbhp.pl/images/boots/116_S1_LADY_2.jpg',
          'https://www.anitexbhp.pl/images/boots/116_S1_LADY_3.jpg',
          'https://www.anitexbhp.pl/images/boots/116_S1_LADY_4.jpg',
        ],
        sizes: [35, 36, 37, 38, 39, 40, 41],
        new: true,
      },
      {
        id: '822DE21C62BE4E879843CBDFF588C627',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek', 'spaw'],
        producer: 'urgent',
        name: '117 s3 hro',
        description:
          'Trzewik spawalniczy z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Odporność na przebicie. Przepuszczalność wody i absorpcja wody. Podeszwa urzeźbiona. Cholewka wykonana ze skóry bydlęcej o grubości 2,20 - 3,00 mm, nici - kevlar, wkładka - eva. Podeszwa wykonana z PU / GUMA HRO do 300 stopni C.',
        img: 'https://www.anitexbhp.pl/images/boots/117_S3_HRO_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/117_S3_HRO_1.jpg',
          'https://www.anitexbhp.pl/images/boots/117_S3_HRO_2.jpg',
          'https://www.anitexbhp.pl/images/boots/117_S3_HRO_3.jpg',
          'https://www.anitexbhp.pl/images/boots/117_S3_HRO_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '3717FF4E6F624C55881A7AAE34572DF9',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['bez_podnosek'],
        producer: 'urgent',
        name: '120 ob',
        description:
          "Trzewik zawodowy bez metalowego podnoska. Cholewka wykonana z bydlęcej skóry typu 'szalony koń (crasy horse)' o grubości nie mniejszej niż 2,2 mm. Konstrukcja cholewki jest wyjątkowo przyjazna dla stopy, nawet przy długotrwałym użytkowaniu. Wkładka profilowana do kształtu stopy, wchłania wilgoć. Podeszwa zewnętrzna wykonana z bezbarwnego TPU (poliuretan termoplastyczny), w porównaniu z podeszwą z PU jest bardziej elastyczna ('pracuje' nawet w niskich temperaturach), nie rysuje powierzchni, jest odporniejsza na ścieranie. Jest trwalsza podczas pracy na mrozie, czy śniegu i w temperaturze do 130 st. C. Podeszwa wewnętrzna wykonana z PU (poliuretan) o mniejszej gęstości, aby była bardziej miękka i lepiej układała się do stopy.",
        img: 'https://www.anitexbhp.pl/images/boots/120_OB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/120_OB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/120_OB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/120_OB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/120_OB_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '943E350FF3C047DB9D3D5E3B92C7E207',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek'],
        producer: 'urgent',
        name: '120 s1 tpu',
        description:
          "Trzewik bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana została z bydlęcej skóry typu 'szalony koń (crasy horse)' o grubości nie mniejszej niż 2,2 mm. Konstrukcja cholewki jest wyjątkowo przyjazna dla stopy, nawet przy długotrwałym użytkowaniu. Wkładka profilowana do kształtu stopy, wchłania wilgoć. Podeszwa zewnętrzna wykonana z bezbarwnego TPU (poliuretan termoplastyczny), w porównaniu z podeszwą z PU jest bardziej elastyczna ('pracuje' nawet w niskich temperaturach), nie rysuje powierzchni, jest odporniejsza na ścieranie. Jest trwalsza podczas pracy na mrozie, czy śniegu i w temperaturze do 130 st. C. Podeszwa wewnętrzna wykonana z PU (poliuretan) o mniejszej gęstości aby była bardziej miękka i lepiej układała się do stopy.",
        img: 'https://www.anitexbhp.pl/images/boots/120_S1_TPU_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/120_S1_TPU_1.jpg',
          'https://www.anitexbhp.pl/images/boots/120_S1_TPU_2.jpg',
          'https://www.anitexbhp.pl/images/boots/120_S1_TPU_3.jpg',
          'https://www.anitexbhp.pl/images/boots/120_S1_TPU_4.jpg',
        ],

        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '83AA0690227A4785BB2F1F43654A349C',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek'],
        producer: 'urgent',
        name: '122 s1',
        description:
          'Trzewik bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana z zamszowej bydlęcej skóry. Podeszwa zewnętrzna i wewnętrzna wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza , wewnętrzna bardziej miękka. Właściwości antypoślizgowe wg normy SRC.',
        img: 'https://www.anitexbhp.pl/images/boots/122_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/122_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/122_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/122_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/122_S1_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '7E7E99A1C1724016BA46581E250A2A9F',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['bez_podnosek'],
        producer: 'urgent',
        name: '126 ob',
        description:
          "Trzewik zawodowy bez metalowego podnoska. Cholewka wykonana z bydlęcej skóry typu 'szalony koń (crasy horse)' o grubości nie mniejszej niż 2,2 mm. Konstrukcja cholewki jest wyjątkowo przyjazna dla stopy, nawet przy długotrwałym użytkowaniu. Wkładka profilowana do kształtu stopy, wchłania wilgoć. Podeszwa zewnętrzna wykonana z bezbarwnego TPU (poliuretan termoplastyczny), w porównaniu z podeszwą z PU jest bardziej elastyczna ('pracuje' nawet w niskich temperaturach), nie rysuje powierzchni, jest odporniejsza na ścieranie. Jest trwalsza podczas pracy na mrozie, czy śniegu i w temperaturze do 130 st. C. Podeszwa wewnętrzna wykonana z PU (poliuretan) o mniejszej gęstości aby była bardziej miękka i lepiej układała się do stopy.",
        img: 'https://www.anitexbhp.pl/images/boots/126_OB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/126_OB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/126_OB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/126_OB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/126_OB_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '1521A723696F41B28726E03D73F32743',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek'],
        producer: 'urgent',
        name: '126 s1',
        description:
          "Trzewik bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana z bydlęcej skóry typu 'szalony koń (crasy horse)' o grubości nie mniejszej niż 2,2 mm. Konstrukcja cholewki jest wyjątkowo przyjazna dla stopy, nawet przy długotrwałym użytkowaniu. Wkładka profilowana do kształtu stopy, wchłania wilgoć. Podeszwa zewnętrzna wykonana z bezbarwnego TPU (poliuretan termoplastyczny), w porównaniu z podeszwą z PU jest bardziej elastyczna ('pracuje' nawet w niskich temperaturach), nie rysuje powierzchni, jest odporniejsza na ścieranie. Jest trwalsza podczas pracy na mrozie, czy śniegu i w temperaturze do 130 st. C. Podeszwa wewnętrzna wykonana z PU (poliuretan) o mniejszej gęstości aby była bardziej miękka i lepiej układała się do stopy.",
        img: 'https://www.anitexbhp.pl/images/boots/126_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/126_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/126_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/126_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/126_S1_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: 'A307F94978574E43A0DC342315CFCFFB',
        category: 'obuwie',
        subcategory: 'trzewiki',
        type: ['podnosek'],
        producer: 'urgent',
        name: '128 sb zima',
        description:
          "Trzewik bezpieczny z metalowym podnoskiem (podstawowa ochrona). Cholewka wykonana ze skóry typu 'buffalo' o grubości nie mniejszej niż 1,6 mm, wewnątrz wyściółka ocieplająca. W tylnej części posiada element odblaskowy. Podeszwa zewnętrzna i wewnętrzna wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.",
        img: 'https://www.anitexbhp.pl/images/boots/128_SB_ZIMA_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/128_SB_ZIMA_1.jpg',
          'https://www.anitexbhp.pl/images/boots/128_SB_ZIMA_2.jpg',
          'https://www.anitexbhp.pl/images/boots/128_SB_ZIMA_3.jpg',
          'https://www.anitexbhp.pl/images/boots/128_SB_ZIMA_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
    ],
    polbuty: [
      {
        id: '366687FF7EE54A41A0238C26CE1C56B5',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['bez_podnosek'],
        producer: 'urgent',
        name: '201 ob',
        description:
          "Półbut zawodowy bez metalowego podnoska. Cholewka wykonana ze skóry bydlęcej typu 'buffalo' o grubości nie mniejszej niż 1,6 mm. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka. Podeszwa antyelektrostatyczna.",
        img: 'https://www.anitexbhp.pl/images/boots/201_OB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/201_OB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/201_OB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/201_OB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/201_OB_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: 'EE99B79F9AEB4C0693F6D1B2665C2F7A',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '201 sb',
        description:
          "Półbut bezpieczny z metalowym podnoskiem (ochrona podstawowa). Cholewka wykonana ze skóry typu 'buffalo' o grubości nie mniejszej niż 1,6 mm. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka. Podeszwa antyelektrostatyczna.",
        img: 'https://www.anitexbhp.pl/images/boots/201_SB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/201_SB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/201_SB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/201_SB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/201_SB_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '22C7AF41A3E64CFC8786057E96F3D075',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '205 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana z zamszowej bydlęcej skóry, wyściółka ze skóry świni (w porównaniu z wyściółką z tworzyw sztucznych znacznie lepiej przepuszcza parę wodną i jest wygodniejsza dla stopy). Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnetrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/205_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/205_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/205_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/205_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/205_S1_4.jpg',
        ],
        sizes: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '68E14CA66C9E43DE971877A8A782C292',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '211 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana z zamszowej bydlęcej skóry oraz dużych fragmentów materiałów typu płótno (dobrze przepuszcza wilgoć). Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/211_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/211_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/211_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/211_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/211_S1_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: 'B4DE10A6CB9B4886811FF161472F4301',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['bez_podnosek'],
        producer: 'urgent',
        name: '212 ob',
        description:
          'Półbut zawodowy bez metalowego podnoska. Cholewka wykonana ze skóry zamszowej. Podeszwa zewnętrzna i wewnętrzna wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnetrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/212_OB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/212_OB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/212_OB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/212_OB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/212_OB_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: 'E2636B24F5EB491D86AA35A4F3E66E56',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '212 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana ze skóry zamszowej. Podeszwa zewnętrzna i wewnętrzna wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnetrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/212_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/212_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/212_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/212_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/212_S1_4.jpg',
        ],
        sizes: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '62C33C619A6049AE9A70244B0C5540E1',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '214 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka cała wykonana z nubukowej skóry. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/214_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/214_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/214_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/214_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/214_S1_4.jpg',
        ],
        sizes: [36, 37, 38, 39, 40, 41],
        new: true,
      },
      {
        id: '5974F47C39024031AC4275EA8166B4C5',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '215 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka i język wykonane z bydlęcej skóry + materiał typu siateczka, wewnątrz wyściółka oddychająca. Podeszwa wewnętrzna wykonana z poliuretanu, podeszwa środkowa z poliuretaranu termoplastycznego, podeszwa zewnętrzna z gumy.',
        img: 'https://www.anitexbhp.pl/images/boots/215_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/215_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/215_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/215_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/215_S1_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: 'C201A309CC0A40AF9324993FD76BB53A',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '216 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana ze skóry zamszowej. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/216_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/216_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/216_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/216_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/216_S1_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '52303C421E8A485FA7B62542CAF60311',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '220 s1',
        description:
          "Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Chloewka wykonana z bydlęcej skóry typu 'szalony koń (crasy horse)' o grubości nie mniejszej niż 2,2 mm. Konstrukcja cholewki jest wyjątkowo przyjazna dla stopy, nawet przy długotrwałym użytkowaniu. Wkładka profilowana do kształtu stopy, wchłania wilgoć. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.",
        img: 'https://www.anitexbhp.pl/images/boots/220_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/220_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/220_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/220_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/220_S1_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '0ABF60BAC6B742D1A543988C124D9CF2',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '224 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana nubukowej skóry + materiał typu siateczka. Podeszwa zewnętrzna i wewnętrzna wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/224_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/224_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/224_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/224_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/224_S1_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '1DD10C4D43DA4A709DDCFBF06CD4FECB',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '231 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana z materiałów włókienniczych. Podeszwa zewnętrzna i wewnętrzna wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/231_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/231_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/231_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/231_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/231_S1_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '9302BC2EEAE1478EAF80B6B03451579A',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '234 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana ze skóry zamszowej. Podeszwa	wewnętrzna jest wykonana z EVA, zewnętrzna wykonana z gumy o dobrych właściwościach antypoślizgowych.',
        img: 'https://www.anitexbhp.pl/images/boots/234_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/234_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/234_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/234_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/234_S1_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '734D95CF184D4CC0858B387CDA19D10A',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '238 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana z materiałów tekstylnych, wzmocnienia z PU. Podeszwa zewnętrzna i wewnętrzna wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/238_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/238_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/238_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/238_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/238_S1_4.jpg',
        ],
        sizes: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '994DF9E99155405CAC28EDDFD4502D7F',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['bez_podnosek'],
        producer: 'urgent',
        name: '241 ob',
        description:
          'Półbut zawodowy bez metalowego podnoska. Cholewka wykonana ze skóry zamszowej. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnetrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/241_OB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/241_OB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/241_OB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/241_OB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/241_OB_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '244774D5B5C948BD98965D88CE16B720',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['bez_podnosek'],
        producer: 'urgent',
        name: '261 ob',
        description:
          'Półbut zawodowy bez metalowego podnoska. Cholewka wykonana z nubukowej skóry z elementami materiałów tekstylnych. Podeszwa wykonana z gumy, 1/ guma ma najlepsze parametry antypoślizgowe ze wszystkich materiałów używanych do produkcji podeszw, 2/ jest odporniejsza od innych materiałów używanych do produkcji podeszw na niską (mróz) i wysoką temperaturę.',
        img: 'https://www.anitexbhp.pl/images/boots/261_OB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/261_OB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/261_OB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/261_OB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/261_OB_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '2F84913CE0D84361ABD9545DF6CA26A6',
        category: 'obuwie',
        subcategory: 'półbuty',
        type: ['podnosek'],
        producer: 'urgent',
        name: '261 s1',
        description:
          'Półbut bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana z nubukowej skóry z elementami materiałów tekstylnych. Podeszwa wykonana została z gumy, 1/ guma ma najlepsze parametry antypoślizgowe ze wszystkich materiałów używanych do produkcji podeszw, 2/ jest odporniejsza od innych materiałów używanych do produkcji podeszw na niską (mróz) i wysoką temperaturę.',
        img: 'https://www.anitexbhp.pl/images/boots/261_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/261_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/261_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/261_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/261_S1_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
    ],
    sandaly: [
      {
        id: '6B57B88C061B4E6BA25469A5039627CE',
        category: 'obuwie',
        subcategory: 'sandały',
        type: ['bez_podnosek'],
        producer: 'urgent',
        name: '301 ob',
        description:
          "Sandał zawodowy bez metalowego podnoska. Cholewka wykonana ze skóry typu 'buffalo' o grubości nie mniejszej niż 1,6 mm. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.",
        img: 'https://www.anitexbhp.pl/images/boots/301_OB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/301_OB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/301_OB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/301_OB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/301_OB_4.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '654BB8B0F79F48DC8EDAC6FE6540CF96',
        category: 'obuwie',
        subcategory: 'sandały',
        type: ['podnosek'],
        producer: 'urgent',
        name: '301 sb',
        description:
          "Sandał bezpieczny z metalowym podnoskiem (podstawowa ochrona). Cholewka wykonana ze skóry typu 'buffalo' o grubości nie mniejszej niż 1,6 mm. Podeszwa zewnętrzna i wewnętrzna jest wykonana z PU/PU (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.",
        img: 'https://www.anitexbhp.pl/images/boots/301_SB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/301_SB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/301_SB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/301_SB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/301_SB_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '43935FC3416841568F0B54A413CEE722',
        category: 'obuwie',
        subcategory: 'sandały',
        type: ['bez_podnosek'],
        producer: 'urgent',
        name: '302 ob',
        description:
          'Sandał zawodowy bez metalowego podnoska. Cholewka wykonana z zamszowej bydlęcej skóry, wyściółka ze skóry świni (w porównaniu z wyściółką z tworzyw sztucznych znacznie lepiej przepuszcza parę wodną i jest wygodniejsza dla stopy). Podeszwa zewnętrzna i wewnętrzna jest wykonana z poliuretanu (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/302_OB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/302_OB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/302_OB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/302_OB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/302_OB_4.jpg',
        ],
        sizes: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '908F6CBC207A480B8E421899EF9B6B5B',
        category: 'obuwie',
        subcategory: 'sandały',
        type: ['podnosek'],
        producer: 'urgent',
        name: '302 s1',
        description:
          'Sandał bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana z zamszowej bydlęcej skóry, wyściółka ze skóry świni (w porównaniu z wyściółką z tworzyw sztucznych znacznie lepiej przepuszcza parę wodną i jest wygodniejsza dla stopy). Podeszwa zewnętrzna i wewnętrzna jest wykonana z poliuretanu (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza , wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/302_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/302_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/302_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/302_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/302_S1_4.jpg',
        ],
        sizes: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '260A1851FC2142929605B7452037A980',
        category: 'obuwie',
        subcategory: 'sandały',
        type: ['podnosek'],
        producer: 'urgent',
        name: '304 sb',
        description:
          'Sandał bezpieczny z metalowym podnoskiem (podstawowa ochrona). Cholewka wykonana w całości z zamszowej, bydlęcej skóry, wyścióka typu siateczka. Podeszwa zewnętrzna i wewnętrzna wykonana z poliuretanu (dwie różne gęstości poliuretanu), podeszwa zewnętrzna jest twardsza, wewnętrzna bardziej miękka.',
        img: 'https://www.anitexbhp.pl/images/boots/304_SB_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/304_SB_1.jpg',
          'https://www.anitexbhp.pl/images/boots/304_SB_2.jpg',
          'https://www.anitexbhp.pl/images/boots/304_SB_3.jpg',
          'https://www.anitexbhp.pl/images/boots/304_SB_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '989AB842698F4DC9A7A664286CCB7205',
        category: 'obuwie',
        subcategory: 'sandały',
        type: ['podnosek'],
        producer: 'urgent',
        name: '307 s1',
        description:
          'Sandał bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka wykonana z zamszowej bydlęcej skóry + materiał typu siateczka, wyściółka typu siateczka.',
        img: 'https://www.anitexbhp.pl/images/boots/307_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/307_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/307_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/307_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/307_S1_4.jpg',
        ],
        sizes: [35, 36, 37, 38, 39, 40, 41],
        new: true,
      },
      {
        id: '4B0D3C636455466C8B8956162525BD74',
        category: 'obuwie',
        subcategory: 'sandały',
        type: ['podnosek'],
        producer: 'urgent',
        name: '310 s1',
        description:
          'Sandał bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewa i język wykonane zostały z nubuku + materiał typu siateczka. Podeszwa wykonana z poliuretanu (PU), podwójny wtrysk.',
        img: 'https://www.anitexbhp.pl/images/boots/310_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/310_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/310_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/310_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/310_S1_4.jpg',
        ],
        sizes: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '58FF96BC35F44721ADDD59B9598D7F20',
        category: 'obuwie',
        subcategory: 'sandały',
        type: ['podnosek'],
        producer: 'urgent',
        name: '361 s1',
        description:
          'Sandał bezpieczny z metalowym podnoskiem. Zamknięty obszar pięty, właściwości antyelektrostatyczne, absorpcja energii w pięcie. Cholewka i język wykonane z nubuku + materiał typu siateczka. Podeszwa wykonana z gumy, 1/ guma ma najlepsze parametry antypoślizgowe ze wszystkich materiałów używanych do produkcji podeszw, 2/ jest odporniejsza od innych materiałów używanych do produkcji podeszw na niską (mróz) i wysoką temperaturę.',
        img: 'https://www.anitexbhp.pl/images/boots/361_S1_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/361_S1_1.jpg',
          'https://www.anitexbhp.pl/images/boots/361_S1_2.jpg',
          'https://www.anitexbhp.pl/images/boots/361_S1_3.jpg',
          'https://www.anitexbhp.pl/images/boots/361_S1_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
    ],
    eva: [
      {
        id: '884E0F165E1647EF93E71B40D5D727C8',
        category: 'obuwie',
        subcategory: 'eva',
        producer: 'dry walker',
        name: 'eva 101',
        description:
          'Obuwie wykonane z najwyższej jakości materiału EVA. Wodoszczelne i bardzo lekkie. Wyposażone w specjalistyczne wkładki gwarantujące izolację do -30 stopni, matę absorbującą wibracje na pięcie oraz wkładki z matą antypoślizgową.',
        img: 'https://www.anitexbhp.pl/images/boots/EVA_101_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/boots/EVA_101_1.jpg'],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: 'FC8A320BE8DE40B6990D69C2F0549C08',
        category: 'obuwie',
        subcategory: 'eva',
        producer: 'dry walker',
        colors: ['moro', 'black'],
        name: 'eva 103 men',
        description:
          'Obuwie wykonane z najwyższej jakości materiału EVA. Wodoszczelne i bardzo lekkie. Wyposażone w specjalistyczne wkładki gwarantujące izolację do -30 stopni, matę absorbującą wibracje na pięcie oraz wkładki z matą antypoślizgową.',
        img: 'https://www.anitexbhp.pl/images/boots/EVA_103_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/EVA_103_1.jpg',
          'https://www.anitexbhp.pl/images/boots/EVA_103_2.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '7547B16B11704C3E8D15DA1B8D626B75',
        category: 'obuwie',
        subcategory: 'eva',
        producer: 'dry walker',
        name: 'eva 103 kołnierz',
        description:
          'Obuwie wykonane z najwyższej jakości materiału EVA. Wodoszczelne i bardzo lekkie. Wyposażone w specjalną antypoślizgową skarpetę o świetnych właściwościach termoizolacyjnych.',
        img: 'https://www.anitexbhp.pl/images/boots/EVA_103_K_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/EVA_103_K_1.jpg',
          'https://www.anitexbhp.pl/images/boots/EVA_103_K_2.jpg',
        ],
        sizes: [40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '87712A12CF234F959F95D90FF052A012',
        category: 'obuwie',
        subcategory: 'eva',
        producer: 'dry walker',
        colors: ['moro', 'black'],
        name: 'eva 107 lady',
        description:
          'Obuwie wykonane z najwyższej jakości materiału EVA. Wodoszczelne i bardzo lekkie. Wyposażone w specjalistyczne wkładki gwarantujące izolację do -30 stopni, matę absorbującą wibracje na pięcie oraz wkładki z matą antypoślizgową.',
        img: 'https://www.anitexbhp.pl/images/boots/EVA_107_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/EVA_107_1.jpg',
          'https://www.anitexbhp.pl/images/boots/EVA_107_2.jpg',
        ],
        sizes: [37, 38, 39, 40, 41],
        new: true,
      },
      {
        id: '67DEE35D065C41C28DFD039F8E2E4BD9',
        category: 'obuwie',
        subcategory: 'eva',
        producer: 'dry walker',
        colors: ['moro', 'black', 'red', 'turkus', 'yellow'],
        name: 'eva chodak 110',
        description:
          'Obuwie wykonane z najwyższej jakości materiału EVA. Wodoszczelne i bardzo lekkie. Wyposażone w specjalną matę absorbującą wibracje na pięcie.',
        img: 'https://www.anitexbhp.pl/images/boots/EVA_110_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/EVA_110_1.jpg',
          'https://www.anitexbhp.pl/images/boots/EVA_110_2.jpg',
          'https://www.anitexbhp.pl/images/boots/EVA_110_3.jpg',
          'https://www.anitexbhp.pl/images/boots/EVA_110_4.jpg',
          'https://www.anitexbhp.pl/images/boots/EVA_110_5.jpg',
        ],
        sizes: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
        new: true,
      },
      {
        id: '4A4065DC4E47498F95CD532C5DE4E7E3',
        category: 'obuwie',
        subcategory: 'eva',
        producer: 'demar',
        name: 'eva 3920',
        description:
          'Lekkie kalosze ochronne Agro Filcok EVA. Buty są mocne, elastyczne i łatwe do czyszczenia. Komfort użytkowania zapewnia antypoślizgowa podeszwa oraz filcowa podszewka. Izolacja od zimna (CI).',
        img: 'https://www.anitexbhp.pl/images/boots/EVA_3920_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/boots/EVA_3920_1.jpg'],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
        new: true,
      },
    ],
    pvc: [
      {
        id: '872FED7423754892A10E97719459DCDC',
        category: 'obuwie',
        subcategory: 'pvc',
        producer: 'fagum stomil',
        name: 'boryna',
        description:
          'Obuwie gumowo - filcowe. Wierzch i spód wykonane z gumy, podszewka oraz kołnierz filcowe. Wysokość butów 37 cm. PN-EN ISO 20347:2012 OB FO CI SRC.',
        img: 'https://www.anitexbhp.pl/images/boots/BORYNA_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/BORYNA_1.jpg',
          'https://www.anitexbhp.pl/images/boots/BORYNA_2.jpg',
          'https://www.anitexbhp.pl/images/boots/BORYNA_3.jpg',
          'https://www.anitexbhp.pl/images/boots/BORYNA_4.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47],
        new: true,
      },
      {
        id: '7A0BB07431F7434C9A855C2D5C37421B',
        category: 'obuwie',
        subcategory: 'pvc',
        producer: 'agro-gum',
        name: 'pvc-filc',
        description:
          'Buty typu gumofilce wykonane z najlepszej jakości tworzywa PVC. Posiadają grubą, antypoślizgową podeszwę. Odporne na warunki atmosferyczne.',
        img: 'https://www.anitexbhp.pl/images/boots/PVC_FILC_2.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/PVC_FILC_1.jpg',
          'https://www.anitexbhp.pl/images/boots/PVC_FILC_2.jpg',
          'https://www.anitexbhp.pl/images/boots/PVC_FILC_3.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45],
        new: true,
      },
      {
        id: '2F2848489870429CB19DACC4184DA029',
        category: 'obuwie',
        subcategory: 'pvc',
        producer: 'pros',
        name: 'woder standard',
        description:
          'Wodery Standard. Wodoochronna, wytrzymała tkanina Plavitex Heavy Duty, gramatura 680 g/m². Wgrzane na stałe wysokiej jakości kalosze. Wytrzymałe szwy zgrzewane obustronnie.',
        img: 'https://www.anitexbhp.pl/images/boots/WODER_STANDARD_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/boots/WODER_STANDARD_1.jpg',
          'https://www.anitexbhp.pl/images/boots/WODER_STANDARD_2.jpg',
        ],
        sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
        new: true,
      },
    ],
  },
  rekawice: {
    powlekane: [
      {
        id: '57474A07C3F849BEB5D49A0C9B83C5D2',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1000',
        description:
          'Rękawica robocza wykonana z bawełny (85%) z dodatkiem poliestru (15%) o gramaturze 55g, powlekana od wewnętrznej części powłoką latexu (80%) oraz nitrylu (20%). Kategoria ochrony: CE I.',
        img: 'https://www.anitexbhp.pl/images/gloves/1000_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1000_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '42F21D48CF4F47BEAA818792EF9AACC5',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1003',
        description:
          'Rękawica robocza wykonana z poliestru, powlekana od wewnętrznej części powłoką latexu. Kategoria ochrony: CE I. Norma EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1003_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1003_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: '1F2B34A6B7E74922B4A965C365C030BD',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1005',
        description:
          'Rękawica ochronna wykonana z poliestru, powlekana nitrylem. Dobre czucie trzymanych rzeczy, odporność na oleje. Kategoria ochrony: CE I. Norma: EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1005_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1005_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: '42DC3BC6D1DE45A1BDCB98B9E467CA99',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1006',
        description:
          'Rękawica ochronna wykonana z bawełny, powlekana nitrylem. Dobre czucie trzymanych rzeczy, odporność na oleje. Kategoria ochrony: CE I. Norma: EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1006_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1006_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: '3C53A3C09A6A4D88AD6677E695360F03',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1007',
        description:
          'Rękawica ochronna ze sztywnym mankietem, wykonana z bawełny, powlekana nitrylem. Duża odporność na przetarcia, odporność na oleje. Kategoria ochrony: CE I. Norma: EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1007_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1007_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '66606B8BDF014E5CB7AFC1A8C4C82D0D',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1008',
        description:
          'Rękawica ochronna wykonana z poliestru, na części chwytnej powlekana poliuretanem, zakończona ściągaczem. Doskonałe czucie trzymanego przedmiotu. Do prac, gdzie wymagana jest precyzja. Każda para pakowana osobno. Kategoria ochrony: CE I. Norma: EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1008_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1008_1.jpg'],
        sizes: [7, 8, 9, 10],
        new: true,
      },
      {
        id: '32F0819FC27E4AB08163350A2C77B8FA',
        category: 'rękawice',
        subcategory: 'powlekane',
        type: ['zima'],
        producer: 'urgent',
        name: '1010 ocieplane',
        description:
          'Rękawica robocza wykonana z akryliku, powlekana od wewnętrznej części powłoką latexu, wewnątrz ocieplana. Kategoria ochrony: CE I.',
        img: 'https://www.anitexbhp.pl/images/gloves/1010_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1010_1.jpg'],
        sizes: [9, 10],
        new: true,
      },
      {
        id: '1C62A8E95F3C414FB374C123D9B62BB8',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1011',
        description:
          'Rękawica ochronna wykonana z dzianiny bawełnianej, oblewanej do polowy żółtą gumą o porowatej strukturze. Kategoria Ochrony CE I. Norma EN: 420',
        img: 'https://www.anitexbhp.pl/images/gloves/1011_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1011_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '1B1E0D5DC5494E61BAFB35BF610AD236',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1012 nakrapiane',
        description:
          'Rękawica wykonana z poliestru i bawełny o gramaturze 400g. Nakropienie PVC 120g na wewnętrznej części dłoni.',
        img: 'https://www.anitexbhp.pl/images/gloves/1012_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1012_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: 'B23F16BABD794730AD5292707D4D3DDF',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1017',
        description:
          'Rękawica ochronna, wykonana z bawełny, powlekana nitrylem, zakończona ściągaczem, gramatura 100g. Duża odporność na przetarcia, odporność na oleje. Kategoria ochrony: CE II. Norma: EN 420, EN 388.',
        img: 'https://www.anitexbhp.pl/images/gloves/1017_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1017_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '10234F4F289E48C3B914B6E29E8A5464',
        category: 'rękawice',
        subcategory: 'powlekane',
        type: ['zima'],
        producer: 'urgent',
        name: '1020 ocieplane',
        description:
          'Rękawica robocza wykonana z poliestru, powlekana od wewnętrznej części powłoką latexu, wewnątrz ocieplana. Kategoria ochrony: CE I.',
        img: 'https://www.anitexbhp.pl/images/gloves/1020_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1020_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '2BD209233EFE4531B2A33A839BD559B1',
        category: 'rękawice',
        subcategory: 'powlekane',
        type: ['zima'],
        producer: 'urgent',
        name: '1028 ocieplane',
        description:
          'Rękawica wykonana z poliestru, powlekana 3/4 dłoni wysokiej jakości, miękkim, głatkim lateksem, końcówki palców pokryte spienionym lateksem, wewnątrz ocieplana. Kategoria ochrony: CE I. Norma EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1028_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1028_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '5A557D0203EB4BB9A7A9D77944504FBE',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1030',
        description:
          'Rękawica ochronna, wykonana z bawełny, powlekana PCV, zakończona ściągaczem, gramatura 125g. Kategoria ochrony: CE II. Norma: EN 420, EN388.',
        img: 'https://www.anitexbhp.pl/images/gloves/1030_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1030_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '01584229E6EB4AC5B94CC1BFAD73E7D1',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1044',
        description:
          'Rękawica wykonana z poliestru, powlekana 3/4 dłoni wysokiej jakości, miękkim, głatkim lateksem, końcówki palców pokryte spienionym lateksem. Kategoria ochrony: CE I. Norma EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1044_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1044_1.jpg'],
        sizes: [9, 10],
        new: true,
      },
      {
        id: '07D8FD18BAC3454181104F03D5C66509',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1045',
        description:
          'Rękawica wykonana z poliestru, powlekana 3/4 dłoni wysokiej jakości, miękkim, gładkim lateksem, końcówki palców pokryte spienionym lateksem. Kategoria ochrony: CE I. Norma EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1045_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1045_1.jpg'],
        sizes: [9, 10],
        new: true,
      },
      {
        id: '0C85F7FC8CDA4240A9A867C3741CF2EF',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1047',
        description:
          'Rękawica ochronna wykonana z poliestru, powlekana karbowanym nitrylem. Dobre czucie trzymanych rzeczy, odporność na oleje. Kategoria ochrony: CE I. Norma: EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1047_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1047_1.jpg'],
        sizes: [8, 9, 10],
        new: true,
      },
      {
        id: '0547CFE27AD14474A739FDE5DD255361',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1055 antyprzecięciowe',
        description:
          'Rękawica robocza wykonana z przędzy HDPE, powlekana od wewnętrznej części powłoką poliuretanu. Kategoria ochrony: CE II, zgodne z normami: EN388 (poziom odporności 4 5 4 3) i EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1055_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1055_1.jpg'],
        sizes: [9, 10, 11],
        new: true,
      },
      {
        id: '7357C4D68EF347C083B540BE41D1F579',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'urgent',
        name: '1065 wodoodporne',
        description:
          'Rękawica wykonana z poliestru, cała dłoń powlekana wysokiej jakości miękkim, gładkim lateksem, końcówki palców pokryte spienionym lateksem. Kategoria ochrony: CE I, zgodne z normą: EN 420.',
        img: 'https://www.anitexbhp.pl/images/gloves/1065_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1065_1.jpg'],
        sizes: [9, 10],
        new: true,
      },
      {
        id: 'D64F81874ECF4DF0B021B057D9453A7C',
        category: 'rękawice',
        subcategory: 'powlekane',
        colors: ['blue/grey', 'green/black'],
        producer: 'reis',
        name: 'rtela',
        description:
          'Rękawice ochronne wykonane z poliestru, powlekane latexem. Powleczenie o chropowatej strukturze zapewnia doskonałą chwytność, nie powodując usztywnienia rękawicy. Odporne na ścieranie i rozdarcie, rozciągliwe i wytrzymałe, zakończone ściągaczem. Elastyczne, dzięki czemu świetnie dopasowują się do dłoni, nie kurczą się w kontakcie z wodą oraz zapewniają doskonałą manualność przy ograniczeniu ślizgania się trzymanego przedmiotu. Zgodne z normami EN388 (przykładowe poziomy odporności: 2 1 2 1) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RTELA_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/gloves/RTELA_1.jpg',
          'https://www.anitexbhp.pl/images/gloves/RTELA_2.jpg',
        ],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: 'C36D25FE6E454941B7B66365DB6CFCDF',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'rteni',
        description:
          'Rękawice ochronne wykonane z poliestru, powlekane nitrylem, zakończone ściągaczem. Charakteryzują się zwiększoną odpornością na ścieranie. Wykazują bardzo dużą elastyczność oraz świetnie dopasowują się do dłoni. Zapewniają manualność oraz doskonałe czucie trzymanego przedmiotu. Nitryl zwiększa odporność na smary i oleje. Zgodne z normami EN388 (przykładowe poziomy odporności: 4 1 2 1 X) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RTENI_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RTENI_1.jpg'],
        sizes: [7, 8, 9, 10],
        new: true,
      },
      {
        id: 'EDE864DA22FB49019A19EE89F806A7BC',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'blutrix',
        description:
          'Rękawice ochronne wykonane z poliestru, powlekane nitrylem, zakończone ściągaczem. Odporne na ścieranie, przetarcie czy też przebicie. Zapewniają manualność oraz doskonałe czucie trzymanego przedmiotu. Nitryl zwiększa odporność na smary, oleje, tłuszcze i węglowodory. Zgodne z normami EN388 (przykładowe poziomy odporności: 3 1 3 1 X) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/BLUTRIX_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/BLUTRIX_1.jpg'],
        sizes: [6, 7, 8, 9, 10, 11, 12],
        new: true,
      },
      {
        id: 'F0E2A8C6AADD4E1693519A95EDBAC12C',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'nitrix y',
        description:
          'Rękawice ochronne wykonane z poliestru, powlekane nitrylem, zakończone ściągaczem. Odporne na ścieranie, przetarcie czy też przebicie. Zapewniają manualność oraz doskonałe czucie trzymanego przedmiotu. Nitryl zwiększa odporność na smary, oleje, tłuszcze i węglowodory. Zgodne z normami EN388 (poziomy odporności: 3 1 2 1) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/NITRIX_Y_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/NITRIX_Y_1.jpg'],
        sizes: [6, 7, 8, 9, 10, 11, 12],
        new: true,
      },
      {
        id: 'D9A81009F0C5429A8FEA17B29B569BD0',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'rnyla',
        description:
          'Rękawice ochronne wykonane z nylonu. Powleczenie o chropowatej strukturze zapewnia doskonałą chwytność, nie powodując usztywnienia rękawicy. Odporne na ścieranie i rozdarcie, rozciągliwe i wytrzymałe. Elastyczne, dzięki czemu świetnie dopasowują się do dłoni. Lateks wykazuje odporność na detergenty oraz kwasy. Niepylące. Zapewniają doskonałą manualność przy ograniczeniu ślizgania się trzymanego przedmiotu. Zgodne z normami EN388 (poziomy odporności: 3 1 3 1 X) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RNYLA_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RNYLA_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: '0EE4F9085B8D415FAC97BEA4F46C3395',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'rdr',
        description:
          'Rękawice ochronne wykonane z dzianiny, powlekane bardzo wytrzymałą gumą o porowatej strukturze, dzięki czemu zachowują świetną przyczepność, przy czym nie tracą swojej elastyczności. Wysoka jakość dzianiny (ścieg 10) gwarantuje komfort użytkowania. Trudnościeralne i odporne na zużycie. Odporne na rozdarcie i powstawanie pęknięć. Zgodne z normami EN388 (poziomy odporności: 3 1 4 1) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RDR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RDR_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: '458EFE3E9A664346BB3359450898FD31',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'rnifo-full',
        description:
          'Rękawice ochronne wykonane z nylonu, powlekane na całej dłoni powłoką nitrylu w kolorze czarnym. Część wierzchnia i chwytna powlekane powłoką spienionego nitrylu typu FOAM. Zakończone ściągaczem. Charakteryzują się zwiększoną odpornością na ścieranie i przetarcie. Zapewniają manualność oraz doskonałe czucie trzymanego przedmiotu. Zgodne z normami EN388 (poziomy odporności: 4 1 2 1 X) oraz EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RNIFO_FULL_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RNIFO_FULL_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: 'C48262307AA7494AA686CFC721DEA429',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'recodrag',
        description:
          'Rękawice ochronne wykonane z dzianiny, powlekane gumą o porowatej strukturze, dzięki czemu zachowują świetną przyczepność. Trudnościeralne i odporne na zużycie. Odporne na rozdarcie i powstawanie pęknięć. Zgodne z normami EN388 (poziomy odporności: 2 1 3 1) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RECODRAG_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RECODRAG_1.jpg'],
        sizes: ['XL'],
        new: true,
      },
      {
        id: '6C922349B7694916923C391A7B34F993',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'ribbon',
        description:
          'Rękawice ochronne wykonane z poliestru, powlekane nitrylem, zakończone ściągaczem. Prążkowana struktura materiału oraz powleczenia. Nnajwyższy współczynnik odporności na ścieranie (4). Nitryl zwiększa odporność na smary, oleje, tłuszcze i węglowodory. Zgodne z normami EN388 (poziomy odporności: 4 1 2 1) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RIBBON_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RIBBON_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: 'E37608DAA4444BADAE20ED6A6298F25F',
        category: 'rękawice',
        subcategory: 'powlekane',
        colors: ['orange', 'red', 'blue'],
        producer: 'reis',
        name: 'ru',
        description:
          'Rękawice ochronne powlekane gumą, wykonane z mieszanki bawełniano-poliestrowej, zakończone ściągaczem. Znane jako wampirki. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RU_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/gloves/RU_1.jpg',
          'https://www.anitexbhp.pl/images/gloves/RU_2.jpg',
          'https://www.anitexbhp.pl/images/gloves/RU_3.jpg',
        ],
        sizes: [9, 10],
        new: true,
      },
      {
        id: 'EDC90805A99F4E32B287AFBCBDE37AFC',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'rexg',
        description:
          'Rękawice ochronne powlekane, zakończone ściągaczem. Wykonane z dzianiny, powlekane podwójnie wulkanizowaną gumą w kolorze pomarańczowym. Bardzo odporne na ścieranie, przecieranie, a także rozdarcie. Potocznie zwane extra grip. Zgodne z normami EN388 (poziomy odporności: 3 1 3 2 X) oraz EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/REXG_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/REXG_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: 'AC71DAC4534641B1A9F6B3634ED6FF15',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'rgs',
        description:
          'Rękawice ochronne na wkładzie z drelichu, od strony chwytnej powlekane szorstkowaną gumą w żółtym kolorze, zakończone ściągaczem. Odporne na ścieranie. Zapewniają dobrą chwytność przy przenoszeniu przedmiotów. Potocznie zwane grip. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RGS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RGS_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '98ACBB79A2E14A5AA97DBB64A5562D36',
        category: 'rękawice',
        subcategory: 'powlekane',
        colors: ['blue/grey', 'pink/grey'],
        producer: 'reis',
        name: 'rnypo',
        description:
          'Rękawice ochronne wykonane z nylonu, na części chwytnej powlekane poliuretanem, zakończone ściągaczem. Charakteryzują się zwiększoną odpornością na ścieranie i rozdarcie. Zapewniają manualność oraz doskonałe czucie trzymanego przedmiotu. Wykazują bardzo dużą elastyczność, a tym samym odporność na rozciąganie oraz świetnie dopasowują się do dłoni. Każda para pakowana w osobną torebkę. Zgodne z normami EN388 (przykładowe poziomy odporności: 4 1 3 1) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RNYPO_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/gloves/RNYPO_1.jpg',
          'https://www.anitexbhp.pl/images/gloves/RNYPO_2.jpg',
        ],
        sizes: [6, 7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: 'D1A4CECBABFF4626959973D6D8C3BEAC',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'rhotpink-lf',
        description:
          'Rękawice ochronne wykonane z poliestru, powlekane spienionym lateksem, zakończone ściągaczem. Powleczenie o chropowatej strukturze zapewnia doskonałą chwytność, nie powodując usztywnienia rękawicy. Elastyczne, dzięki czemu świetnie dopasowują się do dłoni. Rozciągliwe i wytrzymałe. Idealne dla kobiet. W pakiecie znajdują się 3 pary. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RHOTPINK_LF_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RHOTPINK_LF_1.jpg'],
        sizes: [6, 7, 8, 9],
        new: true,
      },
      {
        id: '6C630D30A06B44F69C5F0A10B0AFCED2',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'rgarden-ni',
        description:
          'Rękawice ochronne wykonane z poliestru, powlekane nitrylem, zakończone ściągaczem. Elastyczne, dzięki czemu świetnie dopasowują się do dłoni. Zapewniają manualność oraz doskonałe czucie trzymanego przedmiotu. Nitryl zwiększa odporność na smary i oleje. Idealne dla kobiet. W pakiecie znajdują się 3 pary. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RGARDEN_NI_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RGARDEN_NI_1.jpg'],
        sizes: [6, 7, 8, 9],
        new: true,
      },
      {
        id: 'F114EE6A98F9453DAD6BB6467240A91C',
        category: 'rękawice',
        subcategory: 'powlekane',
        producer: 'reis',
        name: 'familiar',
        description:
          'Rękawice ochronne wykonane z poliestru, powlekane spienionym lateksem. Bardzo miękkie powleczenie spienionym lateksem chroni dłoń przed odciskami, podnosi komfort pracy i zapewnia wygodę przez długi czas użytkowania. Porowata struktura oblania zapewnia doskonałą chwytność, nie powodując usztywnienia rękawicy. Lateks wykazuje odporność na detergenty oraz kwasy. Zapewnia doskonałą manualność przy ograniczeniu ślizgania się trzymanego przedmiotu. Zakończone ściągaczem. W opakowaniu znajdują się dwa kolory powleczenia: czarny oraz niebieski. Idealne dla całej rodziny. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/FAMILIAR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/FAMILIAR_1.jpg'],
        sizes: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        new: true,
      },
      {
        id: '900075A4B749479B96E6E899BA459274',
        category: 'rękawice',
        subcategory: 'powlekane',
        type: ['zima'],
        producer: 'reis',
        name: 'nortex ps',
        description:
          'Rękawice ochronne ocieplane, powlekane spienionym lateksem, zakończone ściągaczem. Wykonane z wysokiej jakości pętelkowej przędzy akrylowej, która gwarantuje komfort użytkowania oraz ciepło. Powleczenie zapewnia przyczepność, elastyczność oraz wygodę użytkowania. Każda para pakowana w osobną torebkę. Zgodne z normami EN388 (poziomy odporności: 1 2 2 1), EN511 (poziomy odporności: 0 1 0) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/NORTEX_PS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/NORTEX_PS_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: '60689463639848FEB78232F1E705F5E9',
        category: 'rękawice',
        subcategory: 'powlekane',
        type: ['zima'],
        producer: 'alpa',
        name: '1020 dragon winter',
        description:
          'Rękawice ochronne ocieplane wykonane z dzianiny w kolorze pomarańczowym. Część chwytna rękawicy oblana bardzo wytrzymałą gumą o porowatej strukturze. Rękawica kat. II, spełnia następujące normy: EN 420, EN 388 (3,1,4,2).',
        img: 'https://www.anitexbhp.pl/images/gloves/1020_DRAGON_WINTER_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1020_DRAGON_WINTER_1.jpg'],
        sizes: [9, 10],
        new: true,
      },
    ],
    skorzane: [
      {
        id: '2B2BDB2FBF754105ADA24AA4B23448D5',
        category: 'rękawice',
        subcategory: 'skórzane',
        producer: 'urgent',
        name: '1204',
        description:
          'Rękawice wykonane z licowej skóry koziej w jasnym kolorze. Miękka skóra wysokiej jakości zapewnia doskonały komfort pracy bez utraty odporności. Wykonane w całości ze skóry, wraz z mankietem. Marszczenie w nadgarstku chroni przed dostawaniem się piasku i innych zanieczyszczeń. Znakomicie przylegają do dłoni. Kat I.',
        img: 'https://www.anitexbhp.pl/images/gloves/1204_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1204_1.jpg'],
        sizes: [9, 10],
        new: true,
      },
      {
        id: '4F8D472E7224453DB1897F7CB438000B',
        category: 'rękawice',
        subcategory: 'skórzane',
        producer: 'urgent',
        name: '1205',
        description:
          'Rękawice wykonane z wysokiej jakości skóry koziej. Wierzch rękawicy wykonany z elastycznej i przewiewnej tkaniny w kolorze czewonym. Zakończone sztywnym mankietem. Kat. I.',
        img: 'https://www.anitexbhp.pl/images/gloves/1205_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/1205_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: 'F4CEFF8D7E74418B8B0BC18E1D016B0D',
        category: 'rękawice',
        subcategory: 'skórzane',
        producer: 'urgent',
        name: 'LS 5001',
        description:
          'Rękawice wzmacniane grubą dwoiną wołową w jednym kawałku, z podszewką części chwytnej dłoni, bawełniany mankiet, kciuk i palec wskazujący całoskórzane, końcówki palców pokryte skórą.',
        img: 'https://www.anitexbhp.pl/images/gloves/LS_5001_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/LS_5001_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '68A0E6525B854AC7931EBA80C8601DDA',
        category: 'rękawice',
        subcategory: 'skórzane',
        producer: 'urgent',
        name: 'LS 5004',
        description:
          'Rękawice wzmacniane grubą dwoiną wołową w jednym kawałku, z podszewką części chwytnej dłoni, bawełniany mankiet, kciuk i palec wskazujący całoskórzane, końcówki palców pokryte skórą.',
        img: 'https://www.anitexbhp.pl/images/gloves/LS_5004_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/LS_5004_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '926975AEBE3342E6AE9B08329448E195',
        category: 'rękawice',
        subcategory: 'skórzane',
        producer: 'reis',
        name: 'rlcs+',
        description:
          'Rękawice ochronne w całości wykonane z wysokiej jakości skóry koziej. Skórzany mankiet. Marszczenie w nadgarstku sprawia, iż rękawica świetnie przylega do dłoni i o wiele lepiej chroni przed dostaniem się do jej wnętrza wiatru czy piasku. Miękka skóra wysokiej jakości - doskonały komfort pracy, bez utraty odporności. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RLCS_PLUS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RLCS_PLUS_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: 'EE2E5A92A02B4B71A5F3BF7020D543E5',
        category: 'rękawice',
        subcategory: 'skórzane',
        producer: 'reis',
        name: 'rltoper',
        description:
          'Rękawice ochronne wykonane z wysokiej jakości skóry koziej, licowanej. Wierzch wykonany z elastycznej tkaniny w granatowym kolorze, dzięki czemu idealnie dopasowują się do dłoni. Całodłonicowe - część chwytna rękawicy wykonana z jednego kawałka skóry, dzięki czemu wykazują większą wytrzymałość i odporność na przetarcia. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RLTOPER_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RLTOPER_1.jpg'],
        sizes: [7, 8, 9, 10],
        new: true,
      },
      {
        id: '280616711E8B4E4A9414EFF8EAB845A6',
        category: 'rękawice',
        subcategory: 'skórzane',
        producer: 'reis',
        name: 'rltoper-velcro',
        description:
          'Rękawice ochronne wykonane z wysokiej jakości skóry koziej, licowanej. Całodłonicowe - część chwytna wykonana z jednego kawałka skóry, dzięki czemu wykazują większą wytrzymałość i odporność na przetarcia. Zapięcie na rzep w nadgarstku umożliwia wygodną regulację i lepszą ochronę przed dostaniem się do jej wnętrza wiatru czy piasku. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RLTOPER_VELCRO_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RLTOPER_VELCRO_1.jpg'],
        sizes: [7, 8, 9, 10],
        new: true,
      },
      {
        id: 'A431789144F64383803227BB31747E43',
        category: 'rękawice',
        subcategory: 'skórzane',
        producer: 'alpa',
        name: '9802',
        description:
          'Rękawice ochronne wykonane z licowej skóry koziej. Wierzch wykonany z elastycznej tkaniny w kolorze czerwonym/granatowym. Część chwytna rękawicy wykonana z jednego kawałka skóry (całodłonicowe), dzięki czemu rękawice są bardziej wytrzymałe i odporne na przetarcia. Rękawica kat. II, spełnia następujące normy: EN 420, EN 388 (2,2,4,3).',
        img: 'https://www.anitexbhp.pl/images/gloves/9802_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/9802_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: '088662A58B7845F3B03BAAA52F603E09',
        category: 'rękawice',
        subcategory: 'skórzane',
        producer: 'alpa',
        name: '9818',
        description:
          'Rękawice ochronne wykonane w całości z licowej skóry koziej w kolorze białym. Część chwytna rękawicy wykonana z jednego kawałka skóry (całodłonicowe), dzięki czemu rękawice są bardziej wytrzymałe i odporne na przetarcia. Całoskórzane, czyli w całości wykonane ze skóry, włącznie z mankietem. Marszczenie w nadgarstku sprawia, że rękawica świetnie przylega do dłoni. Rękawica kat. II, spełnia następujące normy: EN 420, EN 388 (2,2,4,3).',
        img: 'https://www.anitexbhp.pl/images/gloves/9818_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/9818_1.jpg'],
        sizes: [9, 10],
        new: true,
      },
      {
        id: 'BD7BCA5ED58745EEAAC6C9B84E11603A',
        category: 'rękawice',
        subcategory: 'skórzane',
        producer: 'alpa',
        name: '9840',
        description:
          'Rękawice ochronne wykonane z licowej skóry koziej. Wierzch wykonany z elastycznej tkaniny w kolorze granatowym. Część chwytna rękawicy wykonana z jednego kawałka skóry (całodłonicowe), dzięki czemu rękawice są bardziej wytrzymałe i odporne na przetarcia. Rękawica kat. II, spełnia następujące normy: EN 420, EN 388 (2,2,4,3).',
        img: 'https://www.anitexbhp.pl/images/gloves/9840_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/9840_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: 'AE247351FCFD4CB48A276FA0AC45F50F',
        category: 'rękawice',
        subcategory: 'skórzane',
        type: ['zima'],
        producer: 'alpa',
        name: 'eco-winter',
        description:
          'Rękawice ochronne ocieplane wykonane z licowej skóry koziej, od wewnątrz ocieplane. Wierzch wykonany z elastycznej tkaniny w kolorze czerwonym/granatowym. Część chwytna rękawicy wykonana z jednego kawałka skóry (całodłonicowe), dzięki czemu rękawice są bardziej wytrzymałe i odporne na przetarcia. Rękawica kat. II, spełnia następujące normy: EN 420, EN 388 (2,2,4,3).',
        img: 'https://www.anitexbhp.pl/images/gloves/ECO_WINTER_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/ECO_WINTER_1.jpg'],
        sizes: [9, 10, 11],
        new: true,
      },
      {
        id: 'C921708162E34D1D82E2C19609106341',
        category: 'rękawice',
        subcategory: 'skórzane',
        type: ['spaw'],
        producer: 'reis',
        name: 'rspbszindianex',
        description:
          'Rękawice spawalnicze w całości wykonane ze skóry bydlęcej dwoinowej. Część chwytna rękawicy wykonana z jednego kawałka skóry, dzięki czemu wykazują większą wytrzymałość i odporność na przetarcia. Długość rękawicy 35 cm. Mankiet skórzany siega do połowy przedramienia, chroniąc w ten sposób przed przemiękaniem oraz zabezpieczając nadgarstek przed urazami. Zgodne z normami EN388 (3 1 3 3 X), EN407 (4 1 3 X 4 X), EN12477 TYP A.',
        img: 'https://www.anitexbhp.pl/images/gloves/RSPBSZINDIANEX_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RSPBSZINDIANEX_1.jpg'],
        sizes: [11],
        new: true,
      },
      {
        id: '9A0344B9689049AA8C21EA6290225515',
        category: 'rękawice',
        subcategory: 'skórzane',
        type: ['spaw'],
        producer: 'reis',
        name: 'rspl+',
        description:
          'Rękawice ochronne w całości wykonane ze skóry bydlęcej, dwoinowej połączonej z licową skórą kozią. Długość rękawicy 35 cm. Całodłonicowe - część chwytna oraz wierzchnia wykonana z jednego kawałka wysokiej jakości skóry z kozy, dzięki czemu wykazują większą wytrzymałość i odporność na przetarcia. Mankiet wykonany ze skóry dwoinowej, sięgający do połowy przedramienia, chroniący w ten sposób przed przemiękaniem oraz zabezpieczając nadgarstek przed urazami. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RSPL_PLUS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RSPL_PLUS_1.jpg'],
        sizes: [11],
        new: true,
      },
      {
        id: '635DE32919B448A4AD70E626171E6A06',
        category: 'rękawice',
        subcategory: 'skórzane',
        type: ['spaw'],
        producer: 'reis',
        name: 'weldoger',
        description:
          'Rękawice spawalnicze wykonane ze skóry bydlęcej, dwoinowej. Długość rękawicy 35 cm. Szyte trudno palnymi i odpornymi na gorąco nićmi z kevlaru. Całodłonicowe - część chwytna wykonana z jednego kawałka skóry, dzięki czemu wykazują większą wytrzymałość i odporność na przetarcia. Skórzany mankiet sięgający do połowy przedramienia, chroniący w ten sposób przed przemiękaniem oraz zabezpieczając nadgarstek przed urazami. Od wewnątrz wypodszewkowane. Zgodne z normami EN388 (poziomy odporności: 4 1 4 4 X), EN407 (poziomy odporności: 4 1 3 X 4 X) i EN12477 TYP A.',
        img: 'https://www.anitexbhp.pl/images/gloves/WELDOGER_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/WELDOGER_1.jpg'],
        sizes: [11],
        new: true,
      },
      {
        id: '62E8C39ED5364A52974DEF305E3F8F2F',
        category: 'rękawice',
        subcategory: 'skórzane',
        type: ['spaw'],
        producer: 'reis',
        name: 'weldoger-p',
        description:
          'Rękawica spawalnicza na prawą rękę, wykonana ze skóry bydlęcej, dwoinowej. Długość rękawicy 35 cm. Szyta trudno palnymi i odpornymi na gorąco nićmi z kevlaru. Całodłonicowa - część chwytna wykonana z jednego kawałka skóry, dzięki czemu wykazuje większą wytrzymałość i odporność na przetarcia. Skórzany mankiet sięgający do połowy przedramienia, chroniący w ten sposób przed przemiękaniem oraz zabezpieczając nadgarstek przed urazami. Od wewnątrz wypodszewkowana. Zgodna z normami EN388 (poziomy odporności: 4 1 4 3 X), EN407 (poziomy odporności: 4 1 3 X 4 X) i EN12477 TYP A.',
        img: 'https://www.anitexbhp.pl/images/gloves/WELDOGER_P_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/WELDOGER_P_1.jpg'],
        sizes: [11],
        new: true,
      },
      {
        id: '69EC87BA1CF044978826DEC058D848AA',
        category: 'rękawice',
        subcategory: 'skórzane',
        type: ['spaw'],
        producer: 'reis',
        name: 'weldoger-l',
        description:
          'Rękawica spawalnicza na lewą rękę, wykonana ze skóry bydlęcej, dwoinowej. Długość rękawicy 35 cm. Szyta trudno palnymi i odpornymi na gorąco nićmi z kevlaru. Całodłonicowa - część chwytna wykonana z jednego kawałka skóry, dzięki czemu wykazuje większą wytrzymałość i odporność na przetarcia. Skórzany mankiet sięgający do połowy przedramienia, chroniący w ten sposób przed przemiękaniem oraz zabezpieczając nadgarstek przed urazami. Od wewnątrz wypodszewkowana. Zgodna z normami EN388 (poziomy odporności: 4 1 4 3 X), EN407 (poziomy odporności: 4 1 3 X 4 X) i EN12477 TYP A.',
        img: 'https://www.anitexbhp.pl/images/gloves/WELDOGER_L_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/WELDOGER_L_1.jpg'],
        sizes: [11],
        new: true,
      },
      {
        id: 'C80F340CE7EB4F0CA5E1415AA22F940A',
        category: 'rękawice',
        subcategory: 'skórzane',
        type: ['spaw'],
        producer: 'urgent',
        name: 'LS 9001',
        description:
          'Rękawica skórzana, spawalnicza z dwoiny wołowej o długości 35 cm z bawełnianą podszewką i krytymi szwami.',
        img: 'https://www.anitexbhp.pl/images/gloves/LS_9001_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/LS_9001_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '1444E2D6AFF5498584FE8D7B91655944',
        category: 'rękawice',
        subcategory: 'skórzane',
        type: ['spaw'],
        producer: 'urgent',
        name: 'LS 1064',
        description:
          'Rękawica skórzana z dwoiny wołowej o długości 35 cm, z bawełnianą podszewką i krytymi szwami.',
        img: 'https://www.anitexbhp.pl/images/gloves/LS_1064_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/LS_1064_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '34F40C925DEB497FBB62C6C69C166037',
        category: 'rękawice',
        subcategory: 'skórzane',
        type: ['spaw'],
        producer: 'alpa',
        name: '9873',
        description:
          'Rękawice ochronne w całości wykonane ze skóry. Skóra licowa kozia połączona z bydlęcą skórą dwoinową. Długość rękawicy 35cm. Całodłonicowe, część chwytna oraz wierzchnia wykonana z jednego kawałka skóry licowej koziej. Mankiet wykonany z dwoinowej skóry sięgający do połowy przedramienia. Rękawica kat. II, spełnia następujące normy: EN 420, EN 388 (2,2,4,3), EN 407: (4,1,2,X,4,X).',
        img: 'https://www.anitexbhp.pl/images/gloves/9873_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/9873_1.jpg'],
        sizes: [10],
        new: true,
      },
    ],
    gumowe: [
      {
        id: '345FE83489304DF6AD709163AB2CA6A5',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'reis',
        name: 'rf',
        description:
          'Rękawice ochronne gumowe flokowane, wykonane z lateksu. Wewnętrzna powierzchnia rękawic pokryta jest flokiem (pyłem bawełnianym), co ułatwia wkładanie i zdejmowanie oraz zapobiega poceniu się rąk w czasie użytkowania. Na części chwytnej chropowata struktura dzięki czemu rękawiczki zyskują lepszą przyczepność. Odporne na rozciąganie. Zgodne z normą EN388 (poziomy odporności: 1 1 0 0 X).',
        img: 'https://www.anitexbhp.pl/images/gloves/RF_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RF_1.jpg'],
        sizes: ['S', 'M', 'L', 'XL'],
        new: true,
      },
      {
        id: '6C35381A4FA94EE9987CA56DDD9ED95A',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'reis',
        name: 'rbi-vex',
        description:
          'Rękawice ochronne wykonane z latexu i kauczuku neoprenowego. Mankiet sięgający do połowy przedramienia o wiele lepiej ochroni rękę. Wyściółka z flokowanej czystej bawełny. Znakomita przyczepność w warunkach mokrych i suchych, dzięki delikatnie chropowatej strukturze na części chwytnej. Zapewniają manualność. Dopuszczone do kontaktu z żywnością. Zgodne z normami EN388 (poziomy odporności: 1 1 1 0 X) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RBI_VEX_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RBI_VEX_1.jpg'],
        sizes: [7, 8, 9, 10],
        new: true,
      },
      {
        id: '1953DB8D61B24323AD57859DFAB2C11A',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'reis',
        name: 'rindustrial-r',
        description:
          'Rękawice ochronne wykonane w całości z naturalnego lateksu z przedłużonym mankietem. Dodatkowo powlekane lateksem o chropowatej strukturze, zarówno po wewnętrznej, jak i zewnętrznej części dłoni. Specjalna szorstka struktura zapewnia lepszą chwytność trzymanego przedmiotu. Zakończone mankietem długości 35 cm lub 60 cm. Odporne na rozciągnie oraz rozdarcie. Dzięki elastyczności świetnie dopasowują się do dłoni, zapewniając manualność. Zgodne z normą EN388 (poziomy odporności: 3 1 2 1), EN374-5 i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RINDUSTRIAL_R_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RINDUSTRIAL_R_1.jpg'],
        sizes: [11],
        new: true,
      },
      {
        id: 'B9224FB79B994BDCA7625C3F758D8E48',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'reis',
        name: 'rpcvs',
        description:
          'Rękawice ochronne wykonane z PCV, zakończone dzianinowym ściągaczem. Oblewane PCV zarówno po stronie wewnętrznej jak i zewnętrznej. Odznaczają się odpornością na ścieranie. Zapewniają manualność oraz zachowują miękkość. Zgodne z normami EN388 (poziomy odporności: 4 1 2 1 X) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RPCVS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RPCVS_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: 'F1A4B2FD9AA5462698F6E4CABE36C076',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'reis',
        name: 'rpcv35',
        description:
          'Rękawice ochronne wykonane z PCV, zakończone powlekanym mankietem. Oblewane PCV zarówno po stronie wewnętrznej jak i zewnętrznej. Długość rękawic: 35cm. Odznaczają się odpornością na ścieranie. Zapewniają manualność oraz zachowują miękkość. Zgodne z normami EN388 (przykładowe poziomy odporności: 4 1 2 1 X) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RPCV35_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RPCV35_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '9A789CBE97324BB592B543CAA2DB1020',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'reis',
        name: 'rpcv60',
        description:
          'Rękawice ochronne wykonane z PCV, zakończone powlekanym mankietem. Oblewane PCV zarówno po stronie wewnętrznej jak i zewnętrznej. Długość rękawic: 60cm. Odznaczają się odpornością na ścieranie. Zapewniają manualność oraz zachowują miękkość. Zgodne z normami EN388 (poziomy odporności: 4 1 2 1 X) i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RPCV60_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RPCV60_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: 'E83526D444F744A8AAEEC67723EC63FF',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'reis',
        name: 'rfishing',
        description:
          'Rękawice ochronne wykonane z latexu, bawełniana wyściółka od wewnątrz. Miękki mankiet sięga do połowy przedramienia, dzięki czemu o wiele lepiej chroni rękę. Szorstka powierzchnia ułatwia chwytanie mokrych, śliskich przedmiotów. Wykazują elastyczność, a tym samym odporność na rozdarcie i przecieranie. Dopuszczone do kontaktu z żywnością. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RFISHING_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RFISHING_1.jpg'],
        sizes: [8, 9, 10],
        new: true,
      },
      {
        id: '161A8569270544A5AB0D1DF50954F689',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'ansell',
        name: 'rasolvex 36-675',
        description:
          'Antystatyczne rękawice ochronne Solvex® 37-675, wykonane z nitrylu. Flokowana bawełniana wyściółka w połączeniu z elastycznością nitrylowej emulsji zapewnia użytkownikowi wyjątkowy komfort. Zaprojektowane tak, aby zapewnić optymalne wyniki w suchych i mokrych środowiskach, w których wymagana jest odporność na chemikalia. Nadają się do wielokrotnego użycia. Zapewniają bezpieczeństwo w pracy z agresywnymi środkami chemicznymi. Dopuszczone do kontaktu z żywnością. Zgodne z normą EN388 (poziomy odporności: 4 1 0 1) i EN374 (JKL), EN1149 i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RASOLVEX_36_675_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RASOLVEX_36_675_1.jpg'],
        sizes: [7, 8, 9, 10, 11],
        new: true,
      },
      {
        id: '8F81AEF69A5B43B69B741B19305C47FB',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'larkis',
        name: 'ludwik',
        description:
          'Rękawice ochronne wykonane z mieszanki na bazie lateksu i kauczuku naturalnego, grubości 0,5 do 0,7 mm, dwuwarstwowe. Część chwytna i grzbietowa rękawic jest szorstkowata, co zapewnia pewny chwyt, natomiast wnętrze rękawic gładkie, pudrowane. Dopuszczone do kontaktu z żywnością. Przeznaczone do prac z kwasami i zasadami. Całkowita długość rękawic: (350+-10)mm dla rozm. 9 i (360+-10)mm dla rozm. 11. Zgodne z normami EN388 (poziomy odporności: 4 1 2 1), EN ISO 374-1, EN374-2, EN374-4, EN ISO 374-5, EN16523-1 i EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/LUDWIK_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/LUDWIK_1.jpg'],
        sizes: [9, 11],
        new: true,
      },
      {
        id: '7C5E62EF2C1548EDB0762AC2545BA018',
        category: 'rękawice',
        subcategory: 'gumowe',
        type: ['zima'],
        producer: 'reis',
        name: 'rpolargjapan',
        description:
          'Rękawice ochronne, termoodporne wykonane z PCV w kolorze pomarańczowym. Szorstka powierzchnia części chwytnej ułatwia chwytanie śliskich przedmiotów. Wyściółka wykonana z wysokiej jakości tkaniny polarowej. Hydrofobową wyściółkę można wywinąć na zewnątrz i bardzo szybko wysuszyć - zaprojektowana tak, by odprowadzać pot od skóry ręki. Wytrzymałe na ścieranie. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RPOLARGJAPAN_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RPOLARGJAPAN_1.jpg'],
        sizes: [11],
        new: true,
      },
      {
        id: 'FE8DA9F7BED14C83837C3F3007E4B808',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'reis',
        name: 'rfolia',
        description:
          'Rękawice ochronne wykonane z wysokiej jakości folii typu HDPE - 0,94-0,97 g/cm³, grubość: 0,01 mm, gęstość: 8,5 g/m². Dopuszczone do kontaktu z żywnością. Pakowane po 100 szt. w opakowaniu. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RFOLIA_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RFOLIA_1.jpg'],
        sizes: ['M', 'L'],
        new: true,
      },
      {
        id: '2C3D049C74E7480498F44B2E1C41ED4A',
        category: 'rękawice',
        subcategory: 'gumowe',
        colors: ['blue', 'white'],
        producer: 'reis',
        name: 'rfol',
        description:
          'Zarękawki foliowe, 100% polietylen, rozmiar 40x20 cm, z dwóch stron zakończone gumkami ściągającymi. Pakowane po 100 szt. w opakowaniu.',
        img: 'https://www.anitexbhp.pl/images/gloves/RFOL_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/gloves/RFOL_1.jpg',
          'https://www.anitexbhp.pl/images/gloves/RFOL_2.jpg',
        ],
        sizes: ['40x20 cm'],
        new: true,
      },
      {
        id: '939DFCE8CB414AF28C79EFFC9A616ECE',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'kruuse',
        name: 'kru-rins',
        description:
          'Rękawice rektalne/inseminacyjne GREEN SOFT, grubość: 0,025 mm, długość: 95 cm. Miękkie i wytrzymałe. Pakowane po 100 szt.',
        img: 'https://www.anitexbhp.pl/images/gloves/KRU_RINS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/KRU_RINS_1.jpg'],
        sizes: ['95 cm'],
        new: true,
      },
      {
        id: '9A6A6016DC9649B283E7E19C1AF50488',
        category: 'rękawice',
        subcategory: 'gumowe',
        producer: 'kruuse',
        name: 'kru-rinso',
        description:
          'Rękawice rektalne/inseminacyjne z ochraniaczem ramienia, grubość: 0,025 mm, długość: 95 cm. Miękkie i wytrzymałe. Pakowane po 50 szt.',
        img: 'https://www.anitexbhp.pl/images/gloves/KRU_RINSO_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/KRU_RINSO_1.jpg'],
        sizes: ['95 cm'],
        new: true,
      },
    ],
    dziane: [
      {
        id: '98CF883BE4AB43959CF96B46CADAEC9D',
        category: 'rękawice',
        subcategory: 'dziane / tkaninowe',
        producer: 'reis',
        name: 'rwk',
        description:
          'Rękawice ochronne wykonane z bawełny. Dobrze przepuszczają powietrze, nie elektryzują się, doskonale dopasowują się do dłoni. Świetnie sprawdzają się jako wkład do innych rękawic. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RWK_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RWK_1.jpg'],
        sizes: [7, 8, 9, 10],
        new: true,
      },
      {
        id: '9C13CDC6F0C54087AB09CF44E5EA4083',
        category: 'rękawice',
        subcategory: 'dziane / tkaninowe',
        producer: 'reis',
        name: 'rwkb',
        description:
          'Rękawice ochronne wykonane z bawełny. Dobrze przepuszczają powietrze, nie elektryzują się, doskonale dopasowują się do dłoni. Świetnie sprawdzają się jako wkład do innych rękawic. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RWKB_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RWKB_1.jpg'],
        sizes: [7, 8, 9, 10],
        new: true,
      },
      {
        id: 'B25818B31609423CA9D017148011C688',
        category: 'rękawice',
        subcategory: 'dziane / tkaninowe',
        producer: 'reis',
        name: 'rwkblux',
        description:
          'Rękawice ochronne wykonane z bawełny, na części wierzchniej trzy szwy, od strony zewnętrznej delikatnie marszczone w nadgarstku. Dobrze przepuszczają powietrze, nie elektryzują się, doskonale dopasowują się do dłoni. Stosowane, gdy wymagana jest odpowiednia prezencja i wygląd dłoni np. do przenoszenia sztandarów. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RWKBLUX_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RWKBLUX_1.jpg'],
        sizes: [4, 5, 6, 7, 8, 9, 10],
        new: true,
      },
      {
        id: 'FFA42ACBBE5C477DB426C3CE9F4D35D2',
        category: 'rękawice',
        subcategory: 'dziane / tkaninowe',
        producer: 'reis',
        name: 'rwks',
        description:
          'Rękawice ochronne wykonane z bawełny, zakończone ściągaczem. Dobrze przepuszczają powietrze, nie elektryzują się, doskonale dopasowują się do dłoni. Świetnie sprawdzają się jako wkład do innych rękawic. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RWKS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RWKS_1.jpg'],
        sizes: [7, 8, 9, 10],
        new: true,
      },
      {
        id: '6056D84F81AA4FAB9CF24D4D2EC496CD',
        category: 'rękawice',
        subcategory: 'dziane / tkaninowe',
        producer: 'reis',
        name: 'rwksb',
        description:
          'Rękawice ochronne wykonane z bawełny, zakończone ściągaczem. Dobrze przepuszczają powietrze, nie elektryzują się, doskonale dopasowują się do dłoni. Świetnie sprawdzają się jako wkład do innych rękawic. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RWKSB_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RWKSB_1.jpg'],
        sizes: [7, 8, 9, 10],
        new: true,
      },
      {
        id: 'D45697549DA540A9A4B3F5D7A0CE7BA7',
        category: 'rękawice',
        subcategory: 'dziane / tkaninowe',
        producer: 'reis',
        name: 'rnylon',
        description:
          'Rękawice ochronne wykonane z nylonu. Rozciągliwe i wytrzymałe, mimo iż są cienkie. Elastyczne, dzięki czemu świetnie dopasowują się do dłoni. Nie kurczą się, co umożliwia pranie rękawic. Zapewniają manualność oraz doskonałe czucie trzymanego przedmiotu. Niepylące. Świetnie sprawdzają się jako wkład do innych rękawic. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RNYLON_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RNYLON_1.jpg'],
        sizes: [7, 8, 9, 10],
        new: true,
      },
      {
        id: '9D942CC0BCAE4359983C72CDED953DCA',
        category: 'rękawice',
        subcategory: 'dziane / tkaninowe',
        producer: 'reis',
        name: 'rdp',
        description: 'Rękawice ochronne drelichowe, 100% bawełna, pikowane. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RDP_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RDP_1.jpg'],
        sizes: [10],
        new: true,
      },
      {
        id: '8B0DA77F85D84D40BABA7F1734187619',
        category: 'rękawice',
        subcategory: 'dziane / tkaninowe',
        producer: 'reis',
        name: 'rflower',
        description:
          'Rękawice ochronne wykonane z nylonu. Rrozciągliwe i wytrzymałe, mimo iż są cienkie. Elastyczne, dzięki czemu świetnie dopasowują się do dłoni. Nie kurczą się, co umożliwia pranie rękawic. W pakiecie znajduje się 6 par o różnych kolorach i wzorach. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RFLOWER_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RFLOWER_1.jpg'],
        sizes: ['S', 'M'],
        new: true,
      },
      {
        id: 'BFA6792773214D528DE24F0D62049AFA',
        category: 'rękawice',
        subcategory: 'dziane / tkaninowe',
        producer: 'reis',
        name: 'rd-moro',
        description:
          'Rękawice ochronne drelichowe, 100% bawełna. Marszczenie w nadgarstku sprawia, iż rękawica przylega do dłoni. Zgodne z normą EN420.',
        img: 'https://www.anitexbhp.pl/images/gloves/RD_MORO_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/gloves/RD_MORO_1.jpg'],
        sizes: [10],
        new: true,
      },
    ],
  },
  'odziez-robocza': {
    spodnie: [
      {
        id: '0F382182F8254B2BB953E60FDEFDDBC4',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        producer: 'urgent',
        name: 'URG-A do pasa',
        description:
          'Materiał: 65% poliester, 35% bawełna, materiał wzmocnień: oxford+PU, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_A_PAS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_A_PAS_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: 'E5DE9487C178455DABFB9890ECA43B1B',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        producer: 'urgent',
        name: 'URG-B do pasa',
        description: 'Materiał: 65% poliester, 35% bawełna, gramatura 315g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_B_PAS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_B_PAS_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '7BF22C0F56864D4CA4A67E4C2E25D256',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        producer: 'urgent',
        name: 'URG-D do pasa',
        description: 'Materiał: 65% poliester, 35% bawełna, gramatura 315g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_D_PAS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_D_PAS_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: 'FD603BA4034C44B79ABA97EBF68DD987',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        producer: 'urgent',
        name: 'URG-E do pasa',
        description: 'Materiał: 80% poliester, 20% bawełna, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_E_PAS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_E_PAS_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '3B05051E7548494FAE4F92F0ECE4BC31',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        producer: 'urgent',
        name: 'URG-Y do pasa',
        description: 'Materiał: 65% poliester, 35% bawełna, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_Y_PAS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_Y_PAS_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '1245AE45E4AE40CBBCD020D5F1A13324',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        producer: 'urgent',
        name: 'URG-712 do pasa',
        description:
          'Materiał RIP-STOP: 93% poliester, 7% spandex, gramatura 260g/m2, kolana wzmocnione materiałem OXFORD 300D, dodatkowe kieszenie na narzędzia monterskie.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_712_PAS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_712_PAS_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '161E806C4D044FC386BB06CFE0854EA3',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        producer: 'reis',
        name: 'foreco-t',
        description:
          'Spodnie ochronne do pasa. Materiał: 80% poliester, 20% bawełna, gramatura: 260 g/m². Zapinane na guzik i zamek błyskawiczny. Ściągacze w bocznych partiach spodni w pasie zwiększają swobodę ruchów oraz umożliwiają lepsze dopasowanie. Dwie kieszenie z tyłu na rzepy oraz dwie boczne, jedna kieszeń na nogawce na rzep na akcesoria oraz jedna boczna podłużna. Na kolanach kieszenie na wkładki nakolannikowe. Końcówka do przypięcia kluczy.',
        img: 'https://www.anitexbhp.pl/images/workwear/FORECO_T_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/FORECO_T_1.jpg'],
        sizes: [46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: 'CC7542F27921421A8C002DA83F29BEFD',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        colors: ['grey/black/orange', 'navy/black/red', 'green/black/grey'],
        producer: 'LEBER&HOLLMAN',
        name: 'lh-fmn-t do pasa',
        description:
          'Spodnie ochronne do pasa FORMEN. Materiał: 65% poliester, 35% bawełna, gramatura: 270 g/m². Dwie kieszenie z tyłu na rzepy oraz dwie boczne podwójne, w tym jedna z kieszonką na telefon, jedna kieszeń na nogawce na rzepy z dodatkowymi kieszonkami na akcesoria oraz jedna boczna podłużna. Końcówka do przypięcia kluczy. Pętelki umożliwiające doczepienie dodatkowych akcesoriów. Gumka w pasie w tylnej partii zwiększa swobodę ruchów oraz umożliwia lepsze dopasowanie. Na kolanach kieszenie na wkładki nakolannikow. Na kieszeniach, kolanach oraz na dole nogawek dodatkowe wzmocnienie, co zwiększa wytrzymałość spodni na przetarcia. Testowane na zawartość szkodliwych dla zdrowia substancji zgodnie ze standardami OEKO-TEX® Standard 100.',
        img: 'https://www.anitexbhp.pl/images/workwear/LH_FMN_T_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_T_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_T_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_T_3.jpg',
        ],
        sizes: [46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '24C8399AFE6841D08C0705EF5CBD0527',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        producer: 'LEBER&HOLLMAN',
        name: 'lh-harver do pasa',
        description:
          'Spodnie ochronne do pasa. Materiał: 65% poliester, 35% bawełna, gramatura: 300 g/m². Technologia High Technology Fabric zapewnia wytrzymałość i odporność na wytarcia, zwiększa również odporność na smary, oleje i brud. Dwie boczne kieszenie, oraz dwie tylne, dodatkowo doszywane dwie kieszenie doskonałe do przechowywania narzędzi. Kieszenie na nogawkach, w tym na telefon komórkowy. Końcówka do przypięcia kluczy. Na kolanach kieszenie na wkładki nakolannikowe.',
        img: 'https://www.anitexbhp.pl/images/workwear/LH_HARVER_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/LH_HARVER_1.jpg'],
        sizes: [25, 27, 48, 50, 52, 54, 56, 58, 60, 62, 102, 110],
        new: true,
      },
      {
        id: 'E6B5CA74C12A4FFA9D6617BD83AD04EB',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        colors: ['grey', 'blue', 'green'],
        producer: 'reis',
        name: 'Multi Master do pasa',
        description:
          'Spodnie ochronne do pasa Multi Master. Materiał: 65% poliester, 35% bawełna, gramatura: 262 g/m². Możliwość regulacji w pasie zapewnia lepsze dopasowanie. Boczne kieszenie.',
        img: 'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_3.jpg',
        ],
        sizes: [46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: 'C37A28640ED64827A58292CF4F0A4C27',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['pas'],
        producer: 'LEBER&HOLLMAN',
        name: 'lh-hunspo mo do pasa',
        description:
          'Spodnie ochronne do pasa w kolorze moro z odpinanymi nogawkami. Materiał: 65% poliester, 35% bawełna, gramatura: 220 g/m². Dwie kieszenie boczne, na obydwu nogawkach zapinane kieszenie.',
        img: 'https://www.anitexbhp.pl/images/workwear/LH_HUNSPO_MO_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/LH_HUNSPO_MO_1.jpg'],
        sizes: [46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '08B219802F494BACA02CA7ABA6D8B91A',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['ogr'],
        producer: 'urgent',
        name: 'URG-A ogrodnicznki',
        description:
          'Materiał: 65% poliester, 35% bawełna, materiał wzmocnień: oxford+PU, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_A_OGR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_A_OGR_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '229815FD7A414B61B3F23221BF9B05A8',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['ogr'],
        producer: 'urgent',
        name: 'URG-B ogrodnicznki',
        description: 'Materiał: 65% poliester, 35% bawełna, gramatura 315g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_B_OGR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_B_OGR_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: 'F62D015074864A96847B0B3DB9661CF9',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['ogr'],
        producer: 'urgent',
        name: 'URG-E ogrodnicznki',
        description: 'Materiał: 80% poliester, 20% bawełna, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_E_OGR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_E_OGR_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '6BC6A64702AE4AF0B20FB0A3ABD8EDA7',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['ogr'],
        producer: 'urgent',
        name: 'URG-S2 ogrodnicznki',
        description: 'Materiał: 98% bawełna, 2% elastan, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_S2_OGR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_S2_OGR_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '20A18458BE5448A4ADD35BE7EDF6B093',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['ogr'],
        producer: 'urgent',
        name: 'URG-Y ogrodnicznki',
        description: 'Materiał: 65% poliester, 35% bawełna, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_Y_OGR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_Y_OGR_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '6DC5F6BBE9154805AE23410A5CDDDE80',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['ogr'],
        producer: 'reis',
        name: 'foreco-b',
        description:
          'Spodnie ochronne ogrodniczki. Materiał: 80% poliester, 20% bawełna, gramatura: 260 g/m². Gumka w pasie w tylnej partii zwiększa swobodę ruchów oraz umożliwia lepsze dopasowanie. Możliwość regulacji długości za pomocą szelek. Liczne kieszenie m.in. dwie na klatce piersiowej zapinane na rzep, dwie po bokach, z tyłu dwie zapinane na rzep, na nogawce na narzędzia oraz kieszeń boczna zapinana na rzep. Na kolanach kieszenie na wkładki nakolannikowe. Końcówka do przypięcia kluczy.',
        img: 'https://www.anitexbhp.pl/images/workwear/FORECO_B_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/FORECO_B_1.jpg'],
        sizes: [46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '97063CF90B3F41AE97EC122C58C4B399',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['ogr'],
        colors: ['grey/black/orange', 'navy/black/red', 'green/black/grey'],
        producer: 'LEBER&HOLLMAN',
        name: 'lh-fmn-b ogrodniczki',
        description:
          'Spodnie ochronne ogrodniczki FORMEN. Materiał: 65% poliester, 35% bawełna, gramatura: 270 g/m². Dwie kieszenie z tyłu na rzepy oraz dwie boczne podwójne, jedna kieszeń na nogawce na rzepy z dodatkowymi kieszonkami na akcesoria, jedna boczna podłużna oraz jedna na klatce piersiowej z kieszonkami na telefon oraz przybory. Końcówka do przypięcia kluczy. Pętelki umożliwiające doczepienie dodatkowych akcesoriów. Możliwość regulacji w pasie oraz długości za pomocą szelek. Guma w tylnej partii zwiększająca swobodę ruchów. Na kolanach kieszenie na wkładki nakolannikowe. Na kieszeniach, kolanach oraz na dole nogawek dodatkowe wzmocnienie co zwiększa wytrzymałość spodni na przetarcia. Testowane na zawartość szkodliwych dla zdrowia substancji zgodnie ze standardami OEKO-TEX® Standard 100.',
        img: 'https://www.anitexbhp.pl/images/workwear/LH_FMN_B_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_B_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_B_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_B_3.jpg',
        ],
        sizes: [46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '8C7D9C688A8A452F95AE5AA3996432FF',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['ogr'],
        colors: ['grey/black', 'blue/black', 'green/black'],
        producer: 'reis',
        name: 'Multi Master ogrodniczki',
        description:
          'Spodnie ochronne ogrodniczki Multi Master. Materiał: 65% poliester, 35% bawełna, gramatura: 262 g/m². Ściągacz w pasie oraz guziki umożliwiają regulację co pozwala na lepsze dopasowanie. Możliwość regulacji długość za pomocą szelek. Dwie boczne kieszenie oraz jedna podłużna na nogawce, dodatkowo trzy kieszenie w górnej partii spodni, w tym jedna na suwak.',
        img: 'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_OGR_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_OGR_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_OGR_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_OGR_3.jpg',
        ],
        sizes: [46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: 'DD779570381C44EA8139C467303E1BCA',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['short'],
        producer: 'urgent',
        name: 'URG-A krótkie',
        description:
          'Materiał: 65% poliester, 35% bawełna, materiał wzmocnień: oxford+PU, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_A_KR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_A_KR_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: 'DF448A8786D146FAA2C37751657A0CC1',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['short'],
        producer: 'urgent',
        name: 'URG-C krótkie',
        description:
          'Materiał: 65% poliester, 35% bawełna, materiał wzmocnień: oxford+PU, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_C_KR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_C_KR_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '1BBDFAC520504CE3B3183E0517EDB3B8',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['short'],
        producer: 'urgent',
        name: 'URG-Y krótkie',
        description:
          'Materiał: 65% poliester, 35% bawełna, materiał wzmocnień: oxford+PU, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_Y_KR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_Y_KR_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '2C2DE23FE6C941FE838DB158D10CA594',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['short'],
        colors: ['grey/black/orange', 'navy/black/red', 'green/black/grey'],
        producer: 'LEBER&HOLLMAN',
        name: 'lh-fmn-ts krótkie',
        description:
          'Spodnie ochronne do pasa FORMEN z krótkimi nogawkami. Materiał: 65% poliester, 35% bawełna, gramatura: 270 g/m². Dwie kieszenie z tyłu na rzepy oraz dwie boczne podwójne, w tym jedna z kieszonką na telefon, jedna kieszeń na nogawce na rzepy z dodatkowymi kieszonkami na akcesoria oraz jedna boczna podłużna. Końcówka do przypięcia kluczy. Pętelki umożliwiające doczepienie dodatkowych akcesoriów. Gumka w pasie w tylnej partii zwiększa swobodę ruchów oraz umożliwia lepsze dopasowanie. Na kieszeniach dodatkowe wzmocnienie co zwiększa wytrzymałość spodni na przetarcia. Testowane na zawartość szkodliwych dla zdrowia substancji zgodnie ze standardami OEKO-TEX® Standard 100.',
        img: 'https://www.anitexbhp.pl/images/workwear/LH_FMN_TS_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_TS_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_TS_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_TS_3.jpg',
        ],
        sizes: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
        new: true,
      },
      {
        id: 'CC1E06655424430DA3B12F65FCAB6154',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['short'],
        colors: ['grey', 'blue'],
        producer: 'reis',
        name: 'yes-ts krótkie',
        description:
          'Spodnie ochronne do pasa YES z krótkimi nogawkami. Materiał: 65% poliester, 35% bawełna, gramatura: 262 g/m². Zapinane na guziki, dwie boczne kieszenie.',
        img: 'https://www.anitexbhp.pl/images/workwear/YES_TS_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/YES_TS_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/YES_TS_2.jpg',
        ],
        sizes: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
        new: true,
      },
      {
        id: '478C68646CD648B2AB007203B3BAC2E0',
        category: 'odzież robocza',
        subcategory: 'spodnie',
        type: ['short'],
        producer: 'reis',
        name: 'skv-sari mobe krótkie',
        description:
          'SSpodnie ochronne do pasa - krótkie. Materiał: 100% bawełna, gramatura: 180 g/m². Zapinane na guzik oraz zamek błyskawiczny. Dwie kieszenie z tyłu na napy oraz dwie boczne, trzy kieszenie na nogawkach na rzep. Dodatkowe troczki na zakończeniach nogawek.',
        img: 'https://www.anitexbhp.pl/images/workwear/SKV_SARI_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/SKV_SARI_1.jpg'],
        sizes: ['S', 'M', 'L', 'XL'],
        new: true,
      },
    ],
    kurtki: [
      {
        id: '7C5483E1EE4D4C62A0D6C7CA6D3E6AA4',
        category: 'odzież robocza',
        subcategory: 'kutrki',
        producer: 'urgent',
        name: 'URG-A',
        description:
          'Materiał: 65% poliester, 35% bawełna, materiał wzmocnień: oxford+PU, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_A_KURTKA_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_A_KURTKA_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: 'BE16A3DEE064458F83274621B27F9334',
        category: 'odzież robocza',
        subcategory: 'kutrki',
        producer: 'urgent',
        name: 'URG-E',
        description: 'Materiał: 80% poliester, 20% bawełna, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_E_KURTKA_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_E_KURTKA_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '105CECE0FF7B426D8A4D5D7F95B875DC',
        category: 'odzież robocza',
        subcategory: 'kutrki',
        producer: 'urgent',
        name: 'URG-Y',
        description: 'Materiał: 65% poliester, 35% bawełna, gramatura 260g/m2.',
        img: 'https://www.anitexbhp.pl/images/workwear/URG_Y_KURTKA_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/URG_Y_KURTKA_1.jpg'],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
        new: true,
      },
      {
        id: '161406BA0C2D4B49B213D6DADAFF0394',
        category: 'odzież robocza',
        subcategory: 'kutrki',
        producer: 'reis',
        name: 'foreco-j',
        description:
          'Materiał: 80% poliester, 20% bawełna, gramatura: 260 g/m². Zapinana za zamek błyskawiczny z dodatkowym zapięciem na rzepy. Rękawy zakończone ściągaczami. Trzy kieszenie na klatce piersiowej zapinane na rzep, trzy wąskie kieszonki na przybory oraz dwie po bokach. Na ramionach, łokciach i w dolnych partiach kurtki wzmocnienie z czarnego materiału zapewnia zwiększoną wytrzymałość na przetarcia.',
        img: 'https://www.anitexbhp.pl/images/workwear/FORECO_J_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/FORECO_J_1.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
        new: true,
      },
      {
        id: '0E4DE02DDCF24DBFB03F15C1BCA40C58',
        category: 'odzież robocza',
        subcategory: 'kutrki',
        colors: ['grey/black', 'blue/black', 'green/black'],
        producer: 'reis',
        name: 'multi master',
        description:
          'Materiał: 65% poliester, 35% bawełna, gramatura: 262 g/m². Zapinana na guziki. Dwie kieszenie zapinane na rzep, mankiety przy rękawach zapinane na guzik. Na dole bluzy gumka ściągająca, dzięki czemu bluza nie odstaje i lepiej dopasowuje się do użytkownika. Otwory wentylacyjne pod pachami zapobiegają poceniu się, a tym samym zapewniają lepszy komfort użytkowania.',
        img: 'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_KURTKA_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_KURTKA_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_KURTKA_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/MULTI_MASTER_KURTKA_3.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
        new: true,
      },
      {
        id: '3AAC91EF0F064D75B7444F11CD70002E',
        category: 'odzież robocza',
        subcategory: 'kutrki',
        colors: ['grey/black/orange', 'navy/black/orange'],
        producer: 'reis',
        name: 'promaster',
        description:
          'Materiał: 65% poliester, 35% bawełna, gramatura 295-300 g/m². Zapinana za zamek błyskawiczny z dodatkowym zapięciem na rzepy. Rękawy zapinane na rzepy. Dwie kieszenie na klatce piersiowej zapinane na rzep oraz dwie po bokach, dodatkowa kieszeń na telefon komórkowy na rękawie bluzy. System wentylacji pod pachami. Na dole bluzy gumka ściągająca, dzięki czemu bluza nie odstaje i zabezpiecza przed wiatrem.',
        img: 'https://www.anitexbhp.pl/images/workwear/PROMASTER_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/PROMASTER_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/PROMASTER_2.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
        new: true,
      },
      {
        id: 'CFD1140816BA418992ACDF087EAACCF5',
        category: 'odzież robocza',
        subcategory: 'kutrki',
        colors: ['grey/black/orange', 'navy/black/orange', 'green/black/grey'],
        producer: 'LEBER&HOLLMAN',
        name: 'lh-fmn-j',
        description:
          'Materiał: 65% poliester, 35% bawełna, gramatura: 270 g/m². Zapinana na suwak z dodatkowym zapięciem na rzepy. Trzy kieszenie na klatce piersiowej, w tym jedna na rzep, jedna na suwak i jedna na telefon, dwie wąskie kieszonki na przybory oraz dwie dolne na ramionach, łokciach i w dolnych partiach bluzy. Wzmocnienie z czarnego materiału zapewnia zwiększoną wytrzymałość na przetarcia. Przedłużany tył, system wentylacji pod pachami i na plecach. Testowana na zawartość szkodliwych dla zdrowia substancji zgodnie ze standardami OEKO-TEX® Standard 100.',
        img: 'https://www.anitexbhp.pl/images/workwear/LH_FMN_J_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_J_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_J_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/LH_FMN_J_3.jpg',
        ],
        sizes: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
        new: true,
      },
      {
        id: '0D32AFB3A9874195AAACB0CC828EF18C',
        category: 'odzież robocza',
        subcategory: 'kutrki',
        type: ['zima'],
        producer: 'reis',
        name: 'foreco-win-j',
        description:
          'Kurtka ochronna ocieplana. Powłoka 80% poliester, 20% bawełna o gramaturze 270 g/m², podszewka 100% poliester typu tafta 210T o gramaturze 60 g/m², wyściółka 100% poliester o gramaturze 280 g/m². Zapinana na zamek błyskawiczny z dodatkowym zapięciem na rzepy. Dwie kieszenie dolne na rzepy oraz dwie na klatce piersiowej, w tym jedna na suwak, dwie kieszenie wewnętrzne, w tym jedna na suwak, druga na rzep, dodatkowa kieszeń na rękawie. Końcówka do przypięcia kluczy. Dół kurtki oraz rękawy zakończone ściągaczem. Na ramionach i łokciach wzmocnienie z czarnego materiału zapewnia zwiększoną wytrzymałość na przetarcia.',
        img: 'https://www.anitexbhp.pl/images/workwear/FORECO_WIN_J_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/FORECO_WIN_J_1.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
        new: true,
      },
    ],
    bezrekawniki: [
      {
        id: 'CD42B15D1CAD466FB9F6C558A79DDA62',
        category: 'odzież robocza',
        subcategory: 'bezrękawniki',
        colors: ['black/blue', 'black/green', 'black/orange'],
        producer: 'urgent',
        name: 'BZR-308 na polarze',
        description: 'Materiał zewnętrzny: 100% poliester, wewnętrzna strona ocieplona polarem.',
        img: 'https://urgent.pl/uploads/localhost/files/5c1aae409a1fe_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5c1aae409a1fe/filename/bezrekawnik_308_blue_1200.jpg',
          'https://urgent.pl/file/show/file/5c1a3c8fdef2f/filename/bezrekawnik_308_green_1200.jpg',
          'https://urgent.pl/file/show/file/5c1aae671323c/filename/bezrekawnik_308_orange_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: 'BAB0FBCD7923445C9E00F46A78B83952',
        category: 'odzież robocza',
        subcategory: 'bezrękawniki',
        colors: ['blue', 'black'],
        producer: 'urgent',
        name: 'M-2028 na polarze',
        description: 'Materiał zewnętrzny: 100% poliester, wewnętrzna strona ocieplona polarem.',
        img: 'https://urgent.pl/uploads/localhost/files/5c17979206e0b_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5c17979206e0b/filename/M2028_black_1200.jpg',
          'https://urgent.pl/file/show/file/5c125a72a150d/filename/M2028_navy_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '0C1AFFBB3CF348EF8F06F47F8E694CE2',
        category: 'odzież robocza',
        subcategory: 'bezrękawniki',
        producer: 'urgent',
        name: 'GL-7216',
        description:
          'Materiał zewnętrzny: 300D oxford z warstwą PU, podszewka: 100% poliester, ocieplina: 100% poliester.',
        img: 'https://urgent.pl/uploads/localhost/files/5d760656a472d_md.file',
        photos: ['https://urgent.pl/file/show/file/5d760656a472d/filename/GL7216_1200.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '60599BF220D34E90AAF4EA9B1ED20963',
        category: 'odzież robocza',
        subcategory: 'bezrękawniki',
        producer: 'reis',
        name: 'kormoran',
        description:
          'Bezrękawnik ocieplany z dużą ilością kieszeni. Powłoka 100% poliester o gramaturze 100 g/m², wyściółka 100% poliester o gramaturze 220 g/m², podszewka 100% poliester o gramaturze 60 g/m². Zasuwany z dodatkowym zapięciem na napy. Przedłużany tył. Liczne kieszenie m.in. dwie kieszenie na telefon komórkowy, dwie na suwak oraz dwie na rzep, dodatkowa kieszeń po wewnętrznej stronie. W rękawach od wewnątrz specjalny materiał ze ściągaczem zabezpieczający przed dostawaniem się wiatru.',
        img: 'https://web.rawpol.com/photo/files/6944/0025366944.jpg',
        photos: ['https://web.rawpol.com/photo/files/6944/0025366944.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '258C0EC6C5074119AB93984DC672EE79',
        category: 'odzież robocza',
        subcategory: 'bezrękawniki',
        producer: 'reis',
        name: 'kormoran 2',
        description:
          'Bezrękawnik ocieplany z dużą ilością kieszeni. Powłoka 100% poliester o gramaturze 100 g/m², wyściółka 100% poliester o gramaturze 220 g/m², podszewka 100% poliester o gramaturze 60 g/m². Zasuwany z dodatkowym zapięciem na napy. Przedłużany tył. Liczne kieszenie m.in. dwie kieszenie na telefon komórkowy, dwie na suwak oraz dwie na rzep, dodatkowa kieszeń po wewnętrznej stronie. W rękawach od wewnątrz specjalny materiał ze ściągaczem zabezpieczający przed dostawaniem się wiatru.',
        img: 'https://web.rawpol.com/photo/files/3680/0019533680.jpg',
        photos: ['https://web.rawpol.com/photo/files/3680/0019533680.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '08CC1492AF5F496098DE9F1E45881D5C',
        category: 'odzież robocza',
        subcategory: 'bezrękawniki',
        producer: 'reis',
        name: 'barrakuda 2',
        description:
          'Bezrękawnik ocieplany, pikowany. Powłoka 65% poliester, 35% bawełna o gramaturze 150 g/m², wyściółka 100% poliester o gramaturze 160-180 g/m², podszewka 100% poliester o gramaturze 60 g/m². Zapinany na suwak. Dwie kieszenie na klatce piersiowej, w tym jedna zapinana na rzep oraz jedna zapinana na suwak, dwie kieszenie po bokach w dolnej części. Pikowanie zabezpiecza przed przesuwaniem się ociepliny. Ściągacz na dole bezrękawnika.',
        img: 'https://web.rawpol.com/photo/files/4944/0025244944.jpg',
        photos: ['https://web.rawpol.com/photo/files/4944/0025244944.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: 'B9D595E097584C1DA0F8FCF9C76C8706',
        category: 'odzież robocza',
        subcategory: 'bezrękawniki',
        producer: 'reis',
        name: 'delfin',
        description:
          'Bezrękawnik ocieplany z dużą ilością kieszeni oraz sztruksowymi wstawkami na ramionach. Powłoka 100% poliester o gramaturze 170 g/m², wyściółka 100% poliester o gramaturze 160 g/m², podszewka z nylonu typu tafta o gramaturze 53 g/m². Zapinany na suwak z dodatkowym zapięciem na rzep. Ośmiokieszeniowy, w tym cztery kieszenie na suwak oraz cztery zapinane na rzep.',
        img: 'https://web.rawpol.com/photo/files/4720/0019334720.jpg',
        photos: ['https://web.rawpol.com/photo/files/4720/0019334720.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '5B3DF9CF26F3428AB61A0DE8F36CD3C9',
        category: 'odzież robocza',
        subcategory: 'bezrękawniki',
        producer: 'reis',
        name: 'foker',
        description:
          'Bezrękawnik zapewniający ochronę przed chłodem, dzięki podwójnemu systemowi ocieplenia. Powłoka 100% poliester micro-moss, wyściółka 100% poliester o gramaturze 300 g/m², podszewka 100% polar o gramaturze 260 g/m². Zapinany na suwak. Po zewnętrznej stronie dwie kieszenie zapinane na napy, dodatkowa kieszeń od wewnętrznej strony. Pikowanie w poprzeczne pasy, zabezpieczające przed przesuwaniem się wyściółki. Od wewnątrz ocieplony polarem.',
        img: 'https://web.rawpol.com/photo/files/4720/0019334720.jpg',
        photos: ['https://web.rawpol.com/photo/files/4720/0019334720.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
    ],
    ostrzegawcza: [
      {
        id: 'C98E81850D6A46F2A5101491FDF311A1',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['spodnie'],
        producer: 'urgent',
        name: 'URG-714',
        description:
          'Materiał: 80% poliester, 20% bawełna, czarne wstawki, wzmocnienia z materiału OXFORD 300D, wszyta taśma odblaskowa, gramatura 280g',
        img: 'https://urgent.pl/uploads/localhost/files/5f23d73285cb7_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5f23d73285cb7/filename/714_1200.jpg',
          'https://urgent.pl/file/show/file/5f23d7322a106/filename/714_2_1200.jpg',
        ],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
      },
      {
        id: '79E4F39B8AEC4B28A1EE948D9A18A234',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['spodnie'],
        producer: 'urgent',
        name: 'URG-715',
        description:
          'Materiał: 80% poliester, 20% bawełna, czarne wstawki, wzmocnienia z materiału OXFORD 300D, wszyta taśma odblaskowa, gramatura 280g',
        img: 'https://urgent.pl/uploads/localhost/files/5faa57e080d42_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5faa57e080d42/filename/715_1_1200.jpg',
          'https://urgent.pl/file/show/file/5faa58052e061/filename/715_3_1200.jpg',
        ],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
      },
      {
        id: 'D367087B2312450AA6FE3BDAEC7436B5',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['spodnie'],
        producer: 'urgent',
        name: 'HSV ogrodniczki orange',
        description:
          '100% poliester 300D, oxford powleczony PU, wodoodporność 200MM, ocieplina 100% poliester 160g, wyściółka 100% poliester.',
        img: 'https://urgent.pl/uploads/localhost/files/5c25c7d054415_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5c25c7d054415/filename/ogrodniczki_HV_orange_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: 'CC8822DF744D4B56AE11413A9909A166',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['spodnie'],
        producer: 'urgent',
        name: 'HSV ogrodniczki yellow',
        description:
          '100% poliester 300D, oxford powleczony PU, wodoodporność 200MM, ocieplina 100% poliester 160g, wyściółka 100% poliester.',
        img: 'https://urgent.pl/uploads/localhost/files/5c25c869b8f72_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5c25c869b8f72/filename/ogrodniczki_HV_yellow_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '826D4600796D46099BE093708B9A2EDF',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['kurtka'],
        producer: 'urgent',
        name: 'URG-914',
        description:
          'Materiał: 80% poliester, 20% bawełna,  pomarańczowe wstawki z materiału o intensywnej widzialności, wszyta taśma odblaskowa, gramatura 280g',
        img: 'https://urgent.pl/uploads/localhost/files/5f23dca3eb326_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5f23dca3eb326/filename/914_1200.jpg',
          'https://urgent.pl/file/show/file/5f23dca3b64eb/filename/914_1_1200.jpg',
        ],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
      },
      {
        id: '4E59DC17AE5E40C4A690BC5AC871AA35',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['kurtka'],
        producer: 'urgent',
        name: 'URG-915',
        description:
          'Materiał: 80% poliester, 20% bawełna,  żółte wstawki z materiału o intensywnej widzialności, wszyta taśma odblaskowa, gramatura 280g',
        img: 'https://urgent.pl/uploads/localhost/files/5faa632a6a794_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5faa632a6a794/filename/915_1_1200.jpg',
          'https://urgent.pl/file/show/file/5faa633be9d94/filename/915_2_1200.jpg',
        ],
        sizes: [44, 46, 48, 50, 52, 54, 56, 58, 60, 62],
      },
      {
        id: 'B9FD5852A3484000BA7F54AF12E98BEE',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['kurtka'],
        producer: 'urgent',
        name: 'URG-DL orange',
        description:
          '100% poliester 300D, oxford powleczony PU, wodoodporność 200MM, ocieplina 100% poliester 160g, wyściółka 100% poliester.',
        img: 'https://urgent.pl/uploads/localhost/files/5f58d9754ae29_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5f58d9754ae29/filename/kurtka_HV_orange_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '74777C0ADC2C4E6B8F41B6C1F9A9233C',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['kurtka'],
        producer: 'urgent',
        name: 'URG-DL yellow',
        description:
          '100% poliester 300D, oxford powleczony PU, wodoodporność 200MM, ocieplina 100% poliester 160g, wyściółka 100% poliester.',
        img: 'https://urgent.pl/uploads/localhost/files/5f56015a6de31_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5f56015a6de31/filename/URG_HV_DL_YELLOW_1_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '7ED103721FA540C2BA6485C524BF02C0',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['kurtka', 'soft'],
        producer: 'urgent',
        name: 'Softshell orange',
        description:
          'Kurtka z materiału SOFTSHELL o gramaturze 310g/m2, 94% poliester, 6% spandex o gramaturze 150g/m2, od wewnątrz podszewka z polaru o gramaturze 160g/m2, tkanina oddychająca (1000MVP) oraz wodoodporna (8000MM).',
        img: 'https://urgent.pl/uploads/localhost/files/5efedccd5f457_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5efedccd5f457/filename/URG_SFHV_EU_orange_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '622D72AF1AD540878B803E4DCE11F78A',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['kurtka'],
        producer: 'urgent',
        name: 'Softshell yellow',
        description:
          'Kurtka z materiału SOFTSHELL o gramaturze 310g/m2, 94% poliester, 6% spandex o gramaturze 150g/m2, od wewnątrz podszewka z polaru o gramaturze 160g/m2, tkanina oddychająca (1000MVP) oraz wodoodporna (8000MM).',
        img: 'https://urgent.pl/uploads/localhost/files/5efedcff6abe9_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5efedcff6abe9/filename/URG_SFHV_EU_yellow_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '44CE275CBB9746CB84DD91FB96B4BEBE',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['kurtka'],
        producer: 'urgent',
        name: 'HSV kurtka krótka (bomber)',
        description:
          '100% poliester 300D, oxford powleczony PU, wodoodporność 200MM, ocieplina 100% poliester 160g, wyściółka 100% poliester. Kategoria II, EN ISO 20471:2013 + A1:2016, EN343:2003+A1:2007 CLASS-3, 1 (odporność na przenikanie wody, opór pary wodnej).',
        img: 'https://urgent.pl/uploads/localhost/files/5ed0c4fa20bd5_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5ed0c4fa20bd5/filename/URG_HV_BO_ORANGE_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '6D56A91ECEEE439BB39D6DFA2205B6C1',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['kurtka'],
        producer: 'urgent',
        name: 'HSV kurtka krótka (bomber)',
        description:
          '100% poliester 300D, oxford powleczony PU, wodoodporność 200MM, ocieplina 100% poliester 160g, wyściółka 100% poliester. Kategoria II, EN ISO 20471:2013 + A1:2016, EN343:2003+A1:2007 CLASS-3, 1 (odporność na przenikanie wody, opór pary wodnej).',
        img: 'https://urgent.pl/uploads/localhost/files/5ed0c5258f6c9_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5ed0c5258f6c9/filename/URG_HV_BO_YELLOW_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: 'E69B1D56AB2B4CAC8107158207138A81',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['kurtka'],
        producer: 'urgent',
        name: 'HSV kurtka 3w1',
        description:
          '100% poliester 300D, oxford powleczony PU, wodoodporność 200MM, ocieplina 100% poliester 160g, wyściółka 100% poliester, odpinane rękawy, wypinana podpinka polarowa, odpinany kołnierz polarowy.',
        img: 'https://urgent.pl/uploads/localhost/files/5ed0c3db419af_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5ed0c3db419af/filename/URG_3w1_orange_1_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: 'A25AA8BAA2844B1E865882016F133EBF',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['kurtka'],
        producer: 'urgent',
        name: 'HSV kurtka 3w1',
        description:
          '100% poliester 300D, oxford powleczony PU, wodoodporność 200MM, ocieplina 100% poliester 160g, wyściółka 100% poliester, odpinane rękawy, wypinana podpinka polarowa, odpinany kołnierz polarowy.',
        img: 'https://urgent.pl/uploads/localhost/files/5ed0c475be6e1_md.file',
        photos: [
          'https://urgent.pl/file/show/file/5ed0c475be6e1/filename/URG_3w1_yellow_1_1200.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '2B2BC563CA204860A9AB4F4CB3B86F83',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['t-shirt'],
        producer: 'urgent',
        name: 't-shirt orange',
        description: 'Materiał: 100% poliester, gramatura: 160g/m2.',
        img: 'https://urgent.pl/uploads/localhost/files/5c25ef7ec603e_md.file',
        photos: ['https://urgent.pl/file/show/file/5c25ef7ec603e/filename/T_shirt_pomar_1200.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '9D84343B56F34031A23EF430D55C278D',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['t-shirt'],
        producer: 'urgent',
        name: 't-shirt yellow',
        description: 'Materiał: 100% poliester, gramatura: 160g/m2.',
        img: 'https://urgent.pl/uploads/localhost/files/5c25ef989c340_md.file',
        photos: ['https://urgent.pl/file/show/file/5c25ef989c340/filename/T_shirt_1200.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '71B897506F5F431C9F23DE253E37BF2C',
        category: 'odzież robocza',
        subcategory: 'ostrzegawcza',
        type: ['bluza'],
        colors: ['yellow', 'yellow/black', 'yellow/blue', 'orange', 'orange/black', 'orange/blue'],
        producer: 'reis',
        name: 'polstrip',
        description:
          'Bluza polarowa z pasami odblaskowymi, 100% poliester, gramatura 290 g/m². Pasy odblaskowe, po dwa na rękawach i na bluzie zwiększające widoczność. Zapinana na suwak. Kołnierz w formie stójki. Dwie kieszenie zasuwane na zakryty polarem suwak. Dodatkowa zasuwana kieszeń na klatce piersiowej. Rękawy wykończone elastyczną lamówką.',
        img: 'https://web.rawpol.com/photo/files/2448/0022982448.jpg',
        photos: [
          'https://web.rawpol.com/photo/files/2448/0022982448.jpg',
          'https://web.rawpol.com/photo/files/672/0022970672.jpg',
          'https://web.rawpol.com/photo/files/2256/0022942256.jpg',
          'https://web.rawpol.com/photo/files/4528/0022964528.jpg',
          'https://web.rawpol.com/photo/files/8880/0022968880.jpg',
          'https://web.rawpol.com/photo/files/7872/0022957872.jpg',
        ],
        sizes: ['M', 'L', 'XL', '2XL', '3XL'],
      },
    ],
    bluzy: [
      {
        id: '0268E1093361499DAC782C5DA5BE90A3',
        category: 'odzież robocza',
        subcategory: 'bluzy',
        colors: ['darkGrey', 'black', 'navy', 'blue', 'green', 'red'],
        producer: 'jhk',
        name: 'polar flra 300',
        description:
          'Bluza z polaru zapinana na zamek, dwie kieszenie. Materiał: 100% poliester, gramatura: 300 gr. Mikro polar z wykończeniem antypilingowym. Stopery.',
        img: 'https://www.anitexbhp.pl/images/workwear/FLRA_300_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/FLRA_300_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_300_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_300_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_300_4.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_300_5.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_300_6.jpg',
        ],
        sizes: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        new: true,
      },
      {
        id: '94BABB9501D146E5930D795625D699B2',
        category: 'odzież robocza',
        subcategory: 'bluzy',
        colors: ['darkGrey', 'black', 'navy', 'blue', 'red'],
        producer: 'jhk',
        name: 'polar flra 330 worker',
        description:
          'Bluza z polaru. Zapinana na zamek. Materiał: 100% poliester, gramatura: 330 g. Mikro polar z wykończeniem antypilingowym. Kieszenie boczne oraz na prawej piersi zapinane na zamek. Na ramionach i rękawach wzmocnienia nieprzemakalną tkaniną o dużej odporności na przetarcia. Stopery.',
        img: 'https://www.anitexbhp.pl/images/workwear/FLRA_330_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/FLRA_330_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_330_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_330_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_330_4.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_330_5.jpg',
        ],
        sizes: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
        new: true,
      },
      {
        id: '22A71659CCB24083BC0C1A2D81AF52C6',
        category: 'odzież robocza',
        subcategory: 'bluzy',
        colors: ['darkGrey', 'black', 'navy', 'blue', 'red'],
        producer: 'jhk',
        name: 'polar flra 340 premium',
        description:
          'Bluza z polaru. Zapinana na zamek. Materiał: 100% poliester, gramatura: 330 g. Mikro polar z wykończeniem antypilingowym. Kieszenie boczne oraz na prawej piersi zapinane na zamek. Na ramionach i rękawach wzmocnienia nieprzemakalną tkaniną o dużej odporności na przetarcia. Stopery.',
        img: 'https://www.anitexbhp.pl/images/workwear/FLRA_340_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/FLRA_340_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_340_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_340_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_340_4.jpg',
          'https://www.anitexbhp.pl/images/workwear/FLRA_340_5.jpg',
        ],
        sizes: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        new: true,
      },
      {
        id: '75772EB0A9E548EE8BF0AA5F018DF8AC',
        category: 'odzież robocza',
        subcategory: 'bluzy',
        colors: ['black/grey', 'black/red', 'black/green', 'black/blue'],
        producer: 'reis',
        name: 'polar-doble',
        description:
          'Bluza polarowa, 100% poliester, gramatura 290 g/m². Zapinana na suwak, trzy zasuwane kieszenie po zewnętrznej stronie: dwie po bokach i jedna na górze. Rękawy wykończone elastyczną lamówką.',
        img: 'https://www.anitexbhp.pl/images/workwear/POLAR_DOBLE_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/POLAR_DOBLE_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLAR_DOBLE_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLAR_DOBLE_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLAR_DOBLE_4.jpg',
        ],
        sizes: ['M', 'L', 'XL', '2XL', '3XL'],
        new: true,
      },
      {
        id: '945BC4F7F1E04E2A808863C11715C70B',
        category: 'odzież robocza',
        subcategory: 'bluzy',
        colors: ['red/black', 'grey/black', 'blue/navy', 'red/grey'],
        producer: 'reis',
        name: 'polar-twin',
        description:
          'Bluza polarowa POLAR-TWIN, 100% poliester, gramatura 290 g/m². Zapinana na suwak, trzy kieszenie po zewnętrznej stronie: dwie po bokach i jedna na górze zasuwana. Po wewnętrznej stronie dwie dodatkowe kieszenie. Rękawy wykończone elastyczną lamówką.',
        img: 'https://www.anitexbhp.pl/images/workwear/POLAR_TWIN_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/POLAR_TWIN_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLAR_TWIN_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLAR_TWIN_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLAR_TWIN_4.jpg',
        ],
        sizes: ['M', 'L', 'XL', '2XL', '3XL'],
        new: true,
      },
      {
        id: '6752680A40CE4517BE381A9666AB95E0',
        category: 'odzież robocza',
        subcategory: 'bluzy',
        colors: ['grey/black', 'blue/black', 'green/black', 'navy/black'],
        producer: 'reis',
        name: 'colorado',
        description:
          'Bluza ocieplana z polaru z dodatkowymi elementami tkaniny. Powłoka 100% poliester o gramaturze 430 g/m², podszewka 100% poliester o gramaturze 180 g/m². Polar także od wewnętrznej strony zapewnia ciepło i komfort użytkowania. Zapinana na suwak z dodatkowymi zapięciami na rzepy. Po zewnętrznej stronie trzy kieszenie oraz kieszeń wzdłuż dolnej partii pleców, dodatkowa kieszeń na telefon po prawej stronie. Specjalna powłoka typu „windblock” jest wyjątkowo odporna na wiatr. Dół bluzy ściągany na gumkę, ściągacz w rękawach. Delikatne elementy odblaskowe.',
        img: 'https://www.anitexbhp.pl/images/workwear/COLORADO_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/COLORADO_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/COLORADO_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/COLORADO_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/COLORADO_4.jpg',
        ],
        sizes: ['M', 'L', 'XL', '2XL', '3XL'],
        new: true,
      },
      {
        id: '47C6E6830AFD40B3B87F05342B7554F4',
        category: 'odzież robocza',
        subcategory: 'bluzy',
        colors: ['darkGrey', 'black', 'navy', 'blue'],
        producer: 'reis',
        name: 'polmenks',
        description:
          'Bluza polarowa, 100% poliester, gramatura 160 g/m². Zapinana na suwak w górnej części bluzy. Kołnierz w formie stójki.',
        img: 'https://www.anitexbhp.pl/images/workwear/POLMENKS_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/POLMENKS_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLMENKS_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLMENKS_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLMENKS_4.jpg',
        ],
        sizes: ['M', 'L', 'XL', '2XL', '3XL'],
        new: true,
      },
      {
        id: 'A731AA3F7F4B4140A1BFA833EDF52521',
        category: 'odzież robocza',
        subcategory: 'bluzy',
        colors: ['yellow', 'yellow/black', 'yellow/blue', 'orange', 'orange/black', 'orange/blue'],
        producer: 'reis',
        name: 'polstrip',
        description:
          'Bluza polarowa z pasami odblaskowymi, 100% poliester, gramatura 290 g/m². Pasy odblaskowe, po dwa na rękawach i na bluzie zwiększające widoczność. Zapinana na suwak. Kołnierz w formie stójki. Dwie kieszenie zasuwane na zakryty polarem suwak. Dodatkowa zasuwana kieszeń na klatce piersiowej. Rękawy wykończone elastyczną lamówką.',
        img: 'https://www.anitexbhp.pl/images/workwear/POLSTRIP_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/POLSTRIP_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLSTRIP_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLSTRIP_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLSTRIP_4.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLSTRIP_5.jpg',
          'https://www.anitexbhp.pl/images/workwear/POLSTRIP_6.jpg',
        ],
        sizes: ['M', 'L', 'XL', '2XL', '3XL'],
        new: true,
      },
    ],
    koszulki: [
      {
        id: '0D873FB857264A1E99FD592686730520',
        category: 'odzież robocza',
        subcategory: 'koszulki',
        colors: ['black', 'navy', 'blue', 'green', 'moro', 'grey'],
        producer: 'jhk',
        name: 't-shirt 190',
        description:
          'T-Shirt JHK męski z krótkim rękawem. Skład: 100% bawełna, gramatura: 190g. Brak bocznych szwów. Podwójne szwy na ramionach. Wzmocniony lycrą ściągacz. Taśma wzmacniająca. Bawełna stabilizowana. Kolor GREY MELANGE - 85% bawełna/ 15% wiskoza.',
        img: 'https://www.anitexbhp.pl/images/workwear/TSRA_190_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/TSRA_190_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/TSRA_190_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/TSRA_190_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/TSRA_190_4.jpg',
          'https://www.anitexbhp.pl/images/workwear/TSRA_190_5.jpg',
          'https://www.anitexbhp.pl/images/workwear/TSRA_190_6.jpg',
        ],
        sizes: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        new: true,
      },
      {
        id: 'A0EB46B0C78949E283B6FF06507F182C',
        category: 'odzież robocza',
        subcategory: 'koszulki',
        producer: 'jhk',
        name: 't-shirt 150 moro',
        description:
          'T-Shirt JHK męski z krótkim rękawem. Skład: 100% bawełna, gramatura: 155 - 160g. Brak bocznych szwów. Podwójne szwy na ramionach. Wzmocniony lycrą ściągacz. Taśma wzmacniająca. Bawełna stabilizowana. Kolor GREY MELANGE - 85% bawełna/ 15% wiskoza.',
        img: 'https://www.anitexbhp.pl/images/workwear/TSRA_150_MORO_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/TSRA_150_MORO_1.jpg'],
        sizes: ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        new: true,
      },
      {
        id: '5FFB734C52A64F219C68DFA1B76B1932',
        category: 'odzież robocza',
        subcategory: 'koszulki',
        producer: 'urgent',
        name: 't-shirt orange',
        description: 'Materiał: 100% poliester, gramatura: 160g.',
        img: 'https://www.anitexbhp.pl/images/workwear/T_SHIRT_ORANGE_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/T_SHIRT_ORANGE_1.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
        new: true,
      },
      {
        id: '4723AD63003640C5A12D2B4EB6C915F0',
        category: 'odzież robocza',
        subcategory: 'koszulki',
        producer: 'urgent',
        name: 't-shirt yellow',
        description: 'Materiał: 100% poliester, gramatura: 160g.',
        img: 'https://www.anitexbhp.pl/images/workwear/T_SHIRT_YELLOW_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/T_SHIRT_YELLOW_1.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
        new: true,
      },
      {
        id: 'BFC82CE6D8D04D898CD478E830A9279A',
        category: 'odzież robocza',
        subcategory: 'koszulki',
        colors: ['black', 'navy', 'blue', 'grey', 'red'],
        producer: 'jhk',
        name: 't-shirt 170 ls',
        description:
          'T-Shirt JHK męski z długim rękawem. Skład: 100% bawełna, gramatura: 170g. Brak bocznych szwów. Podwójne szwy na ramionach i spodzie koszulki. Wzmocniony lycrą ściągacz. Taśma wzmacniająca. Rękawy zakończone ściągaczami.',
        img: 'https://www.anitexbhp.pl/images/workwear/TSRA_170_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/TSRA_170_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/TSRA_170_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/TSRA_170_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/TSRA_170_4.jpg',
          'https://www.anitexbhp.pl/images/workwear/TSRA_170_5.jpg',
        ],
        sizes: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
        new: true,
      },
    ],
    przeciwdeszczowa: [
      {
        id: 'D5DD12D1B42C4ED69E7182E3A1C9D56D',
        category: 'odzież robocza',
        subcategory: 'przeciwdeszczowa',
        type: ['kpl'],
        colors: ['navy', 'green', 'moro/darkBrown'],
        producer: 'reis',
        name: 'kpl',
        description:
          'Komplet przeciwdeszczowy wykonany z poliester/PCV. Kurtka: zapinana na suwak, kaptur ściągany na troczki, schowany w kołnierzu. Dodatkowe zapięcie w rękawach, dzięki czemu można je zwęzić. Na dole kurtki troczki ściągające. Spodnie do pasa: wykończone w pasie rozciągliwą gumką, dwie boczne kieszenie, dodatkowe zapięcie u dołu spodni, dzięki czemu można zwęzić dół nogawki.',
        img: 'https://web.rawpol.com/photo/files/672/0024440672.jpg',
        photos: [
          'https://web.rawpol.com/photo/files/672/0024440672.jpg',
          'https://web.rawpol.com/photo/files/8880/0024438880.jpg',
          'https://web.rawpol.com/photo/files/1952/0024441952.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: 'C933A3E787D947F18B374DE19267059F',
        category: 'odzież robocza',
        subcategory: 'przeciwdeszczowa',
        type: ['kpl'],
        colors: ['navy', 'black', 'orange'],
        producer: 'reis',
        name: 'kplpu',
        description:
          'Wysokiej jakości komplet przeciwdeszczowy z elastycznego materiału o wysokiej wytrzymałości. Wykonany z PU/PCV, grubość: 0.50 mm, wyściółka wykonana z poliestru. Kurtka: zapięcie na suwak z dodatkową zakładką na napy zasłaniającą szczeliny suwaka przed dostaniem się deszczu i wiatru. Kaptur ściągany na troczki schowany w kołnierzu. Dwie duże kieszenie w dolnej część kurtki z zakładkami. Rękawy od wewnątrz zakończone ściągaczem. Ściągacz na dole kurtki. System wentylacji na plecach oraz pod pachami.',
        img: 'https://web.rawpol.com/photo/files/4624/0019074624.jpg',
        photos: [
          'https://web.rawpol.com/photo/files/4624/0019074624.jpg',
          'https://web.rawpol.com/photo/files/3600/0019073600.jpg',
          'https://web.rawpol.com/photo/files/2064/0019072064.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '0B1EC46536E14F938280107893F0478C',
        category: 'odzież robocza',
        subcategory: 'przeciwdeszczowa',
        type: ['kpl'],
        colors: ['orange', 'yellow'],
        producer: 'reis',
        name: 'kplpufluo',
        description:
          'Wysokiej jakości komplet przeciwdeszczowy z elastycznego materiału o wysokiej wytrzymałości. Wykonany z PU/PCV, grubość: 0.50 mm, wyściółka wykonana z poliestru. Kurtka: zapięcie na suwak z dodatkową zakładką na napy szczelnie zasłaniającą szczeliny suwaka przed dostaniem się deszczu i wiatru. Kaptur ściągany na troczki. Dwie duże kieszenie w dolej część kurtki z zakładkami. Od wewnątrz rękawy zakończone ściągaczem. Ściągacz na dole kurtki. System wentylacji na plecach oraz pod pachami. Spodnie: możliwość regulacji w długości szelkami. Trzy kieszenie. Możliwość zwężenia dołu nogawek za pomocą nap. Pasy odblaskowe na dole nogawek oraz w pasie.',
        img: 'https://web.rawpol.com/photo/files/9456/0024959456.jpg',
        photos: [
          'https://web.rawpol.com/photo/files/9456/0024959456.jpg',
          'https://web.rawpol.com/photo/files/5856/0024965856.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '90F4D6B052424234A9C5782DEACD3097',
        category: 'odzież robocza',
        subcategory: 'przeciwdeszczowa',
        type: ['spodnie'],
        colors: ['navy', 'green'],
        producer: 'reis',
        name: 'spd',
        description:
          'Spodnie ogrodniczki przeciwdeszczowe. Materiał: 65% PVC, 35% poliester. Możliwość regulacji w długości szelkami, które w tylnej partii mają gumkę. Możliwość regulacji w szerokości, dzięki napom znajdującym się w pasie z tyłu spodni. Dodatkowe zapięcie u dołu spodni, dzięki czemu można zwęzić dół nogawek.',
        img: 'https://web.rawpol.com/photo/files/7056/0005577056.jpg',
        photos: [
          'https://web.rawpol.com/photo/files/7056/0005577056.jpg',
          'https://web.rawpol.com/photo/files/7568/0005577568.jpg',
        ],
        sizes: ['L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: 'C9DADEB6B08C4ED69FCEE384CE2FE811',
        category: 'odzież robocza',
        subcategory: 'przeciwdeszczowa',
        type: ['kurtka'],
        colors: ['navy', 'green', 'moro/darkBrown'],
        producer: 'reis',
        name: 'kpnp',
        description:
          'Kurtka przeciwdeszczowa z kapturem, wykonana z poliester/PCV, zapinana na suwak oraz dodatkowe napy, z możliwością ściągnięcia jej na dole troczkami. Kaptur zasuwany w kołnierzu, ściągany troczkami. Dwie kieszenie zamaskowane zakładkami, rękawy zapinane na napy, umożliwiające regulację ich szerokości. Szwy dodatkowo klejone od wewnątrz taśmami, zabezpieczającymi przed dostaniem się wody.',
        img: 'https://web.rawpol.com/photo/files/3328/0005573328.jpg',
        photos: [
          'https://web.rawpol.com/photo/files/3328/0005573328.jpg',
          'https://web.rawpol.com/photo/files/4352/0005574352.jpg',
          'https://web.rawpol.com/photo/files/3648/0005573648.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '7406C4F745E04F2ABA7C9C85CA4E165E',
        category: 'odzież robocza',
        subcategory: 'przeciwdeszczowa',
        type: ['poncho'],
        colors: ['navy', 'green'],
        producer: 'reis',
        name: 'poncho',
        description:
          'Peleryna przeciwdeszczowa typu Poncho. Materiał: 100% PCV. Brzegi zastępujące rękawy zapinane na napy, dzięki czemu chronią przed podwiewaniem. Kaptur połączony z peleryną.',
        img: 'https://web.rawpol.com/photo/files/8064/0007128064.jpg',
        photos: [
          'https://web.rawpol.com/photo/files/8064/0007128064.jpg',
          'https://web.rawpol.com/photo/files/8256/0007128256.jpg',
        ],
        sizes: ['uni'],
      },
      {
        id: '2339D10F72C54151B936C58EFF1F1BCE',
        category: 'odzież robocza',
        subcategory: 'przeciwdeszczowa',
        type: ['coat'],
        colors: ['navy', 'green'],
        producer: 'reis',
        name: 'ppdpu',
        description:
          'Wysokiej jakości płaszcz przeciwdeszczowy z elastycznego materiału o wysokiej wytrzymałości, wykonany z PU/PCV, grubość: 0.50 mm, zapięcie na suwak z dodatkową zakładką na napy. Dwie duże kieszenie z zakładkami na napy. Kaptur ściągany na troczki. Ściągacze wewnątrz rękawów. System wentylacji na plecach oraz pod pachami. Poliuretanowa, wodoodporna powłoka zapewnia bardzo dobrą ochronę przed deszczem.',
        img: 'https://web.rawpol.com/photo/files/1792/0005571792.jpg',
        photos: [
          'https://web.rawpol.com/photo/files/1792/0005571792.jpg',
          'https://web.rawpol.com/photo/files/2112/0005572112.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: 'F65B70DE42B14720B30DBCF932FCFE45',
        category: 'odzież robocza',
        subcategory: 'przeciwdeszczowa',
        type: ['coat'],
        colors: ['navy', 'green'],
        producer: 'reis',
        name: 'ppnp',
        description:
          'Płaszcz przeciwdeszczowy z kapturem wykonany z poliester/PCV, zapinany na suwak oraz dodatkowe napy. Kaptur zasuwany w kołnierzu, ściągany troczkami. Dwie kieszenie zamaskowane zakładkami. Rękawy zapinane na napy, umożliwiające regulację ich szerokości. Szwy dodatkowo klejone od wewnątrz taśmami, zabezpieczającymi przed dostaniem się wody. System wentylacji na plecach.',
        img: 'https://web.rawpol.com/photo/files/9808/0005569808.jpg',
        photos: [
          'https://web.rawpol.com/photo/files/9808/0005569808.jpg',
          'https://web.rawpol.com/photo/files/1216/0005571216.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
      },
      {
        id: '6F2B8B125B2B48D49222C9720B395FB0',
        category: 'odzież robocza',
        subcategory: 'przeciwdeszczowa',
        type: ['coat'],
        colors: ['blue'],
        producer: 'reis',
        name: 'ppf',
        description:
          'Płaszcz przeciwdeszczowy z kapturem. Materiał: 100% polietylen. Rękaw o długości 81 cm, zakończony ściągaczem. Zakładany przez głowę.',
        img: 'https://web.rawpol.com/photo/files/8944/0014208944.jpg',
        photos: ['https://web.rawpol.com/photo/files/8944/0014208944.jpg'],
        sizes: ['uni'],
      },
    ],
    koszule: [
      {
        id: 'FDB08B6E57B74D3C9475F386BFF04D2C',
        category: 'odzież robocza',
        subcategory: 'koszule',
        colors: ['navy/blue', 'navy/blue/yellow', 'navy/red', 'grey/navy/orange'],
        producer: 'reis',
        name: 'kf',
        description:
          'Koszula flanelowa w kratę. Materiał: 100% bawełna, gramatura: 120 g/m², zapinana na guziki.',
        img: 'https://www.anitexbhp.pl/images/workwear/KF_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/workwear/KF_1.jpg',
          'https://www.anitexbhp.pl/images/workwear/KF_2.jpg',
          'https://www.anitexbhp.pl/images/workwear/KF_3.jpg',
          'https://www.anitexbhp.pl/images/workwear/KF_4.jpg',
        ],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL', 'XL', '5XL', '6XL'],
        new: true,
      },
      {
        id: '3892A91FE5E7431F9CF523F889073352',
        category: 'odzież robocza',
        subcategory: 'koszule',
        producer: 'reis',
        name: 'kflux',
        description:
          'Jesienno - zimowa koszula z wysokiej jakości flaneli. Materiał: 100% przędza akrylowa, gramatura: 205 g/m², zapinana na guziki, dwie kieszonki.',
        img: 'https://www.anitexbhp.pl/images/workwear/KFLUX_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/KFLUX_1.jpg'],
        sizes: ['M', 'L', 'XL', 'XXL', 'XXXL'],
        new: true,
      },
      {
        id: '1DFA1482ED1F47EFBD6DBFEA100471CB',
        category: 'odzież robocza',
        subcategory: 'koszule',
        type: ['lady'],
        producer: 'reis',
        name: 'kfl',
        description:
          'Koszula flanelowa w kratę, damska. Materiał: 100% bawełna, gramatura: 120 g/m². Zapinana na guziki.',
        img: 'https://www.anitexbhp.pl/images/workwear/KFL_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/workwear/KFL_1.jpg'],
        sizes: ['S', 'M', 'L', 'XL', 'XXL'],
        new: true,
      },
    ],
  },
  'artykuly-ochronne': {
    'ochrona-glowy': [
      {
        id: '89A9DE1CCB724A63A08374DFAF117AD3',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        colors: ['yellow', 'white', 'red', 'blue'],
        type: ['kask'],
        producer: 'urgent',
        name: 'ANB-3 ABS',
        description:
          'Wykonany z tworzywa ABS, otwory wentylacyjne w górnej części, otwory na bokach do zamocowania dodatkowych akcesoriów, 4-punktowe mocowanie paska podbródkowego, 8-punktowe mocowanie więźby do kasku, regulacja na pokrętło, pasek podbródkowy w komplecie, CE kat. II, EN 397.',
        img: 'https://www.anitexbhp.pl/images/protective/ANB_3_ABS_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/protective/ANB_3_ABS_1.jpg',
          'https://www.anitexbhp.pl/images/protective/ANB_3_ABS_2.jpg',
          'https://www.anitexbhp.pl/images/protective/ANB_3_ABS_3.jpg',
          'https://www.anitexbhp.pl/images/protective/ANB_3_ABS_4.jpg',
        ],
        new: true,
      },
      {
        id: '2ACBFAD156794F8A99844C07D4E614CE',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        colors: ['yellow', 'orange', 'white', 'red', 'blue', 'green'],
        type: ['kask'],
        producer: 'reis',
        name: 'kas',
        description:
          'Hełm ochronny wykonany z tworzywa ABS. Regulacja w obwodzie pasa głównego w zakresie 52-62 cm, dająca możliwość dopasowania hełmu do głowy. Skorupa wyposażona w daszek. 6-punktowa więźba plastikowa z tekstylnym napotnikiem. Swobodny przepływ powietrza dzięki wentylacji umieszczonej na górze hełmu. Możliwość dopięcia do hełmu paska podbródkowego UNIVER-STRAP, KAS-STRAP. Zapewnia ochronę przed spadającymi obiektami zgodnie z normą EN397.',
        img: 'https://www.anitexbhp.pl/images/protective/KAS_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/protective/KAS_1.jpg',
          'https://www.anitexbhp.pl/images/protective/KAS_2.jpg',
          'https://www.anitexbhp.pl/images/protective/KAS_3.jpg',
          'https://www.anitexbhp.pl/images/protective/KAS_4.jpg',
          'https://www.anitexbhp.pl/images/protective/KAS_5.jpg',
          'https://www.anitexbhp.pl/images/protective/KAS_6.jpg',
        ],
        new: true,
      },
      {
        id: '3C5FD1F64DC640FA96C19BB5AC49C0B1',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        colors: ['yellow', 'orange', 'white', 'red', 'blue', 'green'],
        type: ['kask'],
        producer: 'reis',
        name: 'kaspe',
        description:
          'Hełm wykonany z tworzywa HDPE. 6-punktowa więźba plastikowa z tekstylnym napotnikiem, dopasowuje się do kształtu głowy użytkownika. Zapewnia ochronę przed spadającymi obiektami zgodnie z normą EN397. Chroni przed porażeniem prądem 440V AC.',
        img: 'https://www.anitexbhp.pl/images/protective/KASPE_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/protective/KASPE_1.jpg',
          'https://www.anitexbhp.pl/images/protective/KASPE_2.jpg',
          'https://www.anitexbhp.pl/images/protective/KASPE_3.jpg',
          'https://www.anitexbhp.pl/images/protective/KASPE_4.jpg',
          'https://www.anitexbhp.pl/images/protective/KASPE_5.jpg',
          'https://www.anitexbhp.pl/images/protective/KASPE_6.jpg',
        ],
        new: true,
      },
      {
        id: '7082301363204AFD857C2AA6E66B9B39',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        colors: ['yellow', 'orange', 'white', 'red', 'blue', 'green'],
        type: ['kask'],
        producer: 'reis',
        name: 'kaspe-rock',
        description:
          'Hełm wykonany z tworzywa HDPE. Regulacja w obwodzie pasa głównego w zakresie 53-63 cm, dająca możliwość dopasowania hełmu do głowy. Skorupa wyposażona w daszek. 4-punktowa więźba plastikowa z tekstylnym napotnikiem. Przeznaczony do stosowania z akcesoriami: KAS-STRAP, UNIVER-STRAP. Zapewnia ochronę przed spadającymi obiektami zgodnie z normą EN397.',
        img: 'https://www.anitexbhp.pl/images/protective/KASPE_ROCK_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/protective/KASPE_ROCK_1.jpg',
          'https://www.anitexbhp.pl/images/protective/KASPE_ROCK_2.jpg',
          'https://www.anitexbhp.pl/images/protective/KASPE_ROCK_3.jpg',
          'https://www.anitexbhp.pl/images/protective/KASPE_ROCK_4.jpg',
          'https://www.anitexbhp.pl/images/protective/KASPE_ROCK_5.jpg',
          'https://www.anitexbhp.pl/images/protective/KASPE_ROCK_6.jpg',
        ],
        new: true,
      },
      {
        id: 'CAE276A773FB49D1A6B4DC692577C6B7',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        colors: ['yellow', 'orange', 'white', 'red', 'blue', 'green'],
        type: ['kask'],
        producer: '3m',
        name: '3m-kas-solaris',
        description:
          'Hełm ochronny Peltor™ G3000 Solaris™ z krótkim daszkiem, wykonany z tworzywa ABS o podwyższonej odporności na działanie promieniowania. Swobodny przepływ powietrza dzięki wentylacji umieszczonej na górze hełmu. Wąski daszek zapewnia pole widzenia. Odporny na działanie niskiej temperatury (-30°C) i kontakt z odpryskami stopionego metalu (MM). 4-punktowa więźba tekstylna. Kompatybilny z paskiem 3M-STRAP-GH4 oraz osłoną 3M-OT-5F, 3M-SHADE-GR3C. Spełnia wymagania normy EN397.',
        img: 'https://www.anitexbhp.pl/images/protective/3M_KAS_SOLARIS_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/protective/3M_KAS_SOLARIS_1.jpg',
          'https://www.anitexbhp.pl/images/protective/3M_KAS_SOLARIS_2.jpg',
          'https://www.anitexbhp.pl/images/protective/3M_KAS_SOLARIS_3.jpg',
          'https://www.anitexbhp.pl/images/protective/3M_KAS_SOLARIS_4.jpg',
          'https://www.anitexbhp.pl/images/protective/3M_KAS_SOLARIS_5.jpg',
          'https://www.anitexbhp.pl/images/protective/3M_KAS_SOLARIS_6.jpg',
        ],
        new: true,
      },
      {
        id: '562FE0B413CC489585C032A1941668A4',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        colors: ['navy', 'black', 'orange', 'green', 'grey'],
        type: ['czakas'],
        producer: 'reis',
        name: 'bumpcap',
        description:
          'Przemysłowy hełm lekki. Materiał czapki 100% bawełna. Skorupa z tworzywa ABS. System amortyzacji: zewnętrzna i wewnętrzna podkładka z tworzywa EVA amortyzuje podczas uderzenia. Wygodna regulacja w obwodzie dająca możliwość dopasowania wielkości czapki do obwodu głowy. Po bokach są dwa otwory zapewniające lepszą przewiewność. Spełnia wymagania normy EN812.',
        img: 'https://www.anitexbhp.pl/images/protective/BUMPCAP_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/protective/BUMPCAP_1.jpg',
          'https://www.anitexbhp.pl/images/protective/BUMPCAP_2.jpg',
          'https://www.anitexbhp.pl/images/protective/BUMPCAP_3.jpg',
          'https://www.anitexbhp.pl/images/protective/BUMPCAP_4.jpg',
          'https://www.anitexbhp.pl/images/protective/BUMPCAP_5.jpg',
        ],
        new: true,
      },
      {
        id: 'DED33F503BA346F2AECEFA6ED1138BFE',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        type: ['czakas'],
        producer: 'reis',
        name: 'bumpcapfluo',
        description:
          'Przemysłowy hełm lekki. Materiał czapki 65% poliester, 35% bawełna. Skorupa z tworzywa ABS. Odblaskowe elementy zapewniają lepszą widoczność. Wstawki z materiału siatkowego zapewniają doskonałą wentylację. Wygodna regulacja w obwodzie dająca możliwość dopasowania wielkości czapki do obwodu głowy. Możliwość wyjęcia wkładki ochronnej, co umożliwia wypranie czapki zewnętrznej. Spełnia wymagania normy EN812.',
        img: 'https://www.anitexbhp.pl/images/protective/BUMPCAPFLUO_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/BUMPCAPFLUO_1.jpg'],
        new: true,
      },
      {
        id: '60C18264D8404BE38CEC8E48C43FD89A',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        colors: ['black/grey', 'black/orange'],
        type: ['czakas'],
        producer: 'reis',
        name: 'bumpcapmove',
        description:
          'Przemysłowy hełm lekki. Materiał czapki 100% bawełna. Skorupa z tworzywa ABS. Odblaskowe elementy zapewniają lepszą widoczność. System amortyzacji: zewnętrzna i wewnętrzna podkładka z tworzywa EVA amortyzuje podczas uderzenia. Wygodna regulacja w obwodzie dająca możliwość dopasowania wielkości czapki do obwodu głowy. Po bokach są dwa otwory zapewniające lepszą przewiewność. Spełnia wymagania normy EN812.',
        img: 'https://www.anitexbhp.pl/images/protective/BUMPCAPMOVE_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/protective/BUMPCAPMOVE_1.jpg',
          'https://www.anitexbhp.pl/images/protective/BUMPCAPMOVE_2.jpg',
        ],
        new: true,
      },
      {
        id: 'F275E766F72C44A98ED9523726BEC96E',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        type: ['akc'],
        producer: 'reis',
        name: 'kas-strap',
        description:
          'Pasek podbródkowy, 2 punktowy. Długość paska: 43 cm, szerokość: 18 mm. Zwiększa stabilność, utrzymując hełm na miejscu. Składa się z paska, zaczepów oraz podkładki pod brodę. Pasuje do hełmu KAS.',
        img: 'https://www.anitexbhp.pl/images/protective/KAS_STRAP_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/KAS_STRAP_1.jpg'],
        new: true,
      },
      {
        id: '4FA092E7A15543BE9F5FDF52364727E4',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        type: ['akc'],
        producer: 'reis',
        name: 'univer-strap',
        description:
          'Pasek podbródkowy, długość paska: 52 cm, średnica kółka: 3 mm. Składa się z paska, zaczepów oraz podkładki pod brodę.',
        img: 'https://www.anitexbhp.pl/images/protective/UNIVER_STRAP_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/UNIVER_STRAP_1.jpg'],
        new: true,
      },
      {
        id: '30BAEAECBD3B46CCB52D1F6BCC588D8E',
        category: 'artykuły ochronne',
        subcategory: 'ochrona głowy',
        type: ['akc'],
        producer: 'reis',
        name: 'univer-osy',
        description:
          'Ochronniki słuchu w żółtym kolorze dopinane do hełmu. Wygłuszenie: H=28 dB, M=20 dB, L=12 dB, SNR=23 dB. Ochronniki słuchu do stosowania z hełmem. Możliwość połączenie z hełmem UNIVER-KAS oraz ramką do akcesoriów UNIVER-HOLDER. W zestawie UNIVER-ADAPTER. Spełniają wymagania normy EN352-3.',
        img: 'https://www.anitexbhp.pl/images/protective/UNIVER_OSY_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/UNIVER_OSY_1.jpg'],
        new: true,
      },
    ],
    'ochrona-oczu-i-twarzy': [
      {
        id: 'B9A0C70C38FC4988988BCD7836E603C7',
        category: 'artykuły ochronne',
        subcategory: 'ochrona oczu i twarzy',
        type: ['okulary'],
        producer: 'reis',
        name: 'OO-CAMOUFLAGE',
        description:
          'Przeciwodpryskowe okulary ochronne, klasa optyczna 1, filtr chroniący przed nadfioletem 2C-3, filtr chroniący przed olśnieniem słonecznym 5-3.1, przyciemniane szybki z poliwęglanu, ramiona wykonane z nylonu, z możliwością regulacji w długości, chronią przed małymi odpryskami ciał stałych o energii uderzenia do 45 m/s (F), spełniają wymagania norm EN166, EN170, EN172 oraz CE/ANSI Z87.1.',
        img: 'https://www.anitexbhp.pl/images/protective/OO_CAMOUFLAGE_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/OO_CAMOUFLAGE_1.jpg'],
        new: true,
      },
      {
        id: '99DF2AA493EA41BDB5E72CB51300C97A',
        category: 'artykuły ochronne',
        subcategory: 'ochrona oczu i twarzy',
        type: ['okulary'],
        producer: 'reis',
        name: 'OO-GOG-FRAMEB TB',
        description:
          'Przeciwodpryskowe okulary ochronne, klasa optyczna 1, posiadają regulację w długości ramion, na końcu ramion małe otwory, do których można doczepić np. sznurek, dzięki czemu można zawiesić okulary na szyi, zapewniają ochronę przed małymi odpryskami ciał stałych o energii uderzenia do 45 m/s (F), spełniają wymagania normy EN166',
        img: 'https://www.anitexbhp.pl/images/protective/OO_GOG_FRAMEB_TB_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/OO_GOG_FRAMEB_TB_1.jpg'],
        new: true,
      },
      {
        id: 'AFAC8AD296734B65ADB3445C29C57CE1',
        category: 'artykuły ochronne',
        subcategory: 'ochrona oczu i twarzy',
        type: ['okulary'],
        producer: 'reis',
        name: 'GOG-FRAMBER-LIGHT YB UNI',
        description:
          'Przeciwodpryskowe okulary ochronne, klasa optyczna 1, filtr chroniący przed nadfioletem 2C-1.2, ochrona przed uderzeniami - F, szybki wykonane z poliwęglanu, czarna ramka wykonana z nylonu, okulary o żółtym zabarwieniu, poprawiają kontrast, posiadają regulację w długości ramion, na końcu ramion małe otwory, do których można doczepić np. sznurek, dzięki czemu można zawiesić okulary na szyi, zapewniają ochronę przed małymi odpryskami ciał stałych o energii uderzenia do 45 m/s (F), spełniają wymagania normy EN166 oraz EN170',
        img: 'https://www.anitexbhp.pl/images/protective/GOG_FRAMBER_LIGHT_YB_UNI_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/GOG_FRAMBER_LIGHT_YB_UNI_1.jpg'],
        new: true,
      },
      {
        id: 'AF7773837DCB438EB17177EDC30D372F',
        category: 'artykuły ochronne',
        subcategory: 'ochrona oczu i twarzy',
        type: ['okulary'],
        producer: 'reis',
        name: 'GOG-FRAMBER-DARK BB UNI',
        description:
          'Przeciwodpryskowe okulary ochronne, klasa optyczna 1, filtr chroniący przed olśnieniem słonecznym 5-3,1, ochrona przed uderzeniami - F, szybki wykonane z poliwęglanu, czarna ramka wykonana z nylonu, okulary przyciemnione, posiadają regulację w długości ramion, na końcu ramion małe otwory, do których można doczepić np. sznurek, dzięki czemu można zawiesić okulary na szyi, zapewniają ochronę przed małymi odpryskami ciał stałych o energii uderzeni do 45 m/s (F), spełniają wymagania normy EN166 oraz EN172',
        img: 'https://www.anitexbhp.pl/images/protective/GOG_FRAMBER_DARK_BB_UNI_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/GOG_FRAMBER_DARK_BB_UNI_1.jpg'],
        new: true,
      },
      {
        id: '1AF3A472BD994C3EA4F38FD4D8659B64',
        category: 'artykuły ochronne',
        subcategory: 'ochrona oczu i twarzy',
        type: ['okulary'],
        producer: 'reis',
        name: 'GOG-ICE T UNI',
        description:
          'Przeciwodpryskowe okulary ochronne, klasa optyczna 1, wykonane z poliwęglanu, szerokie ramiona stanowią ochronę również z boku, na końcu ramion małe otwory, do których można doczepić np. sznurek, dzięki czemu można zawiesić okulary na szyi, zapewniają ochronę przed małymi odpryskami ciał stałych o energii uderzenia do 45 m/s (F), spełniają wymagania normy EN166.',
        img: 'https://www.anitexbhp.pl/images/protective/GOG_ICE_T_UNI_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/GOG_ICE_T_UNI_1.jpg'],
        new: true,
      },
      {
        id: '6A5BBF6F2D104261959DF670257EB658',
        category: 'artykuły ochronne',
        subcategory: 'ochrona oczu i twarzy',
        type: ['gogle'],
        producer: 'reis',
        name: 'GOG-AIR T UNI',
        description:
          'Gogle przeciwodpryskowe, klasa optyczna 1, zakładane na elastyczną gumkę, dzięki czemu doskonale się dopasowują, na bokach małe wypukłe otwory zapobiegające parowaniu wewnątrz gogli, chronią przed małymi odpryskami ciał stałych o energii uderzenia do 45 m/s (F), spełniają wymagania normy EN166.',
        img: 'https://www.anitexbhp.pl/images/protective/GOG_AIR_T_UNI_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/GOG_AIR_T_UNI_1.jpg'],
        new: true,
      },
      {
        id: '0BB2C86EE36E49AEB38568471656C251',
        category: 'artykuły ochronne',
        subcategory: 'ochrona oczu i twarzy',
        type: ['gogle'],
        producer: 'mcr',
        name: 'MCR-STRYKER-F TS',
        description:
          'Przeciwodpryskowe gogle ochronne w linii STRYKER, klasa optyczna 1, zapewniają ochronę przed małymi odpryskami ciał stałych o energii uderzenia do 45 m/s (F), poliwęglanowe oprawki i szkła z uszczelnieniami z tworzywa TPR, możliwość dopasowania do obwodu głowy poprzez elastyczny pasek z regulacją, liczne otwory wentylacyjne zapewniają dostęp powietrza, szybki pokryte warstwą Anti-Fog, spełniają wymagania normy EN166.',
        img: 'https://www.anitexbhp.pl/images/protective/MCR_STRYKER_F_TS_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/MCR_STRYKER_F_TS_1.jpg'],
        new: true,
      },
    ],
    'ochrona-drog-oddechowych': [
      {
        id: '1741413FEFEF45F483B4FD09CDD9456C',
        category: 'artykuły ochronne',
        subcategory: 'ochrona dróg oddechowych',
        producer: 'reis',
        name: 'MAS-F-FFP1V-ZF',
        description:
          'Półmaska filtrująca z zaworkiem. Typ FFP1: chroni układ oddechowy przed aerozolami stałymi i ciekłymi o ile stężenie fazy rozproszonej tych aerozoli nie przekracza wartości do 4 x NDS. Wentyl wydechowy umieszczony z przodu. Spełnia wymogi testu pyłem dolomitowym. Plastikowy zacisk nosowy i uszczelniacz z pianki poliuretanowej zapewniają przyleganie maski w okolicach nosa. Czaszowy kształt maski zapewnia dopasowanie do twarzy. Zakładana na dwie elastyczne gumki. Półmaska o właściwościach antysmogowych. Zgodna z normą EN149.',
        img: 'https://www.anitexbhp.pl/images/protective/MAS_F_FFP1V_ZF_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/MAS_F_FFP1V_ZF_1.jpg'],
        new: true,
      },
      {
        id: 'B6ED6D0131A54A64A902AFBDC0081926',
        category: 'artykuły ochronne',
        subcategory: 'ochrona dróg oddechowych',
        producer: 'reis',
        name: 'MAS-F-FFP2V',
        description:
          'Półmaska przeciwpyłowa z zaworkiem. Typ FFP2: ochrona przed nietoksycznymi lub średnio toksycznymi aerozolami w stanie płynnym lub stałym (np. mgły olejowe) w stężeniach do 12,5xOEL lub 10xAPF (NDS). Zakładana na elastyczną gumkę. Wentyl wydechowy umiejscowiony z przodu. Metalowa klamra na wysokości nosa na zewnętrznej części półmaski. Piankowa poduszka chroni nos przed odgnieceniem. Okrągły kształt półmaski zapewnia dopasowanie do twarzy. Idealna ochrona przed pyłami i dymem metalicznym, drobnymi cząstkami, mgłą olejową czy ozonem. Półmaska o właściwościach antysmogowych. Zgodna z normą EN149.',
        img: 'https://www.anitexbhp.pl/images/protective/MAS_F_FFP2V_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/MAS_F_FFP2V_1.jpg'],
        new: true,
      },
      {
        id: '3DDEA21844D7444E8F37CBBD5F854BD8',
        category: 'artykuły ochronne',
        subcategory: 'ochrona dróg oddechowych',
        producer: 'reis',
        name: 'MAS-S-FFP2V',
        description:
          'Półmaska przeciwpyłowa z zaworkiem. Typ FFP2: ochrona przed nietoksycznymi lub średnio toksycznymi aerozolami w stanie płynnym lub stałym (np. mgły olejowe) w stężeniach 12,5xOEL lub 10xAPF (NDS). Zakładana na elastyczną gumkę mocowaną na klipsy. Wentyl wydechowy umiejscowiony na boku. Plastikowa klamra na wysokości nosa na zewnętrznej części półmaski. Piankowa poduszka chroni nos przed odgnieceniem. Stożkowy kształt maski dodatkowo profilowany do twarzy umożliwia dopasowanie. Ochrona przed pyłami i dymem metalicznym, drobnymi cząstkami, mgłą olejową czy ozonem. Półmaska o właściwościach antysmogowych. Zgodna z normą EN149.',
        img: 'https://www.anitexbhp.pl/images/protective/MAS_S_FFP2V_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/MAS_S_FFP2V_1.jpg'],
        new: true,
      },
      {
        id: '208267F09FC741DAAFD85CB11ACBAE1C',
        category: 'artykuły ochronne',
        subcategory: 'ochrona dróg oddechowych',
        producer: 'reis',
        name: 'MAS-HALF-FFP2',
        description:
          'Półmaska przeciwpyłowa pięciowarstwowa. Typ FFP2: ochrona przed nietoksycznymi lub średnio toksycznymi aerozolami w stanie płynnym lub stałym w stężeniu nie przekraczającym 10NDS. Zakładana na elastyczne gumki. Metalowa klamra na wysokości nosa. Piankowa poduszka chroni nos przed odgnieceniem. Idealna ochrona przed pyłami i dymem metalicznym, drobnymi cząstkami, mgłą olejową czy ozonem. Półmaska o właściwościach antysmogowych. Zgodna z normą EN149.',
        img: 'https://www.anitexbhp.pl/images/protective/MAS_HALF_FFP2_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/MAS_HALF_FFP2_1.jpg'],
        new: true,
      },
      {
        id: '83507CA7FF3D4DB8B9DE514B54BC1396',
        category: 'artykuły ochronne',
        subcategory: 'ochrona dróg oddechowych',
        colors: ['blue', 'white'],
        producer: 'reis',
        name: 'MAS',
        description:
          'Maseczka higieniczna trójwarstwowa z polipropylenu, grubość: 20 g/m² + 20 g/m² + 23 g/m² (warstwy pierwsza/druga/trzecia). Zakładana na dwie gumki zamocowane na bokach. Metalowa klamra na wysokości nosa. Pakowana po 100 szt. Produkt nie stanowi środka ochrony układu oddechowego ani wyrobu medycznego.',
        img: 'https://www.anitexbhp.pl/images/protective/MAS_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/protective/MAS_1.jpg',
          'https://www.anitexbhp.pl/images/protective/MAS_2.jpg',
        ],
        new: true,
      },
      {
        id: 'CDCC5369A81440B3B561383D21FD59B6',
        category: 'artykuły ochronne',
        subcategory: 'ochrona dróg oddechowych',
        colors: ['black', 'white'],
        producer: 'reis',
        name: 'MAS-SAFER',
        description:
          'Maseczka higieniczna wielokrotnego użytku, trójwarstwowa, 100% bawełna o gramaturze 120 g/m². Zakładana na dwie gumki zamocowane na bokach. Pakowana po 10 szt. Produkt nie stanowi środka ochrony układu oddechowego ani wyrobu medycznego.',
        img: 'https://www.anitexbhp.pl/images/protective/MAS_SAFER_1.jpg',
        photos: [
          'https://www.anitexbhp.pl/images/protective/MAS_SAFER_1.jpg',
          'https://www.anitexbhp.pl/images/protective/MAS_SAFER_2.jpg',
        ],
        new: true,
      },
      {
        id: '50B0546C72B543399B6A6E4FBE326612',
        category: 'artykuły ochronne',
        subcategory: 'ochrona dróg oddechowych',
        producer: '3M',
        name: '3m-mas-f-6000',
        description:
          'Maska pełna wielokrotnego użytku serii 6000. Miękka, wykonana z nieuczulającego elastomeru część twarzowa. Wyjątkowo lekka osłona całotwarzowa (400 g). Wyposażona w system złącza bagnetowego 3M™ pozwalający na podłączanie szerokiego asortymentu podwójnych filtrów. Szyba całotwarzowa z poliwęglanu zapewnia szerokie pole widzenia oraz odporność na zarysowania i uderzenia - B. Nagłowie z 4 taśmami. Opatentowany zawór wydechowy 3M™ Cool Flow kierujący wydychane powietrze w dół zapewnia mniejsze opory wydechu i kumulowanie ciepła i potu, łatwiejsze oddychanie oraz eliminuje wibracje zaworu. Konstrukcja z dwoma elementami oczyszczającymi zapewnia niższe opory oddechowe oraz lepszy rozkład ciężaru. W sprzedaży sama maska. Spełnia wymagania normy EN136.',
        img: 'https://www.anitexbhp.pl/images/protective/3M_MAS_F_6000_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/3M_MAS_F_6000_1.jpg'],
        sizes: ['M', 'L'],
        new: true,
      },
      {
        id: 'E9D5AB90686F455BA825B3AD25EDAE79',
        category: 'artykuły ochronne',
        subcategory: 'ochrona dróg oddechowych',
        producer: '3M',
        name: '3m-mas-6000',
        description:
          'Półmaska oddechowa wielokrotnego użytku serii 6000. Wyposażona w system złącza bagnetowego 3M™ pozwalający na podłączanie szerokiego asortymentu podwójnych filtrów. Konstrukcja z dwoma elementami oczyszczającymi zapewniająca niższe opory oddechowe. Uprząż na głowę zaprojektowana z myślą o kompatybilności z innymi środkami ochrony głowy i oczu 3M™. Zawieszenie kołyskowe dla wygody noszenia. Część twarzowa wykonana z materiału elastomerowego dzięki czemu ma ona wyjątkowo niski ciężar (82 g). W skład produktu wchodzi sama półmaska. Spełnia wymagania normy EN140.',
        img: 'https://www.anitexbhp.pl/images/protective/3M_MAS_6000_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/3M_MAS_6000_1.jpg'],
        sizes: ['S', 'M', 'L'],
        new: true,
      },
    ],
    'ochrona-sluchu': [
      {
        id: '3679848322164498B241D4B773E73BD7',
        category: 'artykuły ochronne',
        subcategory: 'ochrona słuchu',
        type: ['zatyczki'],
        producer: '3M',
        name: '3m-os-1110line',
        description:
          'Wkładki przeciwhałasowe na sznurku. Wygłuszenie: SNR=37 dB. Wykonane z miękkiej hipoalergicznej pianki poliuretanowej, pamiętającej kształt oraz zapewniającej pełny komfort i niewielki nacisk na wnętrze ucha. Gładka, odporna na działanie kurzu powierzchnia zapewnia lepszą higienę, wytrzymałość i wygodę. Małe opakowanie 100 par, każda para pakowana w oddzielną torebeczkę. Spełniają wymagania normy EN352-2.',
        img: 'https://www.anitexbhp.pl/images/protective/3M_OS_1110LINE_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/3M_OS_1110LINE_1.jpg'],
        new: true,
      },
      {
        id: 'E78F705228BD4E469760AC43B71D6F0D',
        category: 'artykuły ochronne',
        subcategory: 'ochrona słuchu',
        type: ['zatyczki'],
        producer: 'reis',
        name: 'osz',
        description:
          'Jednorazowe wkładki przeciwhałasowe do uszu wykonane z pianki poliuretanowej. Wygłuszenie: H=38 dB, M=37 dB L=35 dB, SNR=39 dB. W pudełku znajduje się 200 par, każda zapakowana w oddzielną torebeczkę. Spełniają wymagania normy EN352-2.',
        img: 'https://www.anitexbhp.pl/images/protective/OSZ_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/OSZ_1.jpg'],
        new: true,
      },
      {
        id: 'CDE937CF2B504B6A88FDF832841EDCF6',
        category: 'artykuły ochronne',
        subcategory: 'ochrona słuchu',
        type: ['zatyczki'],
        producer: 'reis',
        name: 'osz-500',
        description:
          'Jednorazowe wkładki przeciwhałasowe do uszu wykonane z pianki poliuretanowej. Wygłuszenie: H=38 dB, M=37 dB L=35 dB, SNR=39 dB. W opakowaniu znajduje się 1000 szt. Spełniają wymagania normy EN352-2.',
        img: 'https://www.anitexbhp.pl/images/protective/OSZ_500_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/OSZ_500_1.jpg'],
        new: true,
      },
      {
        id: 'AC39D7CBD6E345AAB932C2D5421A2C62',
        category: 'artykuły ochronne',
        subcategory: 'ochrona słuchu',
        type: ['zatyczki'],
        producer: 'reis',
        name: 'osz-band blue',
        description:
          'Wkładki przeciwhałasowe na kabłąku. Wygłuszenie: H=18 dB, M=13 dB, L=12 dB, SNR=17 dB. Kabłąk wykonany z polipropylenu, wkładki z silikonu, zaś połączenie wkładek z kabłąkiem z tworzywa ABS. Wielorazowego użytku. Spełniają wymagania normy EN352-2.',
        img: 'https://www.anitexbhp.pl/images/protective/OSZ_BAND_BLUE_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/OSZ_BAND_BLUE_1.jpg'],
        new: true,
      },
      {
        id: 'F67BC9CBF56E40E1ACADFAF291FBFFF9',
        category: 'artykuły ochronne',
        subcategory: 'ochrona słuchu',
        type: ['zatyczki'],
        producer: 'honeywell',
        name: 'bilsom 303l',
        description:
          'Zatyczki do uszu BILSOM 303L. Poziomy tłumienia hałasu: SNR=33Db, H=32Db, M=29dB, L=29Db. Po wsunięciu dostosują się kształtem do kanału usznego bez zbędnego ucisku. Wkładka o kształcie stożka, co ułatwia wsunięcie do kanału usznego oraz zapobiega wypadaniu zatyczek z ucha. Pianka Leight Stripe® zapewnia wysoki komfort podczas noszenia i widoczność dla łatwej kontroli pracowników. Ppatentowana pianka poliuretanowa rozszerza się delikatnie w kanale słuchowym, nie ma skłonności do wycofania się z przewodu słuchowego. Gładka powierzchnia odporna na brud. 1 para w woreczku, w kartonie 200 par. Spełniają wymagania normy EN352-2.',
        img: 'https://www.anitexbhp.pl/images/protective/BILSOM_303L_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/BILSOM_303L_1.jpg'],
        new: true,
      },
      {
        id: '96C91141356040BB91B1A355E9831C4E',
        category: 'artykuły ochronne',
        subcategory: 'ochrona słuchu',
        type: ['nauszniki'],
        producer: 'reis',
        name: 'osb',
        description:
          'Ochronniki słuchu, wygłuszenie: SNR=27dB, H=32dB, M=25dB, L=16dB. Kabłąk wyposażony w poduszkę, dzięki której zapewnia komfort pracy i nie uwiera w głowę. Regulowana długość kabłąka. Spełniają wymagania normy EN352-1.',
        img: 'https://www.anitexbhp.pl/images/protective/OSB_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/OSB_1.jpg'],
        new: true,
      },
      {
        id: '44DFF3125D9149828B80D6345701FB57',
        category: 'artykuły ochronne',
        subcategory: 'ochrona słuchu',
        type: ['nauszniki'],
        producer: 'reis',
        name: 'osy',
        description:
          'Ochronniki słuchu w żółtym kolorze z czarnymi dodatkami. Regulowana długość kabłąka. Spełniają wymagania normy EN352-1.',
        img: 'https://www.anitexbhp.pl/images/protective/OSY_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/OSY_1.jpg'],
        new: true,
      },
      {
        id: '7DBF861B1EC04FA28A71E5A28EC3C9C7',
        category: 'artykuły ochronne',
        subcategory: 'ochrona słuchu',
        type: ['nauszniki'],
        producer: 'reis',
        name: 'os-super',
        description:
          'Ochronniki słuchu. Wygłuszenie: H= 33 dB, M= 26 dB, L= 17 dB, SNR= 29 dB. Szerokie, wygodne poduszki uszczelniające zapewniają optymalne uszczelnienie i jednocześnie niewielki nacisk na skórę. Możliwość złożenia nauszników umożliwia łatwe przechowywanie. Spełniają wymagania normy EN352-1.',
        img: 'https://www.anitexbhp.pl/images/protective/OS_SUPER_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/OS_SUPER_1.jpg'],
        new: true,
      },
      {
        id: 'DBC7F48C45304707AF18B641B8A8A698',
        category: 'artykuły ochronne',
        subcategory: 'ochrona słuchu',
        type: ['nauszniki'],
        producer: '3m',
        name: '3m-optime1',
        description:
          'Ochronniki słuchu Peltor™ Optime™ spawalnicze. Wygłuszenie: SNR=24 dB. Łatwa wymiana poduszek i wkładek pomaga utrzymać higieniczną czystość. Miękkie i szerokie pierścienie uszczelniające zmniejszają nacisk wokół uszu i poprawiają komfort użytkowania. Spełniają wymagania normy EN352-3.',
        img: 'https://www.anitexbhp.pl/images/protective/3M_OPTIME1_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/3M_OPTIME1_1.jpg'],
        new: true,
      },
      {
        id: '30BAEAECBD3B46CCB52D1F6BCC588D8E',
        category: 'artykuły ochronne',
        subcategory: 'ochrona słuchu',
        type: ['nauszniki'],
        producer: 'reis',
        name: 'univer-osy',
        description:
          'Ochronniki słuchu w żółtym kolorze dopinane do hełmu. Wygłuszenie: H=28 dB, M=20 dB, L=12 dB, SNR=23 dB. Ochronniki słuchu do stosowania z hełmem. Możliwość połączenie z hełmem UNIVER-KAS oraz ramką do akcesoriów UNIVER-HOLDER. W zestawie UNIVER-ADAPTER. Spełniają wymagania normy EN352-3.',
        img: 'https://www.anitexbhp.pl/images/protective/UNIVER_OSY_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/UNIVER_OSY_1.jpg'],
        new: true,
      },
    ],
    'ochrona-nog': [
      {
        id: '8AF997EA207B4402A5FCF1F0C506B290',
        category: 'artykuły ochronne',
        subcategory: 'ochrona nóg',
        producer: 'reis',
        name: 'ondumbledor',
        description:
          'Ochraniacze kolan do spodni, 100% polietylen. Wymiary ok.: szerokość 17 cm, długość 24 cm. Zapewniają wygodę pracy, ograniczając dyskomfort odczuwany podczas pracy na kolanach.',
        img: 'https://www.anitexbhp.pl/images/protective/ONDUMBLEDOR_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/ONDUMBLEDOR_1.jpg'],
        new: true,
      },
      {
        id: 'D3D5B4E19B5B4E8F8DB288017E68B076',
        category: 'artykuły ochronne',
        subcategory: 'ochrona nóg',
        producer: 'leber&hollman',
        name: 'onkingbee',
        description:
          'Ochraniacze kolan do spodni, 100% polietylen. Wymiary: szerokość 16 cm, długość 21 cm Zapewniają wygodę pracy, ograniczając dyskomfort odczuwany podczas pracy na kolanach.',
        img: 'https://www.anitexbhp.pl/images/protective/ONKINGBEE_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/ONKINGBEE_1.jpg'],
        new: true,
      },
      {
        id: '82BC05B965A6449A9FFF6F9E573B13C2',
        category: 'artykuły ochronne',
        subcategory: 'ochrona nóg',
        producer: 'reis',
        name: 'onyb',
        description:
          'Nakolanniki w czarnym kolorze. Osłona z tworzywa sztucznego w kolorze żółtym. Paski mocujące zapinane na rzepy umożliwiają umocowanie ochraniaczy na nogach. Dzięki miękkiej poduszce zapewniają komfort i wygodę, nie uwierając użytkownika. Spełniają wymagania normy EN14404.',
        img: 'https://www.anitexbhp.pl/images/protective/ONYB_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/ONYB_1.jpg'],
        new: true,
      },
      {
        id: 'E9FB33E0F9C247D7B7108D68608BB5B6',
        category: 'artykuły ochronne',
        subcategory: 'ochrona nóg',
        producer: 'reis',
        name: 'onstone',
        description:
          'Nakolanniki żelowe, osłona z tworzywa sztucznego, wypełnione żelem dla lepszego komfortu. Paski mocujące zapinane na rzepy umożliwiają umocowanie ochraniaczy na nogach. Dzięki miękkiej poduszce zapewniają komfort i wygodę, nie uwierając użytkownika. Spełniają wymagania normy EN14404.',
        img: 'https://www.anitexbhp.pl/images/protective/ONSTONE_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/ONSTONE_1.jpg'],
        new: true,
      },
      {
        id: '2F6F737BEDFA4BD3B8D11A6746F5129B',
        category: 'artykuły ochronne',
        subcategory: 'ochrona nóg',
        producer: 'reis',
        name: 'bfol',
        description:
          'Buty foliowe wykonane z folii polietylenowej CPE. Rozmiar 41x17 cm. Pakowane po 100 szt.',
        img: 'https://www.anitexbhp.pl/images/protective/BFOL_1.jpg',
        photos: ['https://www.anitexbhp.pl/images/protective/BFOL_1.jpg'],
        new: true,
      },
    ],
  },
  pozostale: [
    {
      id: 'E710CF97A57D4B0F8F3B09937A3C6DE6',
      category: 'pozostałe',
      colors: ['black', 'brown', 'white'],
      producer: 'reis',
      name: 'sznurowadła',
      description:
        'Sznurowadła płaskie do butów wykonane z bawełny. Sznurowadła 100 cm dostępne są w kolorach czarnym, brązowym i białym, 120 cm w kolorze czarnym.',
      img: 'https://www.anitexbhp.pl/images/others/SZNUROWADLA_1.jpg',
      photos: [
        'https://www.anitexbhp.pl/images/others/SZNUROWADLA_1.jpg',
        'https://www.anitexbhp.pl/images/others/SZNUROWADLA_2.jpg',
        'https://www.anitexbhp.pl/images/others/SZNUROWADLA_3.jpg',
      ],
    },
    {
      id: '883A780E71B54A1CBF97E20FD251CD40',
      category: 'pozostałe',
      producer: 'reis',
      name: 'wkładki filcowe',
      description:
        'Wkładka do butów wykonana z wysokiej jakości materiału filcowego, grubość: 6 mm. Zapobiega odparzeniom stóp, doskonale pochłania wilgoć, łagodzi nacisk stopy na podłoże, zmniejsza nadmierne zmęczenie stóp. Nadaje się do każdego typu obuwia.',
      img: 'https://www.anitexbhp.pl/images/others/WKLADKI_FILCOWE.jpg',
      photos: ['https://www.anitexbhp.pl/images/others/WKLADKI_FILCOWE.jpg'],
      sizes: ['35/36', '37/38', '39/40', '41/42', '43/44', '45/46', '47/48'],
    },
    {
      id: '107E321518F94BE788B9BCC29AA582C5',
      category: 'pozostałe',
      producer: 'reis',
      name: 'wkładki aluminiowe',
      description:
        'Wkładka do butów termiczna, trzywarstwowa, grubość: 7 mm. Doskonale izoluje stopę przed zimnym podłożem, zapobiega odparzeniom stóp, idealnie pochłania wilgoć, łagodzi nacisk stopy na podłoże, poprawia dopasowanie butów do stóp.',
      img: 'https://www.anitexbhp.pl/images/others/WKLADKI_ALUMINIOWE.jpg',
      photos: ['https://www.anitexbhp.pl/images/others/WKLADKI_ALUMINIOWE.jpg'],
      sizes: ['35/36', '37/38', '39/40', '41/42', '43/44', '45/46', '47/48'],
    },
  ],

  'srodki-czystosci': [
    {
      id: '1AD014D7582844EFB76B99A85F452FA6',
      category: 'środki czystości',
      producer: 'merida',
      name: 'pasta do mycia rąk merida 500 ml',
      description:
        'Pasta do mycia rąk MERIDA. Doskonale usuwa zabrudzenia chroniąc skórę rąk, zawiera składniki tworzące ochronną warstwę zapobiegającą podrażnieniom skóry. Pasta nie zatyka kanalizacji. Nie zawiera piasku, środkiem ściernym jest mączka drzewna. Testowana dermatologicznie. Nanosić na mokre ręce bezpośrednio z opakowania. Opakowanie: puszka 500 ml.',
      img: 'https://www.anitexbhp.pl/images/cleaning/PASTA_MERIDA_500ML.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/PASTA_MERIDA_500ML.jpg'],
    },
    {
      id: 'DFE2DB2638D647849B2B65271176C784',
      category: 'środki czystości',
      producer: 'merida',
      name: 'pasta do mycia rąk merida 5 l',
      description:
        'Pasta do mycia rąk MERIDA. Doskonale usuwa zabrudzenia chroniąc skórę rąk, zawiera składniki tworzące ochronną warstwę zapobiegającą podrażnieniom skóry. Pasta nie zatyka kanalizacji. Nie zawiera piasku, środkiem ściernym jest mączka drzewna. Testowana dermatologicznie. Nanosić na mokre ręce bezpośrednio z opakowania. Opakowanie: puszka 5 l.',
      img: 'https://www.anitexbhp.pl/images/cleaning/PASTA_MERIDA_5L.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/PASTA_MERIDA_5L.jpg'],
    },
    {
      id: 'DB426AF2B379489A9B094C7E1F8C6481',
      category: 'środki czystości',
      producer: 'merida',
      name: 'pasta do mycia rąk merida mechanik',
      description:
        'Pasta do mycia silnie zabrudzonych rąk MERIDA MECHANIK®. Doskonale usuwa wszelkie zabrudzenia powstałe od smarów grafitowych, olejów silnikowych itp. Zawarte w paście pochodne lanoliny oraz naturalne olejki cytrusowe zapobiegają wysuszeniu skóry nawet przy częstym myciu rąk. Pasta nie zatyka kanalizacji, zawarte w niej składniki ulegają biodegradacji. Testowana dermatologicznie. Może być nanoszona na ręce bezpośrednio z opakowania lub przy użyciu pompki dozującej. Opakowanie: kanister 5 kg.',
      img: 'https://www.anitexbhp.pl/images/cleaning/PASTA_MERIDA_MECHANIK.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/PASTA_MERIDA_MECHANIK.jpg'],
    },
    {
      id: 'B19C3D0A9CDA44B99862F7C5FD7E006F',
      category: 'środki czystości',
      producer: 'bhp',
      name: 'Pasta do rąk BHP-PAS500',
      description:
        'Pasta do mycia rąk bhp, detergentowo-mydlana z gliceryną skuteczie usuwa, tłuszcz, smar, olej, sadzę oraz inne zabrudzenia. Zawiera substancję, która chroni skórę rąk. Testowana dermatologicznie. Opakowanie 500 g.',
      img: 'https://www.anitexbhp.pl/images/cleaning/PASTA_BHP_PAS500.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/PASTA_BHP_PAS500.jpg'],
    },
    {
      id: '1B89112A95214B05BD843169A2BB9F97',
      category: 'środki czystości',
      producer: 'bhp',
      name: 'Pasta do rąk BHP-PAS500scier',
      description:
        'Pasta do mycia rąk bhp detrgentowo-mydlana ze ścierniwem i gliceryną skutecznie usuwa silne zabrudzenia rąk typu tłuszcz, kurz, smar olej, sadza. Zawarta w paście gliceryna działa nawilżająco na dłonie. Łagodny materiał ścierny wspomaga usuwanie zabrudzeń bez podrażniania rąk. Opakowanie 500 g.',
      img: 'https://www.anitexbhp.pl/images/cleaning/PASTA_BHP_PAS500SCIER.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/PASTA_BHP_PAS500SCIER.jpg'],
    },
    {
      id: '7E64B696EFEE43298376584503D23791',
      category: 'środki czystości',
      producer: 'dove',
      name: 'Mydło w kostce DOVE',
      description: 'Mydło w kostce DOVE 100 g. Dostępne rodzaje: fresh, regular, extra sensitive.',
      img: 'https://www.anitexbhp.pl/images/cleaning/MYDLO_DOVE.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/MYDLO_DOVE.jpg'],
    },
    {
      id: 'F0EEC2CE51A8498C9A971F9DE47AB079',
      category: 'środki czystości',
      producer: 'arko',
      name: 'Mydło w kostce Arko',
      description:
        'Mydło toaletowe Arko 90 g. Dostępne rodzaje: kwiat bawełny, rumiankowe, mleko+miód, ekstra kremowe.',
      img: 'https://www.anitexbhp.pl/images/cleaning/MYDLO_ARKO_1.jpg',
      photos: [
        'https://www.anitexbhp.pl/images/cleaning/MYDLO_ARKO_1.jpg',
        'https://www.anitexbhp.pl/images/cleaning/MYDLO_ARKO_2.jpg',
      ],
    },
    {
      id: '27945F3B068A4CFDACACD1E7B1C710B4',
      category: 'środki czystości',
      producer: 'Luksja',
      name: 'Mydło w kostce Luksja',
      description:
        'Mydło w kostce Luksja 90 g. Dostępne rodzaje: bawełna, migdały i masło shea, rumianek i gliceryna, len i mleczko ryżowe, róża i mleko.',
      img: 'https://www.anitexbhp.pl/images/cleaning/MYDLO_LUKSJA_1.jpg',
      photos: [
        'https://www.anitexbhp.pl/images/cleaning/MYDLO_LUKSJA_1.jpg',
        'https://www.anitexbhp.pl/images/cleaning/MYDLO_LUKSJA_2.jpg',
        'https://www.anitexbhp.pl/images/cleaning/MYDLO_LUKSJA_3.jpg',
        'https://www.anitexbhp.pl/images/cleaning/MYDLO_LUKSJA_4.jpg',
      ],
    },
    {
      id: '729D8BF776E14409841867F1C70AF8E0',
      category: 'środki czystości',
      producer: 'merida',
      name: 'Mydło MERIDA ALVA® BEZWONNE',
      description:
        'Specjalistyczne mydło w płynie dla przemysłu spożywczego, nie przenosi obcego zapachu na produkty spożywcze. Zawiera substancje na bazie oleju kokosowego, kolagen i pochodne lanoliny. Zapobiega wysuszaniu skóry, nadaje jej elastyczność i gładkość. Opakowanie: kanister 5 kg.',
      img: 'https://www.anitexbhp.pl/images/cleaning/MYDLO_MERIDA_ALVA.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/MYDLO_MERIDA_ALVA.jpg'],
    },
    {
      id: 'CE2A183A29F14AE0835A2440690AE7E2',
      category: 'środki czystości',
      producer: 'merida',
      name: 'Mydło MERIDA CASTOR®',
      description:
        'Mydło w płynie o dobrych właściwościach myjących, zawiera pochodne lanoliny. Opakowanie: kanister 5 kg. Posiada wpis do Krajowego Systemu Informacji o Kosmetykach.',
      img: 'https://www.anitexbhp.pl/images/cleaning/MYDLO_MERIDA_CASTOR.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/MYDLO_MERIDA_CASTOR.jpg'],
    },
    {
      id: '2678866E642D4A2CA5C2A006768C687B',
      category: 'środki czystości',
      producer: 'victory couture',
      name: 'Żel antybakteryjny',
      description:
        'Bakteriobójczy żel do dezynfekcji rąk z płynem antybakteryjnym. Wytworzony na bazie 76% etanolu. Doskonale sprawdza się w warunkach, gdy nie ma dostepu do wody i mydła. Oczyszcza skórę jednoczesnie ją nawilzając i wygładzając dzięki zawartości aloesu. Pojemność 500 ml.',
      img: 'https://www.anitexbhp.pl/images/cleaning/ZEL_ANTYBAKTERYJNY.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/ZEL_ANTYBAKTERYJNY.jpg'],
    },
    {
      id: '653D99353C0A4FD0907E4CC9FA4EBC3D',
      category: 'środki czystości',
      colors: ['black'],
      producer: 'kiwi',
      name: 'pasta do butów kiwi',
      description: 'Pasta do butów Kiwi, kolor: czarny, pojemność: 50 ml.',
      img: 'https://www.anitexbhp.pl/images/cleaning/PASTA_KIWI.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/PASTA_KIWI.jpg'],
    },
    {
      id: '9ECFA8183C9640559B373348EBABBE22',
      category: 'środki czystości',
      colors: ['black'],
      producer: 'reis',
      name: 'pasta do butów br-pa silver',
      description:
        'Pasta nabłyszczająca do butów w kremie z aplikatorem. Wysoka zawartość naturalnego wosku. Nadaje natychmiastowy blask bez dodatkowego polerowania. Gęstość pasty umożliwia aplikowanie cienkiej warstwy na powierzchnię obuwia. Utrzymuje swoje właściwości w niskich temperaturach. Pojemność: 50 ml.',
      img: 'https://www.anitexbhp.pl/images/cleaning/PASTA_BR_PA_SILVER.jpg',
      photos: ['https://www.anitexbhp.pl/images/cleaning/PASTA_BR_PA_SILVER.jpg'],
    },
  ],
};
