<template>
  <section class="contact">
    <Info />
    <div class="contact__map">
      <iframe
        title="google_map_anitex"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2338.04652472146!2d19.478261751485288!3d54.12610292362109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e2b32764bfd19f%3A0xf2bdf98272d5a9ae!2sFIRMA%20HANDLOWO%20-%20US%C5%81UGOWA%20%22ANITEX%22!5e0!3m2!1spl!2spl!4v1604865212083!5m2!1spl!2spl"
        frameBorder="0"
        :style="{ border: 0, width: '100%', height: '100%' }"
        allowFullScreen=""
        aria-hidden="false"
      />
    </div>
    <Form />
  </section>
</template>

<script>
import Info from '../components/Info.vue';
import Form from '../components/Form.vue';

export default {
  components: { Info, Form },
};
</script>

<style lang="scss" scoped>
.contact {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  padding: 0 10px;
  margin: auto;
}

.contact__map {
  height: 400px;
  margin-top: 10px;

  @media screen and (max-width: 992px) {
    height: 350px;
  }

  @media screen and (max-width: 768px) {
    height: 300px;
  }

  @media screen and (max-width: 576px) {
    height: 250px;
  }

  @media screen and (max-width: 420px) {
    height: 225px;
  }
}
</style>
