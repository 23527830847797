<template>
  <div class="firm-info">
    <p class="firm-info__name">fhu anitex</p>
    <div class="firm-info__box">
      <div>
        <p class="firm-info__text">Nowina 11a</p>
        <p class="firm-info__text margin">82-310 Elbląg</p>
        <p class="firm-info__text"><span>NIP:</span>578-117-77-98</p>
        <p class="firm-info__text"><span>REGON:</span>170798210</p>
      </div>
      <div>
        <p class="firm-info__text margin">
          <span>BIURO:</span>
          <a href="tel:+48399356141" rel="noopener">{{ getPhoneNumber('+48399356141') }}</a>
        </p>
        <p class="firm-info__text">
          <span>TEL:</span>
          <a href="tel:+48609871233" rel="noopener">{{ getPhoneNumber('+48609871233') }}</a>
        </p>
        <p class="firm-info__text">
          <span>TEL:</span>
          <a href="tel:+48609541230" rel="noopener">{{ getPhoneNumber('+48609541230') }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getPhoneNumber(value) {
      return value.match(/.{1,3}/g).join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.firm-info {
  display: grid;
  padding: 15px 20px;
  border: 2px solid $black;

  @media screen and (max-width: 768px) {
    padding: 10px 15px;
  }
}

.firm-info__name {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 15px;
  text-transform: uppercase;

  @media screen and (max-width: 576px) {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}

.firm-info__box {
  display: grid;
  grid-template-columns: 1fr 1fr;

  div:last-child {
    margin-top: auto;
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;

    div:last-child {
      margin-top: 10px;
    }
  }
}

.firm-info__text {
  font-size: 1.25rem;
  margin-bottom: 2px;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 1px;
  }

  @media screen and (max-width: 576px) {
    font-size: 1.15rem;
    margin-bottom: 1px;
  }

  @media screen and (max-width: 420px) {
    font-size: 1.1rem;
  }

  &.margin {
    margin-bottom: 15px;

    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
      margin-bottom: 12.5px;
    }

    @media screen and (max-width: 576px) {
      font-size: 1.15rem;
      margin-bottom: 10px;
    }
  }

  span {
    display: inline-block;
    font-weight: 600;
    width: 95px;

    @media screen and (max-width: 768px) {
      width: 90px;
    }

    @media screen and (max-width: 576px) {
      width: 95px;
    }
  }

  a {
    color: $orange;
    font-weight: 500;

    @media screen and (min-width: 1024px) {
      &:hover {
        color: $blue;
      }
    }

    @media screen and (max-width: 1024px) {
      &:active {
        color: $blue;
      }
    }
  }
}
</style>
