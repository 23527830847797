import workwearImage from '../assets/images/category/category_workwear.jpg';
import bootsImage from '../assets/images/category/category_boots.jpg';
import glovesImage from '../assets/images/category/category_gloves.jpg';
import protectiveImage from '../assets/images/category/category_protective.jpg';
import workwearLowImage from '../assets/images/category/category_workwear_low.jpg';
import bootsLowImage from '../assets/images/category/category_boots_low.jpg';
import glovesLowImage from '../assets/images/category/category_gloves_low.jpg';
import protectiveLowImage from '../assets/images/category/category_protective_low.jpg';

const workwearCategory = 'odziez-robocza';
const bootsCategory = 'obuwie';
const glovesCategory = 'rekawice';
const protectiveCategory = 'artykuly-ochronne';

const offerWithSubcategory = (category, subcategory) => `/oferta/${category}/${subcategory}`;

const workwear = {
  background_low_image: workwearLowImage,
  background_image: workwearImage,
  data: [
    {
      text: 'spodnie',
      route: offerWithSubcategory(workwearCategory, 'spodnie'),
    },
    {
      text: 'bluzy',
      route: offerWithSubcategory(workwearCategory, 'bluzy'),
    },
    {
      text: 'kurtki',
      route: offerWithSubcategory(workwearCategory, 'kurtki'),
    },
    {
      text: 'koszule',
      route: offerWithSubcategory(workwearCategory, 'koszule'),
    },
    {
      text: 'koszulki',
      route: offerWithSubcategory(workwearCategory, 'koszulki'),
    },
    {
      text: 'bezrękawniki',
      route: offerWithSubcategory(workwearCategory, 'bezrekawniki'),
    },
    {
      text: 'ostrzegawcza',
      route: offerWithSubcategory(workwearCategory, 'ostrzegawcza'),
    },
    {
      text: 'przeciwdeszczowa',
      route: offerWithSubcategory(workwearCategory, 'przeciwdeszczowa'),
    },
  ],
};

const boots = {
  background_low_image: bootsLowImage,
  background_image: bootsImage,
  data: [
    {
      text: 'trzewiki',
      route: offerWithSubcategory(bootsCategory, 'trzewiki'),
    },
    {
      text: 'półbuty',
      route: offerWithSubcategory(bootsCategory, 'polbuty'),
    },
    {
      text: 'sandały',
      route: offerWithSubcategory(bootsCategory, 'sandaly'),
    },
    {
      text: 'pvc',
      route: offerWithSubcategory(bootsCategory, 'pvc'),
    },
    {
      text: 'eva',
      route: offerWithSubcategory(bootsCategory, 'eva'),
    },
  ],
};

const gloves = {
  background_low_image: glovesLowImage,
  background_image: glovesImage,
  data: [
    {
      text: 'powlekane',
      route: offerWithSubcategory(glovesCategory, 'powlekane'),
    },
    {
      text: 'skórzane',
      route: offerWithSubcategory(glovesCategory, 'skorzane'),
    },
    {
      text: 'gumowe',
      route: offerWithSubcategory(glovesCategory, 'gumowe'),
    },
    {
      text: 'dziane / tkaninowe',
      route: offerWithSubcategory(glovesCategory, 'dziane'),
    },
  ],
};

const protective = {
  background_low_image: protectiveLowImage,
  background_image: protectiveImage,
  data: [
    {
      text: 'ochrona głowy',
      route: offerWithSubcategory(protectiveCategory, 'ochrona-glowy'),
    },
    {
      text: 'ochrona oczu i twarzy',
      route: offerWithSubcategory(protectiveCategory, 'ochrona-oczu-i-twarzy'),
    },
    {
      text: 'ochrona dróg oddechowych',
      route: offerWithSubcategory(protectiveCategory, 'ochrona-drog-oddechowych'),
    },
    {
      text: 'ochrona słuchu',
      route: offerWithSubcategory(protectiveCategory, 'ochrona-sluchu'),
    },
    {
      text: 'ochrona nóg',
      route: offerWithSubcategory(protectiveCategory, 'ochrona-nog'),
    },
  ],
};

export default {
  value: ['odziez-robocza', 'obuwie', 'rekawice', 'artykuly-ochronne'],
  subcategory: {
    'odziez-robocza': workwear,
    obuwie: boots,
    rekawice: gloves,
    'artykuly-ochronne': protective,
  },
};
