import bootsImage from '../assets/images/offer/boots.jpg';
import cleaningImage from '../assets/images/offer/cleaning.jpg';
import glovesImage from '../assets/images/offer/gloves.jpg';
import workwearImage from '../assets/images/offer/workwear.jpg';
import protectiveImage from '../assets/images/offer/helmet.jpg';
import othersImage from '../assets/images/offer/others.jpg';
import bootsLowImage from '../assets/images/offer/boots_low.jpg';
import cleaningLowImage from '../assets/images/offer/cleaning_low.jpg';
import glovesLowImage from '../assets/images/offer/gloves_low.jpg';
import workwearLowImage from '../assets/images/offer/workwear_low.jpg';
import protectiveLowImage from '../assets/images/offer/helmet_low.jpg';
import othersLowImage from '../assets/images/offer/others_low.jpg';

const offerWithCategory = category => `/oferta/${category}`;

export default [
  {
    text: 'rękawice',
    image: glovesImage,
    lowImage: glovesLowImage,
    route: offerWithCategory('rekawice'),
  },
  {
    text: 'obuwie',
    image: bootsImage,
    lowImage: bootsLowImage,
    route: offerWithCategory('obuwie'),
  },
  {
    text: 'odzież robocza',
    image: workwearImage,
    lowImage: workwearLowImage,
    route: offerWithCategory('odziez-robocza'),
  },
  {
    text: 'artykuły ochronne',
    image: protectiveImage,
    lowImage: protectiveLowImage,
    route: offerWithCategory('artykuly-ochronne'),
  },
  {
    text: 'środki czystości',
    image: cleaningImage,
    lowImage: cleaningLowImage,
    route: offerWithCategory('srodki-czystosci'),
  },
  {
    text: 'pozostałe',
    image: othersImage,
    lowImage: othersLowImage,
    route: offerWithCategory('pozostale'),
  },
];
