<template>
  <section v-if="showCookies" class="cookies">
    <div class="cookies__box">
      <p class="cookies__text">
        Serwis wykorzystuje pliki cookies. Korzystając ze strony wyrażasz zgodę na wykorzystywanie
        plików cookies, <router-link rel="noopener" to="/rodo">dowiedz się więcej.</router-link>
      </p>
      <button class="cookies__button" @click="clickCookiesButton">ok</button>
    </div>
  </section>
</template>

<script>
import VueCookies from 'vue-cookies';
import { mapState, mapMutations } from 'vuex';

export default {
  created() {
    this.checkCookies();
  },
  computed: {
    ...mapState(['showCookies']),
  },
  methods: {
    ...mapMutations(['setShowCookies']),

    checkCookies() {
      const confirmation = VueCookies.get('confirmation');
      this.setShowCookies(!confirmation || false);
    },

    clickCookiesButton() {
      VueCookies.set('confirmation', true, '30d');
      this.setShowCookies(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.cookies {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $orange;
  min-width: 320px;
  z-index: 99999;
}

.cookies__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px 20px;

  @media screen and (max-width: 576px) {
    padding: 5px 15px;
  }

  @media screen and (max-width: 420px) {
    padding: 5px 12.5px;
  }
}

.cookies__text {
  margin-right: 30px;
  padding: 5px 0;
  color: $white;

  a {
    color: $black;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 576px) {
    font-size: 0.8rem;
    margin-right: 20px;
  }

  @media screen and (max-width: 420px) {
    font-size: 0.7rem;
  }
}

.cookies__button {
  text-transform: uppercase;
  padding: 5px;
  font-weight: 500;
  font-size: 1rem;
  color: $white;
  background-color: transparent;
  border: 2px solid $white;
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    &:hover {
      color: $black;
      border-color: $black;
    }
  }

  @media screen and (max-width: 1024px) {
    &:active {
      color: $black;
      border-color: $black;
    }
  }

  @media screen and (max-width: 576px) {
    font-size: 0.9rem;
  }
}
</style>
