<template>
  <section class="about">
    <top-image isAboutUs :image="image" :lowImage="lowImage" text="o nas" />
    <div class="about__box">
      <p v-for="(text, index) in aboutText" :key="index" class="about__text">{{ text }}</p>
    </div>
  </section>
</template>
<script>
import TopImage from '../atoms/TopImage.vue';
import aboutTextData from '../data/about';

import aboutuUsImage from '../assets/images/main/aboutus.jpg';
import aboutuUsLowImage from '../assets/images/main/aboutus_low.jpg';

export default {
  components: { TopImage },
  data() {
    return {
      aboutText: aboutTextData,
      image: aboutuUsImage,
      lowImage: aboutuUsLowImage,
    };
  },
};
</script>
<style lang="scss" scoped>
.about__box {
  margin: auto;
  padding: 50px 180px 40px;
  max-width: 1200px;
  text-align: justify;
  font-size: 1.25rem;

  @media screen and (max-width: 1200px) {
    padding: 50px 100px 40px;
  }

  @media screen and (max-width: 992px) {
    font-size: 1.15rem;
    padding: 45px 70px 35px;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.05rem;
    padding: 40px;
  }

  @media screen and (max-width: 576px) {
    font-size: 0.95rem;
    padding: 30px 20px;
  }

  @media screen and (max-width: 420px) {
    font-size: 0.7rem;
    padding: 25px 15px;
  }
}

.about__text {
  line-height: 30px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    line-height: 28px;
  }

  @media screen and (max-width: 576px) {
    line-height: 24px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 420px) {
    line-height: 22px;
  }
}
</style>
