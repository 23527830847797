export default {
  main: `Zgodnie z art. 13 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych osobowych) informuję, iż Administratorem Pani/Pana danych osobowych jest FHU ANITEX z siedzibą w NOWINA 11a reprezentowana przez właściciela Roman Kowalski.`,
  list: [
    `Dane osobowe przetwarzane będą w celu: zawarcia umowy, świadczenia usługi, wystawienia faktury.`,
    `Pani/Pana dane osobowe będą lub mogą być przekazywane wyłącznie podmiotom uprawnionym do
    uzyskania danych osobowych na podstawie przepisów prawa. Nie przekazujemy Pani/Pana danych
    poza teren Polski.`,
    `Podawane dane osobowe będą przechowywane do końca okresu przedawnienia potencjalnych
    roszczeń.`,
    `Posiada Pani/Pan prawo dostępu do treści swoich danych, sprostowania, ich usunięcia,
    ograniczenia przetwarzania a także prawo do wniesienia sprzeciwu wobec przetwarzania oraz do
    przenoszenia danych.`,
    `Podane przez Panią/Pana dane osobowe są dobrowolne lub są warunkiem zawarcia umowy. W
    przypadku braku zgody na podanie danych osobowych Administrator Danych Osobowych może
    odmówić zawarcia umowy.`,
    `Posiada Pani/Pan prawo do złożenia skargi do organu nadzorczego.`,
    `Posiada Pani/Pan prawo do cofnięcia zgody na przetwarzanie danych, jednakże po zawarciu
    umowy dane osobowe zostaną usunięte po upływie 7 lat.`,
    `Podanie danych osobowych jest dobrowolne – w przypadku ich nie podania umowa nie zostanie
    zawarta.`,
    `Pana/Pani dane osobowe nie podlegają zautomatyzowanemu podejmowaniu decyzji.`,
  ],
};
