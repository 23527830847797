<template>
  <section class="product">
    <Image :main="mainImage" :sub="product.photos" :is-urgent="isUrgent" :is-new="product.new" />
    <Details :product="product" />
  </section>
</template>

<script>
import products from '../data/products';
import Image from '../components/Image.vue';
import Details from '../components/Details.vue';

export default {
  components: { Image, Details },
  data() {
    return {
      product: {},
      mainImage: '',
      isUrgent: false,
    };
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      const { category, subcategory, id } = this.$route.params;

      const data = id ? products[category][subcategory] : products[category];
      const productId = id || subcategory;

      this.product = data.find(item => item.id === productId);

      if (!this.product.id) {
        this.$router.push({ path: '/404' });
        return;
      }

      this.isUrgent = this.product.producer === 'urgent';
      this.mainImage = this.isUrgent ? this.product.img : this.product.photos[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }
}
</style>
