<template>
  <component :is="isCategory ? 'Subcategory' : 'Product'" />
</template>

<script>
import categoryData from '../data/category';
import Subcategory from '../views/Subcategory.vue';
import Product from '../views/Product.vue';

export default {
  components: { Subcategory, Product },
  data() {
    return {
      isCategory: false,
    };
  },
  created() {
    this.checkPath();
  },
  methods: {
    checkPath() {
      const category = this.$route.params.category;
      this.isCategory = categoryData.value.includes(category);
    },
  },
};
</script>
