<template>
  <section v-if="showNavigationBar" class="navigation-bar">
    <div class="navigation-bar__box">
      <router-link rel="noopener" :to="getPath()">
        <button class="navigation-bar__button">
          <img class="navigation-bar__icon" alt="cofnij" :src="backIcon" />
          <span class="navigation-bar__text"> cofnij </span>
        </button>
      </router-link>
      <router-link rel="noopener" to="/">
        <button class="navigation-bar__button">
          <img class="navigation-bar__icon" alt="strona główna" :src="homeIcon" />
          <span class="navigation-bar__text"> strona główna</span>
        </button>
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import back_icon from '../assets/icons/arrow-left.svg';
import home_icon from '../assets/icons/home.svg';

export default {
  data() {
    return {
      backIcon: back_icon,
      homeIcon: home_icon,
    };
  },
  computed: {
    ...mapState(['showNavigationBar']),
  },
  methods: {
    getPath() {
      const path = this.$route.path;
      let index;

      for (let i = path.length; i >= 0; i -= 1) {
        if (path[i] === '/') {
          index = i;
          break;
        }
      }

      return index ? path.substring(0, index) : '/';
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-bar {
  display: flex;
  background-color: rgba($black, 0.8);
  height: 60px;
  margin-bottom: 10px;
  align-items: center;
  z-index: 99;

  @media screen and (max-width: 768px) {
    height: 50px;
  }

  @media screen and (max-width: 576px) {
    height: 40px;
  }
}

.navigation-bar__box {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  padding: 0 50px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 576px) {
    padding: 0 25px;
  }

  @media screen and (max-width: 420px) {
    padding: 0 15px;
  }
}

.navigation-bar__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;

  @media screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.05);
    }
  }

  @media screen and (max-width: 1024px) {
    &:active {
      transform: scale(1.05);
    }
  }
}

.navigation-bar__icon {
  margin-right: 5px;

  @media screen and (max-width: 576px) {
    height: 20px;
    width: 20px;
  }
}

.navigation-bar__text {
  position: relative;
  font-size: 1.25rem;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 2px;

  @media screen and (max-width: 768px) {
    font-size: 1.15rem;
  }

  @media screen and (max-width: 576px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 420px) {
    font-size: 0.9rem;
  }
}
</style>
