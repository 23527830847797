<template>
  <section class="rodo">
    <top-image :image="image" :lowImage="lowImage" text="RODO" />
    <div class="rodo__box">
      <p class="rodo__text">{{ rodoText.main }}</p>
      <ul class="rodo__list">
        <li v-for="(text, index) in rodoText.list" :key="index" class="rodo__list-item">
          {{ text }}
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import TopImage from '../atoms/TopImage.vue';
import rodoTextData from '../data/rodo';

import rodoImage from '../assets/images/main/rodo.jpg';
import rodoLowImage from '../assets/images/main/rodo_low.jpg';

export default {
  components: { TopImage },
  data() {
    return {
      rodoText: rodoTextData,
      image: rodoImage,
      lowImage: rodoLowImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.rodo__box {
  margin: auto;
  padding: 60px 180px 50px;
  max-width: 1200px;
  text-align: justify;
  font-size: 1.1rem;

  @media screen and (max-width: 1200px) {
    padding: 60px 100px 50px;
  }

  @media screen and (max-width: 992px) {
    font-size: 1rem;
    padding: 50px 70px 40px;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
    padding: 50px 40px 40px;
  }

  @media screen and (max-width: 576px) {
    font-size: 0.8rem;
    padding: 30px 20px;
  }

  @media screen and (max-width: 420px) {
    font-size: 0.7rem;
    padding: 25px 15px;
  }
}

.rodo__text {
  line-height: 26px;

  @media screen and (max-width: 768px) {
    line-height: 24px;
  }

  @media screen and (max-width: 576px) {
    line-height: 20px;
  }

  @media screen and (max-width: 420px) {
    line-height: 18px;
  }
}

.rodo__list {
  padding: 35px 80px 0;

  @media screen and (max-width: 992px) {
    padding: 30px 60px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 50px 0;
  }

  @media screen and (max-width: 576px) {
    padding: 20px 30px 0;
  }

  @media screen and (max-width: 420px) {
    padding: 15px 20px 0;
  }
}

.rodo__list-item {
  list-style: decimal;
  line-height: 26px;
  padding-bottom: 15px;

  &:last-child {
    padding-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    line-height: 24px;
  }

  @media screen and (max-width: 576px) {
    padding-bottom: 12.5px;
    line-height: 20px;
  }

  @media screen and (max-width: 420px) {
    padding-bottom: 10px;
    line-height: 18px;
  }
}
</style>
