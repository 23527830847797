<template>
  <section class="page-not-found">
    <img
      class="page-not-found__image"
      :src="pageNotFoundImage"
      alt="strona nie została odnaleziona"
    />
    <p class="page-not-found__text">strona nie została odnaleziona</p>
    <router-link rel="noopener" to="/"
      ><div class="page-not-found__button">wróć do strony głównej</div></router-link
    >
  </section>
</template>

<script>
import page404Image from '../assets/images/main/page_404.jpg';
import page404LowImage from '../assets/images/main/page_404_low.jpg';
import { lazyloadImage } from '../data/helper_functions';

export default {
  data() {
    return {
      image: page404Image,
      lowImage: page404LowImage,
    };
  },
  computed: {
    pageNotFoundImage() {
      return lazyloadImage(this.image) || this.lowImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-not-found {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  padding: 0 10px;
  width: 100%;
  align-items: center;
}

.page-not-found__image {
  margin-bottom: 20px;
  width: 375px;

  @media screen and (max-width: 992px) {
    width: 325px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 768px) {
    width: 275px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 576px) {
    width: 225px;
  }

  @media screen and (max-width: 420px) {
    width: 175px;
  }
}

.page-not-found__text {
  font-size: 2rem;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  border: 2px solid $black;
  padding: 20px 50px;
  margin: auto;
  text-align: center;

  @media screen and (max-width: 992px) {
    font-size: 1.8rem;
    padding: 20px 40px;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    padding: 15px 30px;
    letter-spacing: 0px;
  }

  @media screen and (max-width: 576px) {
    font-size: 1.2rem;
    padding: 10px 15px;
  }

  @media screen and (max-width: 420px) {
    font-size: 1.05rem;
    padding: 10px;
  }
}

.page-not-found__button {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 1px;
  border: 2px solid $orange;
  padding: 15px 25px;
  color: $orange;
  text-transform: uppercase;
  background-color: transparent;
  cursor: pointer;
  margin-top: 20px;

  @media screen and (min-width: 1024px) {
    &:hover {
      border: 2px solid $orange;
      background-color: $orange;
      color: $white;
    }
  }

  @media screen and (max-width: 1024px) {
    &:active {
      border: 2px solid $orange;
      background-color: $orange;
      color: $white;
    }
  }

  @media screen and (max-width: 992px) {
    font-size: 1.3rem;
    padding: 15px 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding: 10px 15px;
    letter-spacing: 0px;
    margin-top: 15px;
  }

  @media screen and (max-width: 576px) {
    font-size: 1rem;
    padding: 10px;
    margin-top: 10px;
  }

  @media screen and (max-width: 420px) {
    font-size: 0.9rem;
  }
}
</style>
