<template>
  <router-link rel="noopener" :to="route">
    <div class="offer-button" :style="{ backgroundImage: `url(${offerButtonImage})` }">
      <div class="offer-button__text">{{ text }}</div>
    </div>
  </router-link>
</template>

<script>
import { lazyloadImage } from '../data/helper_functions';

export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    lowImage: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
  },
  computed: {
    offerButtonImage: function () {
      return lazyloadImage(this.image) || this.lowImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-button {
  display: flex;
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 225px;
  filter: grayscale(75%);
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    &:hover {
      filter: grayscale(40%);

      > .offer-button__text {
        transform: scale(1.1);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &:active {
      filter: grayscale(40%);

      > .offer-button__text {
        transform: scale(1.1);
      }
    }
  }

  @media screen and (max-width: 992px) {
    height: 200px;
  }

  @media screen and (max-width: 768px) {
    height: 175px;
  }

  @media screen and (max-width: 576px) {
    height: 150px;
  }

  @media screen and (max-width: 420px) {
    height: 125px;
  }
}

.offer-button__text {
  display: inline-flex;
  padding: 10px 26px;
  border: 6px solid $white;
  text-align: center;
  color: $white;
  font-weight: 600;
  font-size: 3.1rem;
  margin: auto;

  @media screen and (min-width: 1024px) {
    transition: transform 0.2s;
  }

  @media screen and (max-width: 1024px) {
    transition: none;
  }

  @media screen and (max-width: 1200px) {
    font-size: 2.9rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.6rem;
    padding: 8px 18px;
  }

  @media screen and (max-width: 576px) {
    font-size: 2rem;
    border-width: 5px;
  }

  @media screen and (max-width: 420px) {
    font-size: 1.5rem;
    padding: 6px 12px;
    border-width: 4px;
  }
}
</style>
