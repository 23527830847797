export default {
  blue: 'hsl(225,100%,50%)',
  black: 'hsl(0, 0%, 0%)',
  grey: 'hsl(0, 5%, 70%)',
  darkGrey: 'hsl(0, 5%, 40%)',
  green: 'hsl(120,100%,30%)',
  red: 'hsl(0,100%,45%)',
  orange: 'hsl(30,100%,50%)',
  pink: 'hsl(330, 100%, 65%)',
  yellow: 'hsl(50, 100%, 55%)',
  moro: 'hsl(70, 40%, 40%)',
  navy: 'hsl(240, 40%, 30%)',
  darkBrown: 'hsl(30, 80%, 10%)',
  brown: 'hsl(30, 60%, 25%)',
  white: 'hsl(0, 0%, 100%)',
  turkus: 'hsl(183, 65%, 50%)',
};
