<template>
  <component :is="isCategory ? 'Category' : 'Subcategory'" />
</template>

<script>
import categoryData from '../data/category';
import Category from '../views/Category.vue';
import Subcategory from '../views/Subcategory.vue';

export default {
  components: { Category, Subcategory },
  data() {
    return {
      isCategory: false,
    };
  },
  created() {
    this.checkPath();
  },
  methods: {
    checkPath() {
      const category = this.$route.params.category;
      this.isCategory = categoryData.value.includes(category);
    },
  },
};
</script>
