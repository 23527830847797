<template>
  <section class="home">
    <home-button v-for="data in home" :key="data.text" v-bind="data" />
  </section>
</template>

<script>
import { mapMutations } from 'vuex';
import home from '../data/home';
import HomeButton from '../atoms/HomeButton.vue';

export default {
  components: { HomeButton },
  data() {
    return {
      home,
    };
  },
  created() {
    this.setShowNavigationBar(false);
  },
  unmounted() {
    this.setShowNavigationBar(true);
  },
  methods: {
    ...mapMutations(['setShowNavigationBar']),
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: grid;
  grid-template-areas:
    'a a a b'
    'a a a b'
    'a a a b'
    'a a a c'
    'a a a c'
    'a a a c'
    'd d d d'
    'd d d d';
  height: 80vh;
  max-width: 1200px;
  width: 100%;
  gap: 10px;
  padding: 0 10px;
  text-align: center;
  margin: auto;

  @media screen and (max-width: 992px) {
    grid-template-areas:
      'a a a a'
      'a a a a'
      'b b c c'
      'd d d d';
  }

  @media screen and (max-width: 576px) {
    height: 95vh;
    grid-template-areas:
      'a a a a'
      'a a a a'
      'a a a a'
      'b b b b'
      'c c c c'
      'd d d d';
  }
}
</style>
