<template>
  <section class="top-bar">
    <router-link rel="noopener" to="/">
      <h1 class="top-bar__logo">FHU Anitex BHP</h1>
    </router-link>
  </section>
</template>

<style lang="scss" scoped>
.top-bar {
  position: relative;
  height: 150px;
  margin-bottom: 10px;
  display: flex;
  background-color: $grey-light;
  z-index: 99;
  text-transform: uppercase;

  @media screen and (max-width: 1200px) {
    height: 140px;
  }

  @media screen and (max-width: 992px) {
    height: 130px;
  }

  @media screen and (max-width: 768px) {
    height: 100px;
  }

  @media screen and (max-width: 576px) {
    height: 80px;
  }
}

.top-bar__logo {
  margin: auto;
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 5px;
  cursor: pointer;
  color: $black;
  text-align: center;
  border-top: 2px solid;
  border-bottom: 2px solid;
  padding: 4px 100px;
  text-transform: uppercase;

  @media screen and (max-width: 992px) {
    font-size: 2.7rem;
    padding: 3px 60px;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.4rem;
    letter-spacing: 4px;
    padding: 3px 40px;
  }

  @media screen and (max-width: 576px) {
    font-size: 2.1rem;
    padding: 3px 25px;
    letter-spacing: 3px;
  }

  @media screen and (max-width: 420px) {
    font-size: 1.8rem;
    padding: 2px 10px;
    letter-spacing: 2px;
  }
}
</style>
