<template>
  <router-link rel="noopener" :to="getPath()">
    <div class="card" @click="clickCard">
      <div v-if="colorsDataLength" class="card__box-color">
        <div
          v-for="color in colorsData"
          :key="color"
          :class="[getColorClass(color), 'card__color']"
          :style="{
            '--first-color': `${productsColors[color[0]]}`,
            '--second-color': `${productsColors[color[1]]}`,
            '--third-color': `${productsColors[color[2]]}`,
          }"
        />
      </div>
      <img class="card__image" :src="img" :alt="name" />
      <span class="card__category">{{ category }}</span>
      <span class="card__name">{{ name }}</span>
      <span class="card__producer">{{ producer }}</span>
    </div>
  </router-link>
</template>

<script>
import productsColors from '../data/products_colors';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    producer: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      colorsData: [],
      productsColors,
    };
  },
  computed: {
    colorsDataLength: function () {
      return !!this.colorsData.length;
    },
  },
  created() {
    this.setColorsData();
  },
  methods: {
    setColorsData() {
      this.colors.map(item => this.colorsData.push(item.split('/')));
    },

    getPath() {
      const path = this.$route.path;

      return `${path}/${this.id}`;
    },

    getColorClass(color) {
      if (color.length === 2) return 'two';
      if (color.length === 3) return 'third';
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  cursor: pointer;
  border: 2px solid rgba($grey, 0.2);
  box-shadow: 0 0 10px 3px $grey-light;
  text-transform: uppercase;
  transition: transform 0.3s ease, border 0.3s;

  @media screen and (min-width: 1024px) {
    &:hover {
      transform: translateY(-5px);
      border-color: $orange;
    }
  }

  @media screen and (max-width: 1024px) {
    &:active {
      border-color: $orange;
    }
  }
}

.card__box-color {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.card__color {
  position: relative;
  width: 15px;
  height: 15px;
  margin-bottom: 6px;
  background-color: var(--first-color);
  border: 1px solid $black;

  &.two {
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-left: 13px solid transparent;
      border-bottom: 13px solid var(--second-color);
      z-index: 1;
    }
  }

  &.third {
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      border-left: 9px solid transparent;
      border-bottom: 9px solid var(--second-color);
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-right: 9px solid transparent;
      border-top: 9px solid var(--third-color);
      z-index: 1;
    }
  }
}

.card__image {
  width: 95%;
  object-fit: contain;
  margin: 10px auto;
  height: 225px;

  @media screen and (max-width: 420px) {
    height: 180px;
  }
}

.card__category {
  font-size: 0.65rem;
  font-weight: 600;
  color: $orange;
  padding-top: 5px;
  border-top: 1px solid $grey-light;
}

.card__name {
  font-size: 1rem;
  margin: 3px 0;
  font-weight: 600;
  color: $black;
}

.card__producer {
  font-size: 0.65rem;
  font-weight: 600;
  color: $grey;
}
</style>
