import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Rodo from '../views/Rodo.vue';
import Contact from '../views/Contact.vue';
import Offer from '../views/Offer.vue';
import Product from '../views/Product.vue';
import PageNotFound from '../views/PageNotFound.vue';
import DynamicCategory from '../templates/DynamicCategory.vue';
import DynamicSubcategory from '../templates/DynamicSubcategory.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/o-nas',
    name: 'About',
    component: About,
  },
  {
    path: '/rodo',
    name: 'RODO',
    component: Rodo,
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/oferta',
    name: 'Offer',
    component: Offer,
  },
  {
    path: '/oferta/:category',
    name: 'DynamicCategory',
    component: DynamicCategory,
  },
  {
    path: '/oferta/:category/:subcategory',
    name: 'DynamicSubcategory',
    component: DynamicSubcategory,
  },
  {
    path: '/oferta/:category/:subcategory/:id',
    name: 'Product',
    component: Product,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ top: 0 }),
});

export default router;
