import { createStore } from 'vuex';

export default createStore({
  state: {
    showNavigationBar: true,
    showCookies: true,
    showFilterBar: false,
    products: [],
    filters: {
      value: [],
      data: [],
      type: '',
    },
    product: {},
  },
  mutations: {
    setShowNavigationBar: (state, value) => (state.showNavigationBar = value),
    setShowCookies: (state, value) => (state.showCookies = value),
    setShowFilterBar: (state, value) => (state.showFilterBar = value),
    setProducts: (state, value) => (state.products = value),
    setFilters: (state, value) => (state.filters = value),
    setProduct: (state, value) => (state.product = value),
  },
});
